
/*    GLOBAL STYLES
===================================================================*/
/*=== CONTENT OF CSS FILE =========================================

	#PRELOADER
	#CSS RESET
  #SEARCH
  #REVO #SLIDER custom
	#IMAGES
	#HEADER
  #FEATURES 1
  #FEATURES 2-3
  #FEATURES 4
  #FEATURES 5
  #FEATURES 6
  #FEATURES 7
  #FEATURES 8
  #FEATURES 9
  #FEATURES 10
  #SHOP
  #SHOP #DEPARTMENT
  #SHOP #ITEMS
  #SHOP #INFO
  #PORTFOLIO 1 GRID
  #PORTFOLIO 1
  #PORTFOLIO SINGLE
  #CLIENTS 1
  #TESTIMONIALS 1
  #TESTIMONIALS 2
  #WORK PROCESS 1
  #ABOUT US 1
  #ABOUT US 2
  #COUNTERS 1
  #ADS 1 & 2
  #VIDEO ADS 1
  #BLOG SECTION 1
  #BLOG SECTION 2
  #BLOG #WIDGETS
  #CONTACT INFO SECTION 1
  #GMAPS 1
  #STATICK MEDIA
  #PAGE TITLE
  #CONTACT MENU
  #CONTACT FORM
  #FOOTER 1
  #FOOTER 2
  #FOOTER 2 BLACK
  #MENU	
		#MAIN-MENU MENU
		#INDENT MAIN CONTAINER	( responsive in "media query" section)
		#STICKY ICON BUTTON ANIMATION
		#ANIMATION FOR SUB MENU
	#MEGAMENU
	#PRIMARY MENU
  #HEADER FIX
  #LOGO
  #BREADCRUMB 
  #CAROUSEL
  #NEWSLETTER
  #OUR CLIENTS
  #TABS
  #BLOCKQUOTES
  #TESTIMONIALS
  #PAGE TITLE
  #404
  #COMING SOON
  #ALERT BOXES
  #SHORTCODES #DEMO
  #BLOG
  #COMMENTS 
  #WORK NAV
  #RELATED POSTS
  #PROGRESS BAR
  #MAINTENANCE
  #PORTFOLIO
  #ELEMENTS
  #FOOTER
  #TYPOGRAPHY
  #BUTTONS
  #MARGINS #PADDINGS
  #BACK TO TOP BUTTON
  #FAQ
  #COUNTER
  #ACCORDION-CUSTOM
  #ACCORDION 3
  #ACCORDION
  #TOGGLE
  #PRICING TABLE
  #TIMELINE
  #CLEARFIX
   
  === #RESPONSIVE STYLES =======================================
  
	#PORTFOLIO FILTER
	
	Max Width "600px"
		#TESTIMONIALS
		
	Max Width "640px"

	Max Width "767px"

	Max Width "875px"

	Max Width "991px" - Min Width "768px"
		#SERVICES
		
	Max Width "991px"  PHONE
		#MARGINS MOBILE
		#PAGE TITLE
		#GOOGLE MAP
		#NEWSLETTER
		#HEADER
		#SLIDER 1
		#TWITTER
		#TESTIMONIALS
		#SERVICES
		#TIMELINE 
		#MAINTENANCE
		#HEADER MENU
		#INDENT MAIN CONTAINER
		#FOOTER
		#FOOTER 2
		#PRICING TABLE
	
	Min Width "992px" DESKTOP
		
	Max Width "1199px" - Min Width "992px"

	Max Width "1024px" TABLET
		#BOXES 2 
		#FOOTER
		#FOOTER 2
		#TWITTER
		#FOOTER CONTACT INFO
		
	Max Width "1200px"	
	
	Max Width "479px"
	
===	#Core Owl Carousel CSS File 
===	#Owl Carousel Owl Demo THEME CSS File 
===	#Magnific Popup CSS File

  #INDEX-PORTFOLOLIO 2 NEW
  #TRAVEL NEW
  #SIDE MENU NEW 
  #FULL SCREEN MENU NEW
  #TWITTER FEEDS NEW
  #FLICKR FEEDS NEW
  #TOP BAR NEW
  #CONSTRUCTION 2 NEW
  #CAFE NEW

*/

/* #PRELOADER  ============================================== */
/* This only works with JavaScript, 
if it's not present, don't show loader */

#loader-overflow{
  display:block;
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  background: #fefefe;
  z-index: 99999;	
}
#loader3 {
  will-change: transform;
  width: 40px;
  height: 40px;
  position: absolute;
  top: 50%;
  left: 50%;
  margin: -20px 0 0 -20px;
  background-color: #b6b6b7;
  border-radius: 50%;  
  -webkit-animation: scaleout 1.1s infinite ease-in-out;
  animation: scaleout 1.1s infinite ease-in-out;
  
  text-indent: -99999px;
  z-index: 999991;
}
@-webkit-keyframes scaleout {
  0% { 
    transform: scale(0);
    -webkit-transform: scale(0);
  }
  100% {
    transform: scale(1.0);
    -webkit-transform: scale(1.0);
    opacity: 0;
  }
}
@keyframes scaleout {
  0% { 
    -webkit-transform: scale(0);
    -moz-transform: scale(0);
    -ms-transform: scale(0);
    -o-transform: scale(0);
    transform: scale(0);
    opacity: 0;
  }
  40% {
    opacity: 1;
  }
  100% {
    -webkit-transform: scale(1.0);
    -moz-transform: scale(1.0);
    -ms-transform: scale(1.0);
    -o-transform: scale(1.0);
    transform: scale(1.0);
    opacity: 0;
  }
}

/* #CSS RESET
 ===================================================================*/
html, body, div, span, applet, object, iframe, a, abbr, acronym, address, big, cite, code, del, dfn, em, font, img, ins, kbd, q, s, samp, small, strike, strong, sub, sup, tt, var, b, u, i, center, dl, dt, dd, fieldset, form, label, legend, caption, tbody, tfoot, thead, tr, th, td {
margin: 0;
padding: 0;
border: 0;
outline: 0;
}
:focus {
outline: 0;
}

/* HTML5 display-role reset for older browsers */
article, aside, details, figcaption, figure, 
footer, header, hgroup, menu, nav, section {
	display: block;
}
table {
	border-collapse: collapse;
	border-spacing: 0;
}
html,body{
	margin:0;
	padding:0;
}
table {
	border-collapse:collapse;
	border-spacing:0;
}
fieldset,img { 
	border:0;
}
input{
	border:1px solid #b0b0b0;
	padding:3px 5px 4px;
	color:#979797;
}
address,caption,cite,code,dfn,th,var {
	font-style:normal;
	font-weight:normal;
}
caption,th {
	text-align:left;
}
q:before,q:after {
	content:'';
}
abbr,acronym { border:0;
} 
body {
  border: 0 none;
  font-size: 100%;
  margin: 0;
  outline: 0 none;
  padding: 0;
  vertical-align: baseline;
	font-size: 14px;
	line-height: 25px;
	font-family: 'Open Sans', Arial, Helvetica, sans-serif; 
	font-weight: normal;
	color: #7e8082;
	background-color: #fff;
	-webkit-font-smoothing: antialiased !important;
	-webkit-text-size-adjust: 100% !important;
}
.bold {
	font-weight:bold;
}
.font-light {
	font-weight:100;
}
.font-norm {
	font-weight:normal !important;
}
::selection
{
	background-color: #ffea00;
  color:#4b4e53;
}
::-moz-selection  
{
	background-color: #ffea00;
  color:#4b4e53;
}
.relative {
  position: relative;
}

/* #SEARCH NEW
================================================== */
.gsc-selected-option-container.gsc-inline-block {
  width: auto !important;
}
/***** MAIN COMPONENTS  *****/
.cd-main-content, .cd-main-header {
  position: relative;
  -webkit-transition: -webkit-transform .3s;
  -moz-transition: -moz-transform .3s;
  transition: transform .3s;
  /* Force Hardware Acceleration in WebKit */
  -webkit-transform: translateZ(0);
  -moz-transform: translateZ(0);
  -ms-transform: translateZ(0);
  -o-transform: translateZ(0);
  transform: translateZ(0);
  will-change: transform;
}
@media only screen and (max-width: 1169px) {
  .cd-main-content.nav-is-visible, .cd-main-header.nav-is-visible {
    -webkit-transform: translateX(-260px);
    -moz-transform: translateX(-260px);
    -ms-transform: translateX(-260px);
    -o-transform: translateX(-260px);
    transform: translateX(-260px);
  }
  .nav-on-left .cd-main-content.nav-is-visible, .nav-on-left .cd-main-header.nav-is-visible {
    -webkit-transform: translateX(260px);
    -moz-transform: translateX(260px);
    -ms-transform: translateX(260px);
    -o-transform: translateX(260px);
    transform: translateX(260px);
  }
}
.cd-main-content {
  background: #e2e3df;
  min-height: 100vh;
  z-index: 2;
}
.cd-main-header {
  height: 50px;
  background: #ffffff;
  z-index: 3;
}
.nav-is-fixed .cd-main-header {
  /* add .nav-is-fixed class to body if you want a fixed navigation on > 1170px */
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
}
@media only screen and (min-width: 1170px) {
  .cd-main-header {
    height: 80px;
  }
  .cd-main-header:after {
    content: "";
    display: table;
    clear: both;
  }
}
.cd-logo {
  position: absolute;
  top: 12px;
  left: 5%;
}
.cd-logo img {
  display: block;
}
@media only screen and (max-width: 1169px) {
  .nav-on-left .cd-logo {
    left: auto;
    right: 5%;
  }
}
@media only screen and (min-width: 1170px) {
  .cd-logo {
    top: 26px;
    left: 4em;
  }
}
.cd-header-buttons {
  position: absolute;
  display: inline-block;
  padding:0;
  top: 26px;
  right: 35px;
  z-index:29;
  transition: top .4s ease 0s; 
  -webkit-transform: translateZ(0);
  -moz-transform: translateZ(0);
  -ms-transform: translateZ(0);
  -o-transform: translateZ(0);
  transform: translateZ(0);
}
.affix .cd-header-buttons {
  top: 10px;
  color:#8b8c8e;
  transition: top .4s ease 0s; 
  -webkit-transform: translateZ(0);
  -moz-transform: translateZ(0);
  -ms-transform: translateZ(0);
  -o-transform: translateZ(0);
  transform: translateZ(0);
}
.cd-header-buttons li {
  display: inline-block;
}
@media only screen and (max-width: 1169px) {
  .nav-on-left .cd-header-buttons {
    right: auto;
    left: 5%;
  }
  .nav-on-left .cd-header-buttons li {
    float: right;
  }
}
.cd-search-trigger, .cd-nav-trigger {
  position: relative;
  display: block;
  width: 44px;
  height: 44px;
  overflow: hidden;
  white-space: nowrap;
  /* hide text */
  color: transparent;
  z-index: 3;
}
.cd-search-trigger::before, .cd-search-trigger::after {
  /* search icon */
  content: '';
  position: absolute;
  -webkit-transition: opacity .3s;
  -moz-transition: opacity .3s;
  transition: opacity .3s;
  /* Force Hardware Acceleration in WebKit */
  -webkit-transform: translateZ(0);
  -moz-transform: translateZ(0);
  -ms-transform: translateZ(0);
  -o-transform: translateZ(0);
  transform: translateZ(0);
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
}
.cd-search-trigger::before {
  /* lens */
  top: 11px;
  left: 11px;
  width: 16px;
  height: 16px;
  border-radius: 50%;
  border: 1px solid #4b4e53;
}
.black-header .cd-search-trigger::before {
  border: 2px solid rgba(255,255,255, .8);
}
.cd-search-trigger::after {
  /* handle */
  height: 1px;
  width: 8px;
  background: #4b4e53;
  bottom: 16px;
  right: 13px;
  -webkit-transform: rotate(45deg);
  -moz-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  -o-transform: rotate(45deg);
  transform: rotate(45deg);
}
.black-header .cd-search-trigger::after {
  background: rgba(255,255,255, .8);
  height: 2px;
}
.cd-search-trigger span {
  /* container for the X icon */
  position: absolute;
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
}
.cd-search-trigger span::before, .cd-search-trigger span::after {
  /* close icon */
  content: '';
  position: absolute;
  display: inline-block;
  height: 2px;
  width: 22px;
  top: 50%;
  margin-top: -2px;
  left: 50%;
  margin-left: -11px;
  background: #4b4e53;
  opacity: 0;
  /* Force Hardware Acceleration in WebKit */
  -webkit-transform: translateZ(0);
  -moz-transform: translateZ(0);
  -ms-transform: translateZ(0);
  -o-transform: translateZ(0);
  transform: translateZ(0);
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  -webkit-transition: opacity .3s, -webkit-transform .3s;
  -moz-transition: opacity .3s, -moz-transform .3s;
  transition: opacity .3s, transform .3s;
}
.cd-search-trigger span::before {
  -webkit-transform: rotate(45deg);
  -moz-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  -o-transform: rotate(45deg);
  transform: rotate(45deg);
}
.cd-search-trigger span::after {
  -webkit-transform: rotate(-45deg);
  -moz-transform: rotate(-45deg);
  -ms-transform: rotate(-45deg);
  -o-transform: rotate(-45deg);
  transform: rotate(-45deg);
}
.cd-search-trigger.search-is-visible::before, .cd-search-trigger.search-is-visible::after {
  /* hide search icon */
  opacity: 0;
}
.cd-search-trigger.search-is-visible span::before, .cd-search-trigger.search-is-visible span::after {
  /* show close icon */
  opacity: 1;
}
.cd-search-trigger.search-is-visible span::before {
  -webkit-transform: rotate(135deg);
  -moz-transform: rotate(135deg);
  -ms-transform: rotate(135deg);
  -o-transform: rotate(135deg);
  transform: rotate(135deg);
}
.cd-search-trigger.search-is-visible span::after {
  -webkit-transform: rotate(45deg);
  -moz-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  -o-transform: rotate(45deg);
  transform: rotate(45deg);
}
.cd-search {
  position: absolute;
  height: 50px;
  width: 100%;
  top: 50px;
  left: 0;
  z-index: 3;
  opacity: 0;
  visibility: hidden;
  -webkit-transition: opacity .3s 0s, visibility 0s .3s, height 0.4s ease 0s;
  -moz-transition: opacity .3s 0s, visibility 0s .3s, height 0.4s ease 0s;
  transition: opacity .3s 0s, visibility 0s .3s, height 0.4s ease 0s;
  -webkit-transform: translateZ(0);
  -moz-transform: translateZ(0);
  -ms-transform: translateZ(0);
  -o-transform: translateZ(0);
  transform: translateZ(0);
}
.cd-search form {
  height: 100%;
  width: 100%;
}
.cd-search input {
  border-radius: 0;
  border: none;
  background: #ffffff;
  height: 100%;
  width: 100%;
  padding: 0 40px;
  box-shadow: inset 0 1px 0 #e2e3df, 0 3px 6px rgba(0, 0, 0, .05);
  -webkit-appearance: none;
  -moz-appearance: none;
  -ms-appearance: none;
  -o-appearance: none;
  appearance: none;
}
.cd-search input::-webkit-input-placeholder, .cd-search input::-moz-placeholder, .cd-search input:-ms-input-placeholder{
  color: #7f7f7f;
}
.cd-search input:focus {
  outline: none;
}
.cd-search.is-visible {
  opacity: 1;
  visibility: visible;
  -webkit-transition: opacity .3s 0s, visibility 0s 0s, height 0.4s ease 0s;
  -moz-transition: opacity .3s 0s, visibility 0s 0s, height 0.4s ease 0s;
  transition: opacity .3s 0s, visibility 0s 0s, height 0.4s ease 0s;
  -webkit-transform: translateZ(0);
  -moz-transform: translateZ(0);
  -ms-transform: translateZ(0);
  -o-transform: translateZ(0);
  transform: translateZ(0);
  z-index:28;
}
.nav-is-fixed .cd-search {
  position: fixed;
}
.cd-search {
  height: 100px;
  top: 0px;
}
.affix .cd-search {
  height: 60px;
}
.cd-search input {
  font-size: 24px;
  line-height:28px;
  font-weight: 300;
}
.cd-overlay {
  /* shadow layer visible when navigation is active */
  position: fixed;
  z-index: 2;
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
  cursor: pointer;
  background-color: rgba(105, 170, 111, .8);
  visibility: hidden;
  opacity: 0;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  -webkit-transition: opacity .3s 0s, visibility 0s .3s, -webkit-transform .3s 0s;
  -moz-transition: opacity .3s 0s, visibility 0s .3s, -moz-transform .3s 0s;
  transition: opacity .3s 0s, visibility 0s .3s, transform .3s 0s;
}
.cd-overlay.is-visible {
  opacity: 1;
  visibility: visible;
  -webkit-transition: opacity .3s 0s, visibility 0s 0s, -webkit-transform .3s 0s;
  -moz-transition: opacity .3s 0s, visibility 0s 0s, -moz-transform .3s 0s;
  transition: opacity .3s 0s, visibility 0s 0s, transform .3s 0s;
}
@media only screen and (max-width: 1169px) {
  .cd-overlay.is-visible {
    -webkit-transform: translateX(-260px);
    -moz-transform: translateX(-260px);
    -ms-transform: translateX(-260px);
    -o-transform: translateX(-260px);
    transform: translateX(-260px);
  }
  .nav-on-left .cd-overlay.is-visible {
    -webkit-transform: translateX(260px);
    -moz-transform: translateX(260px);
    -ms-transform: translateX(260px);
    -o-transform: translateX(260px);
    transform: translateX(260px);
  }
  .cd-overlay.is-visible.search-is-visible, .nav-on-left .cd-overlay.is-visible.search-is-visible {
    -webkit-transform: translateX(0);
    -moz-transform: translateX(0);
    -ms-transform: translateX(0);
    -o-transform: translateX(0);
    transform: translateX(0);
  }
}

/* -------------------------------- 
support for no js 
-------------------------------- */
.no-js .cd-primary-nav {
  position: relative;
  height: auto;
  width: 100%;
  overflow: visible;
  visibility: visible;
  z-index: 2;
}
.no-js .cd-search {
  position: relative;
  top: 0;
  opacity: 1;
  visibility: visible;
}
@media only screen and (min-width: 1170px) {
  .no-js .cd-primary-nav {
    position: absolute;
    z-index: 3;
    display: inline-block;
    width: auto;
    top: 0;
    right: 150px;
    padding: 0;
  }
  .no-js .nav-is-fixed .cd-primary-nav {
    position: fixed;
  }
}

/* #REVO #SLIDER custom 
================================================== */
.tp-banner{
	width:100%;
	position:relative;
}
.dark-bg {
  background:#303236;
}
.tp-banner-fullscreen-container {
	width:100%;
	position:relative;
	padding:0;
}
.rs-fullscr-container .tp-leftarrow, .rs-fullscr-container .tp-rightarrow{
	z-index: 27;
} 
.tparrows.preview4 .tp-arr-titleholder {
	font-weight:normal;
}
.rs-fullscr-container {
  width: 100%;
  display: block;
  position: relative;
  overflow: hidden;
  width: 100%;
  position: relative;
  padding: 0;
}
.page-section  {
  width: 100%;
  display: block;
  position: relative;
  overflow: hidden;
}
/******   CAPTIONS   ******/
.tp-caption.dark-light-32 {
	color:#4b4e53;
	font-family: 'Lato',Arial,Helvetica,sans-serif;
	font-size: 32px;
	line-height: 32px;
	font-weight: 300;
}
.tp-caption.dark-black-100 {
	color:#4b4e53;
	font-family: 'Lato',Arial,Helvetica,sans-serif;
	font-size: 100px;
	line-height: 100px;
	font-weight: 900;
}
.tp-caption.dark-light-100 {
	color:#4b4e53;
	font-family: 'Lato',Arial,Helvetica,sans-serif;
	font-size: 100px;
	line-height: 100px;
	font-weight: 300;
}
.slider-1-cap-line {
	height:3px;
	width:100px;
	background-color: #4b4e53;
}
.slider-bg-white-cap {
	display:none;
	visibility: hidden;
}
/******   CAPTIONS REVO FULL WIDTH  ******/
.font-white, .font-white a{
  color:#fff !important;
}
.light-73-wide{
	font-family: 'Lato',Arial,Helvetica,sans-serif;
	font-size: 73px;
	line-height: 73px;
	font-weight: 300;
  letter-spacing:24px;
  color:#4b4e53;
}
.light-72-wide{
	font-family: 'Lato',Arial,Helvetica,sans-serif;
	font-size: 72px;
	line-height: 72px;
	font-weight: 300;
  letter-spacing:25px;
  color:#4b4e53;
}
.light-70-wide{
	font-family: 'Lato',Arial,Helvetica,sans-serif;
	font-size: 70px;
	line-height: 70px;
	font-weight: 300;
  letter-spacing:24px;
  color:#4b4e53;
}
.light-69-wide{
	font-family: 'Lato',Arial,Helvetica,sans-serif;
	font-size: 69px;
	line-height: 69px;
	font-weight: 300;
  letter-spacing:20px;
  color:#4b4e53;
}
.light-60-wide{
	font-family: 'Lato',Arial,Helvetica,sans-serif;
	font-size: 60px;
	line-height: 60px;
	font-weight: 300;
  letter-spacing:15px;
  color:#4b4e53;
}
.light-60{
	font-family: 'Lato',Arial,Helvetica,sans-serif;
	font-size: 60px;
	line-height: 60px;
	font-weight: 300;
  color:#4b4e53;
}
.light-56-wide{
	font-family: 'Lato',Arial,Helvetica,sans-serif;
	font-size: 56px;
	line-height: 56px;
	font-weight: 300;
  letter-spacing:25px;
  color:#4b4e53;
}
.light-52-wide{
	font-family: 'Lato',Arial,Helvetica,sans-serif;
	font-size: 54px;
	line-height: 56px;
	font-weight: 300;
  letter-spacing:10px;
  color:#4b4e53;
}
.light-50-wide{
	font-family: 'Lato',Arial,Helvetica,sans-serif;
	font-size: 50px;
	line-height: 64px;
	font-weight: 300;
  letter-spacing:3px;
  color:#4b4e53;
}
.light-42-wide{
	font-family: 'Lato',Arial,Helvetica,sans-serif;
	font-size: 42px;
	line-height: 46px;
	font-weight: 300;
  letter-spacing:2px;
  color:#4b4e53;
}
.norm-42-wide{
	font-family: 'Lato',Arial,Helvetica,sans-serif;
	font-size: 42px;
	line-height: 46px;
  letter-spacing:2px;
  color:#4b4e53;
}
.norm-40-wide{
	font-family: 'Lato',Arial,Helvetica,sans-serif;
	font-size: 40px;
	line-height: 46px;
  letter-spacing:2px;
  color:#4b4e53;
}
.norm-16-wide{
	font-family: 'Lato',Arial,Helvetica,sans-serif;
	font-size: 16px;
	line-height: 18px;
	font-weight: 300;
  letter-spacing:3px;
  color:#4b4e53;
}
.norm-16{
	font-family: 'Lato',Arial,Helvetica,sans-serif;
	font-size: 16px;
	line-height: 25px;
	font-weight: 400;
  color:#4b4e53;
}
.w-50{
  width:50%;
}
.a-100, .a-100 span{
  font-size:100px;
  line-height:100px;
}


/*==========  Mobile  ==========*/
@media only screen and (max-width : 478px) {
	.slider-bg-white-cap {
		display:block;
		visibility:visible;
		height:240px;
		width:1500px;
		background-color: rgba(255,255,255,.9);
	}
	.tp-left{
		left:60px !important;
	}
	.tp-caption.center-0-478{
		left: 50% !important;
		-webkit-transform: translateX(-50%) !important;
		-moz-transform: translateX(-50%) !important;
		-ms-transform: translateX(-50%) !important;
		-o-transform: translateX(-50%) !important;
		transform: translateX(-50%) !important;
		-transform: translateZ(0);
	}
	.hide-0-736 {
		display:none;
		visibility: hidden;
	}
	/***** SLIDE 2 Captions	*****/
	.dark-light-61.tp-resp-24 {
		top:44%!important;
	}
	.dark-light-54.tp-resp-24 {
		top:49%!important;
	}
	.dark-black-63.tp-resp-24 {
		top:54%!important;
	}
	/***** SLIDE 3 Captions	*****/
	.dark-light-60.tp-resp-24 {
		top:42%!important;
	}
	.dark-black-60.tp-resp-24 {
		top:47%!important;
	}
}

@media only screen and (max-width : 736px){ 
	.fs16-when-0-736{
		font-size: 16px !important;
		line-height: 16px !important;
	}
	.fs24-when-0-736 {
		font-size: 24px !important;
		line-height: 24px !important;
	}
}
@media only screen and (min-width : 479px) and (max-width : 736px){ 
	.hide-0-736, .hide-479-736 {
		display:none !important;
		visibility: hidden;
	}
}

/*****  SLIDE 2   *****/
.tp-caption.dark-light-61 {
	color:#4b4e53;
	font-family: 'Lato',Arial,Helvetica,sans-serif;
	font-size: 61px;
	font-weight: 300;
	letter-spacing:7px;
}
.tp-caption.dark-black-63 {
	color:#4b4e53;
	font-family: 'Lato',Arial,Helvetica,sans-serif;
	font-size: 63px;
	font-weight: 900;
	letter-spacing:5px;
}
.tp-caption.dark-light-54 {
	color:#4b4e53;
	font-family: 'Lato',Arial,Helvetica,sans-serif;
	font-size: 54px;
	font-weight: 300;
	letter-spacing:7px;
}

/*****  SLIDE 3   *****/
.tp-caption.dark-light-60 {
	color:#4b4e53;
	font-family: 'Lato',Arial,Helvetica,sans-serif;
	font-size: 75px;
	line-height: 85px;
	font-weight: 300;
}
.tp-caption.dark-black-60 {
	color:#4b4e53;
	font-family: 'Lato',Arial,Helvetica,sans-serif;
	font-size: 75px;
	line-height: 85px;
	font-weight: 900;
}
.local-scroll-cont {
	width: 40px;
	height: 40px;
	position:absolute;
	bottom: 0;
	left: 50%;
	margin-left: -20px;
	text-align: center;
	z-index: 20;
	
	-webkit-animation: scroll-down-anim ease-in-out 1s infinite;
  -moz-animation: scroll-down-anim ease-in-out 1s infinite;
  -o-animation: scroll-down-anim ease-in-out 1s infinite;
  animation: scroll-down-anim ease-in-out 1s infinite; 
}
.scroll-down {
  color: #4b4e53;
	font-size: 32px;
}

@-webkit-keyframes scroll-down-anim {
    0%{ -webkit-transform: translateY(-8px); transform: translateY(-8px); }
    50%{ -webkit-transform: translateY(0px); transform: translateY(0px); }
    100%{ -webkit-transform: translateY(-8px); transform: translateY(-8px); }
}
@-moz-keyframes scroll-down-anim {
    0%{ -moz-transform: translateY(-8px); transform: translateY(-8px); }
    50%{ -moz-transform: translateY(0px); transform: translateY(0px); }
    100%{ -moz-transform: translateY(-8px); transform: translateY(-8px); }
}
@-o-keyframes scroll-down-anim {
    0%{ -o-transform: translateY(-8px); transform: translateY(-8px); }
    50%{ -o-transform: translateY(0px); transform: translateY(0px); }
    100%{ -o-transform: translateY(-8px); transform: translateY(-8px); }
}
@keyframes scroll-down-anim {
    0%{ transform: translateY(-8px); }
    50%{ transform: translateY(0px); }
    100%{ transform: translateY(-8px); }
}

/* #IMAGES
================================================== */
	img.scale-with-grid {
		max-width: 100%;
		height: auto; }
	img, video {
		max-width: 100%;
		height: auto;
	}	

/*	#HEADER
=====================================================*/
.logo-row {
	position:relative;
	width:133px;
	z-index:1;
}
.header-transporent-bg-black{
  background:#303236;
}

/* #FEATURES 1 #NEW
=====================================================*/
.fes1-cont {
	padding-top:130px;
	padding-bottom:110px;
}
.fes1-img-cont {
	text-align:center;
}
.fes1-main-title-cont{
	margin:30px 0 40px 0;
}
.title-fs-60 {
	color:#4b4e53;
	font-family: 'Lato',Arial,Helvetica,sans-serif;
	font-size: 60px;
	line-height: 60px;
	font-weight: 300;
}
.line-3-100{
	margin-top:15px;
	height:3px;
	width:100px;
	background:#4b4e53;
}
.fes1-box{
  position:relative;
	padding-left: 55px;
	padding-bottom: 10px;
}
.fes1-box-icon{
	position:absolute;
	top: 0;
	left: 0;
	color:#4b4e53;
	font-size: 35px;
}
.fes1-box h3 {
	color:#4b4e53;
  font-family: 'Open Sans', Arial, Helvetica, sans-serif;
	font-weight: bold;
	font-size:18px;
	margin-bottom:10px;
	margin-top:10px;
}

/* #FEATURES 2-3 #NEW
=====================================================*/

@media only screen and (min-width: 992px) {
  .left-50{
    left:50%;
  }
  .right-50{
    right:50%;
  }
}
.fes2-main-text-cont {
  margin: 0 auto;
  padding: 145px 50px 140px 50px;
  max-width:500px; 
}
.fes2-text-cont{
	margin-top:30px;
}
.fes2-img {
/*****  background: url(../images/fes2.jpg) 50% 50% no-repeat; */
  
  width: 100%;
  display: block;	
  position: relative;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
     
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  overflow: hidden;

  background-repeat: no-repeat;
  background-position: center center;
}
.title-fs-45 {
	color:#4b4e53;
	font-family: 'Lato',Arial,Helvetica,sans-serif;
	font-size: 45px;
	line-height: 45px;
	font-weight: 300;
}
.fes3-img {
/*****  background: url(../images/fes3.jpg) 50% 50% no-repeat;*/
  background-size: cover;
  
  width: 100%;
  display: block;	
  position: relative;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
     
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  overflow: hidden;
  background-repeat: no-repeat;
  background-position: center center;
}
.line-3-70{
	margin-top:11px;
	height:3px;
	width:70px;
	background:#4b4e53;
}

/* #FEATURES 4 #NEW
=====================================================*/
.fes4-cont{
  padding-top:130px;
	padding-bottom:80px;
}
.fes4-box{
  margin-bottom:50px;
}
.fes4-title-cont {
  position: relative;
  padding-left: 70px;
  padding-bottom: 15px;
}
.fes4-box-icon{
  position: absolute;
  top: 7px;
  left: 0;
  color: #4b4e53;
  font-size: 45px;
}
.fes4-title-cont h3, .title-18 {
  font-family: 'Open Sans', Arial, Helvetica, sans-serif;
  color: #4b4e53;
  font-weight: 300;
  font-size: 18px;
  margin: 0;
}
.section-title {
  margin:0;
  padding:22px 25px 24px 25px;
  font-family: 'Open Sans', Arial, Helvetica, sans-serif;
	font-weight:300;
	font-size:30px;
	line-height:36px;
	color:#4b4e53; 
  border-left:#4b4e53 2px solid;
}
.section-title-2 {
  margin:0;
  padding:25px 25px 27px 25px;
  font-family: 'Open Sans', Arial, Helvetica, sans-serif;
	font-weight:300;
	font-size:30px;
	line-height:30px;
	color:#4b4e53; 
}
.section-title-3 {
  margin:0;
  padding:15px 20px 17px 23px;
  font-family: 'Open Sans', Arial, Helvetica, sans-serif;
	font-weight:300;
	font-size:23px;
  font-weight:400;
  letter-spacing:3px;
	line-height:32px;
	color:#4b4e53; 
  border-left:#4b4e53 2px solid;
}

.p-50-cont{ padding-top:50px; padding-bottom:50px;}
.p-60-cont{ padding-top:60px; padding-bottom:60px;}
.p-80-cont{ padding-top:80px; padding-bottom:80px;}
.p-110-cont{ padding-top:110px; padding-bottom:110px;}
.p-140-cont{ padding-top:140px; padding-bottom:140px;}
.pt-110-cont{ padding-top:110px;}
.pt-100-cont{ padding-top:100px;}
.pt-80-b-50-cont { padding-top:80px; padding-bottom:50px;}
.pt-100-b-80-cont { padding-top:100px; padding-bottom:80px;}
.pt-110-b-80-cont { padding-top:110px; padding-bottom:80px;}
.pt-110-b-30-cont{ padding-top:110px; padding-bottom:30px;}
.pt-110-b-40-cont{ padding-top:110px; padding-bottom:40px;}
.pt-110-b-50-cont{ padding-top:110px; padding-bottom:50px;}
.pt-110-b-60-cont{ padding-top:110px; padding-bottom:60px;}
.pt-110-b-80-cont{ padding-top:110px; padding-bottom:80px;}
.pt-120-b-100-cont{ padding-top:120px; padding-bottom:100px;}
.pt-150-b-110-cont{ padding-top:150px; padding-bottom:110px;}

@media only screen and (max-width : 1024px) {
  .p-140-cont{ padding-top:120px; padding-bottom:120px;}
}

@media only screen and (max-width : 768px) {
  .container {
    padding-left:20px;
    padding-right:20px;
  }
  .row-sm-fix {
		margin-right: -20px;
    margin-left: -20px; 
	}
	.fes1-cont {
		padding-top:80px;
		padding-bottom:50px; 
	}
  .fes4-cont{
    padding-top:80px;
    padding-bottom:30px;
  }
  .p-110-cont{ padding-top:80px; padding-bottom:80px;}
  .p-140-cont{ padding-top:80px; padding-bottom:80px;}
  .pt-110-cont, .pt-100-cont{ padding-top:80px;}
  .pt-100-b-80-cont { padding-top:80px; padding-bottom:60px;}
  .pt-110-b-80-cont { padding-top:80px; padding-bottom:50px;}
  .pt-110-b-30-cont{ padding-top:80px; padding-bottom:10px;}
  .pt-110-b-40-cont{ padding-top:80px; padding-bottom:20px;}
  .pt-110-b-50-cont{ padding-top:80px; padding-bottom:20px;}
  .pt-110-b-60-cont{ padding-top:80px; padding-bottom:40px;}
  .pt-110-b-80-cont{ padding-top:80px; padding-bottom:40px;}
  .pt-120-b-100-cont{ padding-top:80px; padding-bottom:50px;}
  .pt-150-b-110-cont{ padding-top:80px; padding-bottom:40px;}
	.title-fs-60 {
		font-size: 40px;
		line-height: 40px;
	}
	.title-fs-45 {
		font-size: 30px;
		line-height: 30px;
	}
	.line-3-100{
		margin-top:10px;
	}
	.fes1-img-cont img{
		height:250px;
	}
	.fes2-main-text-cont{
		padding:70px 30px 70px 30px;
	}
}

@media only screen and (max-width : 480px) {
  .p-140-cont{ padding-top:60px; padding-bottom:60px;}
}

/* #FEATURES 5 #NEW
=====================================================*/
.fes5-box h3 {
  color: #4b4e53;
  font-family: 'Open Sans', Arial, Helvetica, sans-serif;
  font-weight: bold;
  font-size: 18px;
  margin-bottom: 7px;
  margin-top: 7px;
}

/* #FEATURES 6 #NEW
=====================================================*/
.fes6-box{
  margin-top:25px;
  margin-bottom:40px;
}
.fes6-box h3 {
  color: #4b4e53;
  font-family: 'Open Sans', Arial, Helvetica, sans-serif;
  font-weight: normal;
  font-size: 18px;
  margin: 0;
}
.fes6-box .icon {
  color: #4b4e53;
  font-size: 65px;
  line-height:64px;
}
@media (max-width: 767px) {
  .fes6-box{
    text-align:center;
  }
}

/* #FEATURES 7 #NEW
=====================================================*/
.fes7-box {
  position: relative;
  padding-left: 47px;
  padding-bottom: 10px;
}
.fes7-box-icon {
  position: absolute;
  top: 2px;
  left: 0;
  color: #4b4e53;
  font-size: 30px;
}
.fes7-box h3 {
  color: #4b4e53;
  font-family: 'Open Sans', Arial, Helvetica, sans-serif;
  font-weight: bold;
  font-size: 16px;
  margin-bottom: 5px;
  margin-top: 5px;
}
.fes7-text-cont h1 {
  margin-top: 0px;
  margin-bottom: 40px;
}
.fes7-img-cont {
  overflow: hidden;
  position: absolute;
  height: 100%;
  padding: 0;
  top: 0; 
}
.fes7-img {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-position: 50% 50%;
  background-size: cover;
  z-index: 0;
  
  width: 100%;
  display: block;	
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
     
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  overflow: hidden;
  background-repeat: no-repeat;
  background-position: center center;
}
.fes7-img-constr {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 0;
  background-size: cover;
  background-position: 50% 50%;
  z-index: 0;
  
  width: 100%;
  display: block;	
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
     
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  overflow: hidden;
  background-repeat: no-repeat;
  background-position: center center;  
}
.white-bg{
	background-color:#fff;
}
.grey-light-bg{
	background-color:#eee;
}
.grey-dark-bg, .header.no-transparent .header-wrapper.grey-dark-bg{
	background-color:#303236;
}
.yellow-bg{
	background-color:#ffea00 !important;
}
.font-black {
  color:#4b4e53 !important;
}
.font-dark {
  color:#101010;
}
.font-text {
  color:#7e8082;
}

@media (max-width: 992px) {
  .fes7-img-cont {
    position: relative;
  }
  .fes7-img {
    position: relative;
    height: 390px;
  }
}

/* #FEATURES 8 #NEW
=====================================================*/
.fes8-title-cont {
  position: relative;
  padding-left: 52px;
  padding-bottom: 15px;
}
.fes8-title-cont h3 {
  font-family: 'Open Sans', Arial, Helvetica, sans-serif;
  color: #4b4e53;
  font-weight: normal;
  font-size: 16px;
  margin: 0;
}
.fes8-box {
  margin-bottom: 50px;
}
.fes8-box-icon {
  position: absolute;
  top: 0px;
  left: 0;
  color: #4b4e53;
  font-size: 30px;
}

/* #FEATURES 9 #NEW
=====================================================*/
.fes9-img-cont img{
  position:absolute;
  top:0;
  left:0;
}
.fes9-img-center {
  width:416px;
  height:300px;
  margin:0 auto;
  position:relative;
}

@media (max-width: 480px) {
  .fes9-img-center {
    width:280px;
    height:280px;
  }
}

/* #FEATURES 10 CAR #NEW
=====================================================*/
.fes10-container{
  padding: 100px 100px 70px 100px;
	margin-right: auto;
	margin-left: auto;
	position:relative;
	width:100%;
}

@media (max-width: 767px) {
  .fes10-container {
    padding: 80px 20px 50px 20px;
  }
}

/* #SHOP #NEW
=====================================================*/
.container-p-75 {
  padding:0 75px 75px 75px;
  margin-right: auto;
  margin-left: auto;
  position: relative;
  width: 100%;
}
#menu-cart .icon_cart{
  margin-right:9px;
  font-size:14px;
}
.select-md {
  font-size:12px;
  text-transform:uppercase;
}
.font-12 {
  font-size:12px !important;
}
.item-price{
  font-size:24px;
  color:#4b4e53;
}
.lh-19 {
  line-height:19px;
}
.lh-20 {
  line-height:20px;
}
.uppercase {
  text-transform:uppercase;
}
.add-rating {
  font-size:18px;
}
.add-rating i {
  margin-left:2px;
}
.rating {
  float:left;
  font-weight:normal;
}
table.shopping-cart-table tr th, table.shopping-cart-table tr td {
  vertical-align: middle;
}
.shopping-cart-table th{
  color:#4b4e53;
  font-weight:600;
}
.shopping-cart-table .icon-close{
  font-size:24px;
}
.form input[type="text"]:focus, .form input[type="email"]:focus, .form input[type="number"]:focus, .form input[type="url"]:focus, .form input[type="search"]:focus, .form input[type="tel"]:focus, .form input[type="password"]:focus, .form input[type="date"]:focus, .form input[type="color"]:focus, .form select:focus {
  color: #111;
  border-color: rgba(0,0,0, .7);
  outline: none;
}

@media (max-width: 1200px) {
  .container-p-75 {
    padding:0;
  }
}

/* #SHOP #DEPARTMENT #NEW
=====================================================*/
#shop-dep .row{
  margin-left:-5px;
  margin-right:-5px;
}
#shop-dep img{
  width:100%;
}
.shop-dep-text-cont {
  position:absolute;
  left:24px;
  bottom:20px;
  background:#fff;
  padding:17px 20px 15px 20px;
  min-width:160px;
}
.shop-dep-text-cont h4{
  margin:0;
  padding:0;
  font-family: 'Open Sans', Arial, Helvetica, sans-serif;
}
.shop-dep-item  a{
  display:block;
  overflow: hidden;
}
.shop-dep-item  a span{
  color:#7e8082;
}
.shop-dep-item  a span.sale-bold {
  color:#4b4e53;
  font-weight:bold;
}
.shop-dep-item img {
  transition: all .3s cubic-bezier(0.300, 0.100, 0.580, 1.000);
}
.shop-dep-item a:hover img{
  -webkit-transform: scale(1.3) translateZ(0);
	-moz-transform: scale(1.3) translateZ(0);
	-o-transform: scale(1.3) translateZ(0);
	-ms-transform: scale(1.3) translateZ(0);
	transform: scale(1.3) translateZ(0);
}
.shop-dep-item {
  padding-left:5px;
  padding-right:5px;
  position:relative;
}

.light-34 {
  font-weight:100;
  font-size:34px;
  line-height:40px;
}
.block-inline-bg-white {
  display:inline-block;
  background:#fff;
}
.shop-ad-img {
  position:relative;
}
.shop-ad-img img {
  position:absolute;
  bottom:0;
}
.text-middle {
  position: absolute;
  top: 50%;
  -webkit-transform: translateY(-50%);
  -moz-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  -o-transform: translateY(-50%);
  transform: translateY(-50%);
}

@media (max-width: 767px) {
  .block-center-x-767 {
    position: absolute;
    left: 50%;
    -webkit-transform: translateX(-50%);
    -moz-transform: translateX(-50%);
    -ms-transform: translateX(-50%);
    -o-transform: translateX(-50%);
    transform: translateX(-50%); 
  }
}

/* #SHOP #ITEMS #NEW
=====================================================*/
.shop-add-btn-cont {
  margin-right:65px;
}
.shop-sub-btn-cont {
  margin-top:-33px;
  float:right;
}
.shop-sub-btn-cont .post-prev-count {
  margin-left:15px;
}
.shop-price-cont {
  font-size:16px;
  color:#4b4e53;
  margin-bottom:20px;
}
.sale-label-cont {
  position: absolute;
  top: -20px;
  right: 3px;
  font-size: 13px;
}
.sale-label {
  display:inline-block;
  padding-top: 17px;
  height:60px;
  width:60px;
  text-align:center;
  border-radius:50%;
  background:#ffea00;
  color:#4b4e53;
}
.shop-add-btn {
  display:block;
  width:100%;
  padding-left:0 !important;
  padding-right:0 !important;
  text-align:center;
}

/* #SHOP #INFO #NEW
=====================================================*/
.shop-info h6{
  color:#fff;
  text-transform:uppercase;
  margin-bottom:30px;
}
.shop-info .icon{
  font-size:40px;
  line-height:40px;
}

/* #PORTFOLIO 1 GRID #NEW
=====================================================*/
.port-filter{
	list-style: none;
	padding: 0;
	margin: 0 0 45px 0;
	font-size: 13px;
	text-transform: uppercase;
	/* text-align: center; */
	letter-spacing: 2px;
	color: #555;
	cursor: default;
}
.port-filter a{
	display: inline-block;
	margin: 0 21px;
  margin-bottom:5px;
	color: #999;
	text-decoration: none;
  border-bottom: 1px solid transparent;
	
	-webkit-transition: all 0.37s cubic-bezier(0.000, 0.000, 0.580, 1.000);  
    -moz-transition: all 0.27s cubic-bezier(0.000, 0.000, 0.580, 1.000); 
    -o-transition: all 0.27s cubic-bezier(0.000, 0.000, 0.580, 1.000);
    -ms-transition: all 0.27s cubic-bezier(0.000, 0.000, 0.580, 1.000); 
    transition: all 0.27s cubic-bezier(0.000, 0.000, 0.580, 1.000);
	
	cursor: pointer;
}
.port-filter a:hover{
	color: #101010;
	text-decoration: none;
}
.port-filter a.active{
	color: #101010;
  border-color:#101010;
	cursor: default;
}
.port-filter a.active:hover{
	color: #101010;
}
 
/******************************************************************************/ 
.port-grid{
	list-style: none;
	margin: 0;
	padding: 0;
}
.port-item{
	width: 25%;
	float: left;
	margin: 0;
	-webkit-box-sizing: border-box;
	-moz-box-sizing: border-box;
	box-sizing: border-box;
}
.port-item.port-item-width-2{
	width: 50%;
}
.port-grid-2 .port-item{
	width: 50%;
}
.port-grid-3 .port-item{
	width: 33.333%;
}
.container .port-grid-3 .port-item{
	width: 33.2%;
}
.port-grid-5 .port-item{
	width: 20%;
}
.port-grid.port-grid-gut{
  margin: 0 0 0 -15px;
}
.port-grid.port-grid-gut .port-item{
  padding: 0 0 15px 15px;
}
.port-grid .port-item > a{
	display: block;	
	overflow: hidden;	
	padding: 0;
  margin:0;
}
.block-center-y {
  position: absolute;
  top: 50%;
  -webkit-transform: translateY(-50%);
  -moz-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  -o-transform: translateY(-50%);
  transform: translateY(-50%); 
}
.block-center-xy {
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  -moz-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  -o-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}
.title-fs-45-wide {
  color: #4b4e53;
  font-family: 'Lato',Arial,Helvetica,sans-serif;
  font-size: 45px;
  line-height: 58px;
  font-weight: 300;
  letter-spacing: 10px;
}
.w-100 {
  width:100%;
}

@media only screen and (max-width: 1200px) {
  .port-grid-5 .port-item {
      width: 25%;
  }
  .port-text-cont .title-fs-45-wide {
    font-size: 35px;
    line-height: 47px;
    letter-spacing: 5px;
  }
}

/* ==============================
   Tablet landskape
   ============================== */
  
@media only screen and (max-width: 992px) {
	.port-item, .port-grid-3 .port-item, .container .port-grid-3 .port-item, .port-grid-5 .port-item, .port-item.port-item-width-2{
    width: 50%;
  }
  .port-item.port-item-width-2{
    width: 100%;
  }
  #counter-1 .row > div {
     border: none;
  }
}

/* ==============================
   Phone
   ============================== */
  
@media only screen and (max-width: 480px) {
	.port-item, .port-grid-2 .port-item,	.port-grid-3 .port-item, .container .port-grid-3 .port-item, .port-grid-5 .port-item , .port-item.port-item-width-2{
    width: 100%;
  }	
  .port-text-cont .title-fs-45-wide {
    font-size: 25px;
    line-height: 38px;
    letter-spacing: 1px;
  } 
  .port-filter a {
    width:100%;
    display: block;
  }

}

/* #PORTFOLIO 1 #NEW 
=====================================================*/
.port-main-img {
  width: 100%;
  display: block;
  overflow: hidden;
}
.port-overlay-cont {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 0;
  z-index: 2;
  transition: all .3s cubic-bezier(0.300, 0.100, 0.580, 1.000);
}
.port-main-img {
  transition: all .3s cubic-bezier(0.300, 0.100, 0.580, 1.000);
}
.lightbox-item {
  position:relative;
}
.lightbox-item  .port-btn-cont {
  position: absolute;
  top: 50%;
  left: 50%;
  margin-left: -12px;
  margin-top: -28px;
  transition: all .3s cubic-bezier(0.300, 0.100, 0.580, 1.000);
}
.lightbox-item:hover  .port-btn-cont {
  margin-top: -12px;
}
.lightbox-item  .port-btn-cont {
  font-size:26px;
  color:#fff;
}
.lightbox-item  .port-btn-cont.lightbox-video {
  margin-left: -18px;
  margin-top: -32px;
}
.lightbox-item:hover  .port-btn-cont.lightbox-video {
  margin-top: -17px;
}
.lightbox-item  .port-btn-cont.lightbox-video {
  font-size:34px;
}
.port-item:hover .port-overlay-cont, .lightbox-item:hover .port-overlay-cont {
  opacity: 1;
}
.port-item:hover .port-main-img, .lightbox-item:hover .port-main-img {
  -webkit-transform: scale(1.3) translateZ(0);
	-moz-transform: scale(1.3) translateZ(0);
	-o-transform: scale(1.3) translateZ(0);
	-ms-transform: scale(1.3) translateZ(0);
	transform: scale(1.3) translateZ(0);
}
.port-item:hover .port-img-overlay:after, .lightbox-item:hover .port-img-overlay:after {
  background: rgba(16,16,16, .85);
}
.port-item .port-img-overlay:after , .lightbox-item .port-img-overlay:after  {
  content: "";
  display: block;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  background: rgba(255,255,255, 0);
  -webkit-transition: all 0.27s cubic-bezier(0.300, 0.100, 0.580, 1.000);
  -moz-transition: all 0.27s cubic-bezier(0.300, 0.100, 0.580, 1.000);
  -o-transition: all 0.27s cubic-bezier(0.300, 0.100, 0.580, 1.000);
  -ms-transition: all 0.27s cubic-bezier(0.300, 0.100, 0.580, 1.000);
  transition: all 0.27s cubic-bezier(0.300, 0.100, 0.580, 1.000);
}
.port-img-overlay {
  position: relative;
  overflow: hidden;
} 
.port-btn-cont a{
  display:inline-block;
} 
.port-overlay-cont{
  padding: 50px;
  padding-top: 20px;
}
.container .port-grid-5 .port-overlay-cont{
  padding: 20px;
  padding-right: 10px;
}
.port-item:hover .port-overlay-cont, .lightbox-item:hover .port-overlay-cont {
  padding-top: 40px;
}
.container .port-grid-5 .port-item:hover .port-overlay-cont, .container .port-grid-5 .lightbox-item:hover .port-overlay-cont {
  padding-top: 30px;
}
.port-title-cont {
  margin-bottom:0px;
  transition: all .4s cubic-bezier(0.300, 0.100, 0.580, 1.000);
}
.port-item:hover .port-title-cont, .lightbox-item:hover .port-title-cont {
  margin-bottom:15px;
}
.port-title-cont h3 {
  margin:0;
	font-family: 'Open Sans',Arial,Helvetica,sans-serif;
  font-size:16px;
  line-height:24px;
  font-weight:600;
  color: #fff;
}
.port-title-cont h3 a {
  color:#fff;
}
.port-title-cont h3 a:hover{
  color:#dedede;
}
.port-grid-gut li:first-child  .port-overlay-cont{
  padding-right: 40px;
}
.port-title-cont span {
  color: #bebebe;
}
.port-title-cont span a{
  color:#bebebe;
  font-size:12px;
  text-transform: uppercase;
}
.port-title-cont span a:hover{
  color:#fff;
}
.port-btn-cont a{
  color:#bebebe; 
  font-size:18px;
}
.port-btn-cont a:hover{
  color:#fff;
}
.port-view-more-cont {
  margin-top:-1px;
  text-align:center;
}
.port-view-more {
  display:block;
  padding-top:40px;
  padding-bottom:37px;
  background:#eee;
  font-family: 'Lato',Arial,Helvetica,sans-serif;
  font-size:18px;
  line-height:18px;
  font-weight:100;
  transition: all .3s ease 0s;
}
.port-view-more:hover {
  background:#dedede;
  color:#4b4e53;
}
.port-view-more-cont-dark {
  text-align:center;
}
.port-view-more-dark {
  display:block;
  padding-top:55px;
  padding-bottom:52px;
  background:#4b4e53;
  font-family: 'Lato',Arial,Helvetica,sans-serif;
  color:#fff;
  font-size:18px;
  line-height:18px;
  font-weight:normal;
  letter-spacing:4px;
  transition: all .3s ease 0s;
}
.port-view-more-dark:hover {
  background:#101010;
  color:#fff;
}
.port-view-more-inv{
  background:#dedede;
  display:block;
  padding-top:40px;
  padding-bottom:37px;
  font-family: 'Lato',Arial,Helvetica,sans-serif;
  font-size:18px;
  line-height:18px;
  font-weight:100;
  transition: all .3s ease 0s;
}
.port-view-more-inv:hover {
  background:#eee;
  color:#4b4e53;
}
.mobile .port-overlay-cont{
  display:none;
  visibility:hidden;
}

/* #PORTFOLIO SINGLE #NEW
=====================================================*/
.port-detail {
  font-size:12px;
  
}
.port-detail p  {
  margin-bottom:5px;
}
.port-detail p strong {
  width: 80px;
  display: inline-block;
  letter-spacing:1px;
}

/* #CLIENTS 1 #NEW
=====================================================*/
.client-row .col-sm-3, .client1-item {
  padding-top: 20px;
  padding-bottom: 20px;
  border-left: 1px solid #eee;
}
.client-row .col-sm-3:first-child,.client-row  .client1-item:first-child{
  border-left: none;
}
.client-row.border-bot {
  border-bottom: 1px solid #eee;
}
.client-row img {
  height: 100px;
}
.client1-item {
  text-align:center;
}
@media only screen and (max-width: 992px) {
  .client-row .col-sm-3, .client-row.border-bot, .client1-item  {
    border: none;
  }
}

/* #TESTIMONIALS 1 #NEW
=====================================================*/
.quote p{
  margin-bottom:24px;
  color:#4b4e53;
  font-family: 'Lato',Arial,Helvetica,sans-serif;
  font-size:21px;
  line-height:32px;
  font-weight:100;
  font-style:normal;
}
.quote {
  border: none!important;
  position: relative;
  font-size:14px;
}
.quote footer {
  font-size:14px;
  color:#7e8082;
  font-style:normal;
}
@media only screen and (min-width: 1169px) {
  .pl-50-min-1169 {
    padding-left:50px;
  }
  .pr-50-min-1169 {
    padding-right:50px;
  }
}

/* #TESTIMONIALS 2 #NEW
=====================================================*/
.ts-author-cont{
  padding-top:70px;
  padding-bottom:20px;
  position: relative;
  border-bottom: 1px solid #e6e6e6;
}
.ts-author-img{
  display: block;
  position: absolute;
  bottom: 0px;
  right: 0px;
  width: 128px; 
}
.testimonial-2:before {
  content: '"';
  position:absolute;
  top:0;
  left:0;
  font-size:60px;
  line-height:40px;
  color:#dcdddd;
}
.testimonial-2 p{
  margin-bottom: 24px;
  color: #4b4e53;
  font-family: 'Lato',Arial,Helvetica,sans-serif;
  font-size: 21px;
  line-height: 32px;
  font-weight: 100;
  font-style: normal;
}
.testimonial-2 {
  padding: 10px 20px;
  margin: 15px 0 15px 10px; 
  border-left: none;
}

/* #WORK PROCESS 1 #NEW
=====================================================*/
.work-proc-1-bg {
  will-change: background-position;
  margin: 0;
  padding: 0;
/*****  background: #2c2c2c url(../images/work-proc-bg.jpg) fixed;*/
  color:#f1f1f1;
}
.work-proc-1-bg h3, .work-proc-1-bg .icon  {
  color:#f1f1f1;
}
.work-proc-1-bg h2  {
  color:#f1f1f1;
  border-color:#f1f1f1;
}
.work-proc-1-bg p  {
  color:#d3d4d5;
}


/* #ABOUT US 1 #NEW
=====================================================*/
.team-social {
  list-style: none;
  margin: 15px 0 0 0;
  padding: 0;
}
.team-social li {
  display: inline-block;
  font-size: 18px;
  margin: 0 5px;
}
.team-social a {
  color: #97999c;
}
.team-social a:hover {
  color: #101010;
}
.member h3 {
  font-family: 'Open Sans',Arial,Helvetica,sans-serif;
  font-size: 16px;
  line-height:24px;
  font-weight: 700;
  margin: 0;
  padding-top: 20px;
  padding-bottom: 5px;
  position: relative;
}
.member span {
  font-size: 14px;
  font-style: normal;
}
.member{
  text-align: center;
  margin-bottom:30px;
}

/* #ABOUT US 2 #NEW
=====================================================*/
.about-us-2-cont {
  background:#f4f4f4;
}

/* #COUNTERS 1 #NEW
=====================================================*/
.count-number{
  margin-top: 17px;
  font-size:45px;
  line-height:45px;
  color:#4b4e53;
  font-weight:100;
}
.count-title {
  font-size:14px;
}
.count-descr{
  margin-top: 10px;
  margin-bottom: 10px;
}
#counter-1 .row > div {
  border-left: 1px solid #dadada;
}
#counter-1 .row > div:first-child {
  border: none;
}
@media only screen and (max-width: 992px) {
  #counter-1 .row > div {
     border: none;
  }
}

/* #ADS 1 & 2 #NEW
=====================================================*/
.ads-img-cont {
  padding-top:50px;
  padding-bottom:45px;
}

/* #VIDEO ADS 1 #NEW
=====================================================*/
.video-ads-bg {
  will-change: background-position;
  margin: 0;
  padding: 0;
  background:#2c2c2c url(../images/body-bg.jpg) fixed;
}
.video-ads-text-cont {
  margin:0 auto;
  padding-top:100px;
  padding-bottom:100px;
  text-align:center;
  font-family: 'Lato',Arial,Helvetica,sans-serif; 
  font-size:36px;
  line-height:36px;
  font-weight:100;
  color:#f1f1f1;
}
.video-ads-a a:hover{
  color:#a5a5a5;
}
.video-ads-text-cont a{
  display:inline-block;
  padding:20px;
  font-size:80px;
  line-height:63px;
  color:#fff;
  vertical-align:middle;
}
@media only screen and (max-width: 992px) {
  .video-ads-text, .video-ads-a {
    display:block;
  }
  .video-ads-text-cont {
    font-size:28px;
    line-height:28px;
  }
  .video-ads-bg, .work-proc-1-bg {
    background-attachment:scroll;
  }
}

/* #BLOG SECTION 1 #NEW
=====================================================*/
.intro-demo .post-prev-img{
 border:7px solid #e8e8e8;
}
.grey-light-bg .intro-demo .post-prev-img{
 border:7px solid #d3d3d3;
}
.post-prev-img{
  margin-bottom:25px;
}
.post-prev-img img {
  width: 100%;
}
.post-prev-title h1,
.post-prev-title h3{
  margin:0;
	font-family: 'Open Sans',Arial,Helvetica,sans-serif;
  font-size:16px;
  line-height:26px;
  font-weight:600;
}
.blog-main-posts .post-prev-img{
  margin-bottom:30px;
}
.blog-main-posts .post-prev-info {
  margin-bottom: 20px;
}
.blog-main-posts .post-prev-title h1,
.blog-main-posts .post-prev-title h3{
  font-size:18px;
  line-height:32px;
}
.post-prev-img a:hover {
  opacity: .6;
}
.post-prev-img .lightbox-item a:hover {
  opacity: 1;
}
.section-more {
  font-size:14px;
  font-weight:normal;
  color:#7e8082;
}
.section-more:hover {
  color:#101010;
}
.section-more .icon {
  font-size:30px;
  vertical-align:middle;
}
.post-prev-info{
  margin-bottom:14px;
  font-size: 13px;
  text-transform: uppercase;
}
.slash-divider{
  margin-right:7px;
  margin-left:7px;
}
.slash-divider-10{
  margin-right:10px;
  margin-left:10px;
}
.post-prev-info, .post-prev-info a{
  color:#a3a6a8;
}
.post-prev-info a:hover{
  color:#101010;
}
.post-prev-text {
  padding-bottom:20px;
  margin-bottom:20px;
  border-bottom:1px solid #eee;
}
.blog-more{
  font-weight:600;
}
.icon-count {
  font-size:12px;
  margin-left:7px;
}
.post-prev-count {
  margin-left:12px;
  color:#7e8082;
}
.social-menu.dropdown-menu{
  border-radius:0;
  margin-top: 5px;
  border-radius: 0;
  min-width: 0px;
  box-shadow: 0 2px 5px 0 rgba(0,0,0,.26);
  border: none;
  background: #eee;
}
.social-menu.dropdown-menu li{
  display:inline-block;
}
.social-menu.dropdown-menu > li > a {
  padding: 7px 15px;
  color:#7e8082;
}
.social-menu.dropdown-menu > li > a:focus, .social-menu.dropdown-menu > li > a:hover {
  color: #111;
  text-decoration: none;
  background: none;
}
.post-prev-more-cont {
  position:relative;
}
.no-border {
  border:none;
}

@media (max-width: 480px) {
  .section-more.left, .section-more.right {
    float: none !important;
    display: block;
    margin-top:5px;
  }
}

/* #BLOG SECTION 2 #NEW
=====================================================*/
.blog2-post-title-cont, .blog2-post-prev-text {
  margin-bottom:20px;
}
.blog2-post-title-cont .post-prev-title {
  padding:15px 0 15px 20px;
  margin-left:120px;
  border-left: #C9CACB 1px solid;
}
.blog2-post-title-cont {
  position:relative;
}
.post-prev-date-cont {
  position: absolute;
  top: 24px;
  left: 15px;
}
.blog2-date-numb {
  font-size:35px;
  font-weight:100;
}
.blog2-month {
  font-size:13px;
  padding-left:3px;
}
.blog2-post-title-cont .post-prev-info {
  margin:0;
}
.blog2-post-prev-text {

}
@media (max-width: 768px) {
  .blog2-month {
    display:block;
    line-height: 18px;
    padding-left:0;
  }
  .blog2-post-title-cont .post-prev-title {
    margin-left:70px;
  }
  .blog2-date-numb {
    font-size:30px;
  }
  .post-prev-date-cont {
    top: 16px;
    text-align:center;
  }
}

/*	#BLOG #WIDGETS #NEW
===============================================*/
.blog-post .post-prev-text p {
  margin-bottom:20px;
}
.widget-search-form  {
  position:relative;
  width:100%;
  font-size:18px;
}
.widget-search-form  input{
  width:100%;
}
.widget-search-form button {
  position:absolute;
  right:0;
  top:8px;
  background:transparent;
}
.widget-search-form button:hover {
  color: #101010;
}

.widget-title {
  margin-bottom: 20px;
  text-transform: uppercase;
  font-size: 16px;
  font-weight: 600;
  color: #4b4e53;
}
.clearlist, .clearlist li {
  list-style: none;
  padding: 0;
  margin: 0;
  background: none;
}
.widget-menu li {
  padding-bottom: 7px;
}
.widget-menu small {
  color:#aaa;
}
.widget-posts li  {
  font-size: 13px;
  margin-bottom:20px;
}
.widget-posts-img {
  float: left;
  margin: 0 15px 0 0;
}
.tags a {
  display: inline-block;
  margin: 0 2px 5px 0;
  padding: 3px 10px;
  border: 1px solid #e9e9e9;
  font-size: 11px;
  text-transform: uppercase;
  text-decoration: none;
  letter-spacing: 1px;
}
.tags a:hover{
  background:#4b4e53;
  color:#fff;
  border: 1px solid #4b4e53;
}

/* #CONTACT INFO SECTION 1 #NEW
=====================================================*/
.cis-cont{
  position: relative;
  padding-left: 65px;
}
.cis-icon {
  position: absolute;
  top: 25px;
  left: 0;
  color: #7e8082;
  font-size: 35px;
}
.cis-text  {
  padding:16px 0px 18px 27px;
  border-left:1px solid #dedede;
}
.grey-light-bg .cis-text  {
  border-left:1px solid #bdbdbd;
}
.cis-text h3 {
  color: #4b4e53;
  font-weight: 300;
  font-size: 16px;
  line-height: 26px;
  margin: 0;
}
.cis-text p {
  margin: 0;
  font-size: 12px;
}

/* #GMAPS 1 #NEW
=====================================================*/
#google-map, .google-map, .google-map iframe {
  height: 625px;
  position: relative;
  background-color: #eee !important;
  z-index: 1;
}
.google-map iframe{
  display:block;
  width:100%;
}
#google-map.contact-form-with-catcha {
  height: 755px;
}
.gmnoprint, .gm-style-cc {
  display:none !important;
}
.google-map-container {
	text-align:center;
	padding-top: 45px;
}

/* #STATICK MEDIA #NEW
=====================================================*/
.sm-img-bg {
  background-position: 50% 0px;
}
.sm-img-bg-fullscr {
  background-position: 50% 0px;
  background-size:cover;
  
  width: 100%;
  display: block;	
  position: relative;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
     
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  overflow: hidden;
  background-repeat: no-repeat;
}
.sm-video-bg {
/*****  background-image: url(../images/static-media/video-bg.jpg);*/
  background-position: 50% 0px;
  overflow: hidden;
  position:relative;
}
.sm-content-cont{
  display: table;
  height:600px;
}
.sm-content-cont-660{
  height:660px;
}
.sm-content-cont-fullscr{
  display: table;
}

@media only screen and (max-width: 767px){
  .sm-content-cont {
    height: 350px; /*300*/
  }
  .sm-content-cont .light-72-wide, .sm-content-cont-fullscr .light-72-wide{
    font-size:25px;
    line-height:30px;
    letter-spacing:3px;
  }
  .light-72-wide, .sm-content-cont .light-50-wide {
    font-size:21px;
    line-height:30px;
    letter-spacing:1px;
  }
  .slider-text-bg {
    height:45%;
    height:50%;
    width:100%;
    background:rgba(255,255,255,.5);
    background:rgba(103,103,103,1);
    background:rgba(13,13,13,.75);
    position:absolute;
    top:50%;
    -webkit-transform: translateY(-50%);
    -moz-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    -o-transform: translateY(-50%);
    transform: translateY(-50%);
    left:0;
    z-index:0;
  }
  .port-filter a {
    width:40%;
  }
}

@media only screen and (min-device-width : 768px) and (max-device-width : 1024px) {
  .sm-content-cont .light-72-wide, .sm-content-cont-fullscr .light-72-wide{
    letter-spacing:5px;
  }
}
.sm-cont-middle {
  display: table-cell;
  height: 100%;
  vertical-align: middle;
}

/***** BG VIDEO *****/
.sm-video-wrapper{
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0px;
  left: 0px;
  z-index: 0;
} 
.sm-video{
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0px;
  left: 0px;
}
.sm-video-wrapper video{
  max-width: inherit;
  height: inherit;
  z-index: 0;
} 

/*****  ALFA BG  *****/
.bg-img-alfa:before {
  content: " ";
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
/*****  background-image: url(../images/static-media/img-alfa-wide.png);*/
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
  z-index:1;

  display: block;	
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
     
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  overflow: hidden;
  background-repeat: no-repeat;
}
@media only screen and (max-width : 1024px) {
/*****  .bg-img-alfa:before { background-image: url(../images/static-media/img-alfa-wide-1024.png);}*/
}
@media (max-width : 1024px) and (orientation: portrait) {
/*****  .bg-img-alfa:before { background-image: url(../images/static-media/img-alfa-wide-768.png);}*/
}

.bg-dark-alfa-40:before {
  content: " ";
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  background: rgba(3,3,3, .4);
}
.bg-dark-alfa-50:before {
  content: " ";
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  background: rgba(3,3,3, .5);
}
.bg-dark-alfa-60:before {
  content: " ";
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  background: rgba(3,3,3, .6);
}
.bg-dark-alfa-70:before {
  content: " ";
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  background: rgba(3,3,3, .7);
}
.bg-dark-alfa-80:before {
  content: " ";
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  background: rgba(3,3,3, .8);
}
.bg-dark-alfa-90:before {
  content: " ";
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  background: rgba(3,3,3, .9);
}

.sm-mt-0 { margin-top:0px;}
.sm-mt-20 { margin-top:20px;}
.sm-mt-40 { margin-top:40px;}
.sm-mt-50 { margin-top:50px;}
.sm-mt-55 { margin-top:55px;}
.sm-mt-60 { margin-top:60px;}
.sm-mt-70 { margin-top:70px;}
.sm-mb-10 { margin-bottom:10px;}
.sm-mb-15 { margin-bottom:15px;}
.sm-mb-20 { margin-bottom:20px;}
.sm-mb-40 { margin-bottom:40px;}
.sm-mb-50 { margin-bottom:50px;}
.sm-mb-60 { margin-bottom:60px;}
.sm-mb-70 { margin-bottom:70px;}
.sm-mb-80 { margin-bottom:80px;}
.sm-mb-100 { margin-bottom:100px;}

/* #PAGE TITLE #NEW
=====================================================*/
.page-title-cont {
  padding-top:135px;
  padding-bottom:65px;
}
.page-title {
  margin-bottom:20px;
  font-size: 24px;
  line-height: 25px;
  letter-spacing: 5px;
  font-weight: 400;
  text-transform:uppercase;
}
.page-title-font-big {
  margin-bottom:0px;
  font-size: 40px;
  line-height: 40px;
  letter-spacing: 10px;
  font-weight: 600;
  /* color:#6f7175; */
  text-transform:uppercase;
}
.page-sub-title {
  margin-bottom:15px;
  letter-spacing: 3px;
  font-size: 12px;
  line-height:15px;
  text-transform:uppercase;
}
.breadcrumbs {
  margin-top: 53px;
  text-align:right;
  font-size:11px;
  letter-spacing:2px;
  text-transform:uppercase;
}
.breadcrumbs a{
  color:#7e8082;
}
.breadcrumbs a:hover, .bread-current{
  color:#101010;
}
.grey-dark-bg .page-title, .grey-dark-bg .page-title-big{
  color:#fff;
}
.grey-dark-bg .page-sub-title{
  color:rgba(255,255,255, .7);
}
.page-title-cont.grey-dark-bg a{
  color:rgba(255,255,255, .8);
}
.page-title-cont.grey-dark-bg a:hover{
  color:rgba(255,255,255, 1);
}
.page-title-cont.grey-dark-bg .bread-current{
  color:#fff;
}
.page-title-img {
  width: 100%;
	display: block;	
	position: relative;
	-webkit-background-size: cover;
	-moz-background-size: cover;
	-o-background-size: cover;
	background-size: cover;
    
  -webkit-box-sizing: border-box;
	-moz-box-sizing: border-box;
	box-sizing: border-box;
  overflow: hidden;
  background-attachment: fixed;
	background-repeat: no-repeat;
	background-position: center center;
}
.page-title-small {
  padding-top:118px;
  padding-bottom:58px;
}
.page-title-small .page-title{
  margin-top:40px;
  margin-bottom:10px;
}
.page-title-small .breadcrumbs {
  margin-top: 40px;
}

.blog-single-fullwidth-img {
  padding-top:550px;
}

/* #CONTACT MENU
=====================================================*/
.menu-contact-info {
	background-color:#c6c7c9;
	padding:0;
	color: #fff;
}
.menu-map-container {
	padding:0 !important;
}
ul.contact-list, ul.contact-list li {
	margin:0;
	padding:0;
	list-style:none;
}
ul.contact-list {
	padding-top: 85px;
	padding-bottom: 72px;
	padding-left: 28px;
}
ul.contact-list li{
	display: block;
	padding-right: 15px;
	padding-bottom: 12px;
	border:none !important;
}
.loc-icon-container {
	position:absolute;
	top:0;
	left:0;
}
.menu-contact-info .contact-list > li {
	position:relative;
}
.menu-contact-text-container {
	padding-left:60px;
	padding-top: 11px;
	min-height: 46px;
}
.menu-contact-info .contact-list .a-mail {
	color:#fff !important;
	padding:0 !important;
}
.a-mail:hover {
	color:#414146 !important;
	background:none !important;
}
.icon_mail_alt.main-menu-contact-icon {
	padding-top:10px;
	padding-bottom:14px;
}
.menu-contact-info {
	margin-top: -10px;
	margin-bottom: -10px;
}
.main-menu-contact-icon {
  display: block;
  font-size: 18px;
  font-weight: 100 !important;
  padding: 12px;
  border: 2px solid #fff;
  border-radius: 50%;
  color: #fff;
  height: 46px;
  width: 46px;
}
.icon_mail_alt.main-menu-contact-icon {
	padding-top:10px;
	padding-bottom:14px;
}

/* #CONTACT FORM #NEW
=====================================================*/
.contact-form-cont {
	position: relative;
  max-width:550px;
  padding:80px 50px 30px 60px;
}
.contact-form-container-left {
	position: relative;
	margin:0 0 0 40px;
	padding-top:70px;
	padding-bottom:40px;
}
.gray-bg {
	background:#eee;
}
.controled {
  display: block;
  width: 100%;
  line-height: 1.42857143;
  -webkit-transition: border-color ease-in-out .15s,-webkit-box-shadow ease-in-out .15s;
  -o-transition: border-color ease-in-out .15s,box-shadow ease-in-out .15s;
  transition: border-color ease-in-out .15s,box-shadow ease-in-out .15s;
	border-radius:0;
	height:auto;
	box-shadow:none !important;
}
.controled.error {
	border-color:#E42C3E !important;
	z-index:1;
}
label.error {
  font-weight:normal;
  font-size:11px;
  color:#e42c3e;
  position: absolute;
  top: 5px;
  right: 15px;
  /* visibility:hidden !important;
  display:none !important; */
}
.checkbox label.error {
  top: 18px;
  left:0;
}
.gray-bg-container .contact-form-cont .controled.valid , .gray-bg-container .contact-form-container-left .controled.valid {
	border-color:#3db03f;
	z-index:1;
}
.gray-bg-container .contact-form-cont .controled.error , .gray-bg-container .contact-form-container-left .controled.error {
	border-color:#E42C3E;
	z-index:1;
}
#contactSuccess, #contactError{
	margin-top:15px;
	margin-bottom:0px;
  border:none;
  -webkit-box-shadow: 0 2px 5px 0 rgba(0,0,0,.26);
  -moz-box-shadow: 0 2px 5px 0 rgba(0,0,0,.26);
  box-shadow: 0 2px 5px 0 rgba(0,0,0,.26);
  text-align: center;
}
input, textarea, select, .login-select, .controled{
  border:none;
	border-bottom: 1px solid #a6a7a9; 
	border-radius: 0px;
  background:transparent;
	color: #4b4e53;
	font-size: 14px;
	margin: 0;
	outline: medium none;
	padding: 8px 20px 8px 0;
}
.input-border {
  border: 1px solid #dedede; 
  padding:9px 15px  ;
}
select.input-border {
  border: 1px solid #dedede; 
  padding:9px 20px 9px 10px ;
}
.w-100 {
  width:100%;
}

::-webkit-input-placeholder, .controled::-webkit-input-placeholder {color: #4b4e53;}
::-moz-placeholder, .controled::-moz-placeholder           {color:#4b4e53;}/* Firefox 19+*/
:-moz-placeholder, .controled:-moz-placeholder           {color:#4b4e53;}/* Firefox 18- */
:-ms-input-placeholder, .controled:-ms-input-placeholder      {color:#4b4e53;}


:focus::-webkit-input-placeholder {color: #a6a7a9;}
:focus::-moz-placeholder          {color:#a6a7a9;}/* Firefox 19+*/
:focus:-moz-placeholder           {color:#a6a7a9;}/* Firefox 18- */
:focus:-ms-input-placeholder      {color:#a6a7a9;}

.controled:focus, .nl-email-input:focus {
  border-color:#4b4e53;
}
.leave-comment-cont {
  padding:10px 40px 60px 40px;
}
.shipping-cont {
  padding:1px 40px 40px 40px;
}

@media (max-width: 767px) {
  .leave-comment-cont {
    margin-bottom:80px;
  }
  .display-none-767{
    display:none;
  }
}

/* #FOOTER 1 NEW
=====================================================*/
.footer-copy {
  margin-top:5px;
}
.footer-copy a{
  font-size:11px;
  color:#7e8082;
}
.footer-soc-a a {
  font-size:16px;
  padding:12px;
}

/* #FOOTER 2 NEW
=====================================================*/
.footer-2-copy-cont {
  padding-top:30px;
  border-top: 1px solid #eee;
}
.widget{
  margin-bottom:60px;
}
.footer-2-copy {
  font-size:11px;
  color:#7e8082;
}
.footer-2-copy:hover {
  color:#101010;
}
.footer-2-soc-a a {
  font-size:16px !important;
  padding:7px 11px !important;
}
.logo-footer{
  max-height:30px;
}
.logo-footer-cont{
  margin-bottom:40px;
}
.footer-2-text-cont {
  margin-bottom:24px;
}
.widget h4 {
  margin-top: 16px;
  margin-bottom: 21px;
  font-family: 'Open Sans', Arial, Helvetica, sans-serif;
  font-size:18px;
  font-weight:bold;
}
.widget .links-list {
  list-style: none;
  margin: 0;
  padding:0;
}
.widget .links-list li{
 padding-bottom:7px;
}
.entry-title h4 {
  margin: 0;
  font-size: 14px;
  font-weight: 600;
}

.widget .post-prev-title a{
  font-size: 14px;
  line-height:21px;
  font-weight: normal;
}
.widget .post-prev-info{
  text-transform:none;
  line-height:18px;
}
.container-m-60 {
  padding-right: 60px;
  padding-left: 60px;
  margin-right: auto;
  margin-left: auto;
  position: relative;
  width: 100%;
}

@media (max-width: 992px) {
  .container-m-60 {
    padding-right: 20px;
    padding-left: 20px;
  }
}

/* #FOOTER 2 BLACK #NEW
=====================================================*/
.footer2-black {
  background: #303236;
  color:rgba(255,255,255, .7);
}
.footer2-black .widget h4 {
  color:#fff;
}
.footer2-black .a-text, .footer2-black .a-text-cont a {
  color: rgba(255,255,255, .7);
}
.footer2-black .a-text:hover, .footer2-black .a-text-cont a:hover {
  color: #fff;
}
.footer2-black .footer-2-copy-cont {
  padding-top: 30px;
  border-top: 1px solid rgba(255,255,255, .1);
}
.footer2-black .footer-2-soc-a a {
  color: rgba(255,255,255, .3); 
}
.footer2-black .footer-2-soc-a a:hover, .footer2-black .footer-2-copy:hover {
  color: #fff; 
}

/* #MENU #NEW
=====================================================*/

/***** #MAIN-MENU MENU *****/
#main-menu {
  z-index: 1;
}
#main-menu .navbar {
  background: none;
  border: 0 none;
  margin: 0;
}
#main-menu .navbar .navbar-collapse {
  padding: 0;
  position: static; 
  border:none;
  box-shadow:none;
}
#main-menu .navbar .nav {
  margin: 0;
  position: static;
}
#main-menu .navbar .nav > li {
  position: relative;
  z-index:5;
}
#main-menu .navbar .nav > li > a {
  font-size:12px;
  color:#5c6066;
  z-index: 1;
  text-shadow: none;
  transition: padding .4s ease 0s, border-color .6s ease 0s;
  -webkit-transform: translateZ(0);
  -moz-transform: translateZ(0);
  -ms-transform: translateZ(0);
  -o-transform: translateZ(0);
  transform: translateZ(0);
}
.black-header #main-menu .navbar .nav > li > a {
  color: rgba(255,255,255, .7);
}

/* Effect 3: bottom line slides/fades in */
.affix #main-menu .navbar .nav > li > a {
  transition: padding .4s ease 0s; 
  -webkit-transform: translateZ(0);
  -moz-transform: translateZ(0);
  -ms-transform: translateZ(0);
  -o-transform: translateZ(0);
  transform: translateZ(0);
}
#main-menu .navbar .nav > li > a .main-menu-title{
	line-height:20px;
}
#main-menu .navbar .nav > li > a {
	padding:38px 3px 5px 3px;
	margin: 0 12px 17px 12px;
	border-bottom: 2px solid rgba(45,78,83,0);
	color:#4b4e53;
  letter-spacing:2px;
  background-color: transparent;
  display: block;
}
.affix.header-1 #main-menu .navbar .nav > li > a {
	margin-bottom: 13px;
	padding: 20px 3px 5px 3px;
}
.header-1 #main-menu .navbar .nav > li.current > a {
	padding:38px 3px 5px 3px;
	margin: 0 12px 17px 12px;
	border-bottom: 2px solid rgba(45,78,83,1);
	color:#4b4e53; 
}
.black-header #main-menu .navbar .nav > li.current > a {
	border-bottom: 2px solid rgba(255,255,255,1);
	color:#fff; 
}
.affix.header-1 #main-menu .navbar .nav > li.current > a{
  margin-bottom: 13px;
	padding: 20px 3px 5px 3px;
	border-color:rgba(75,78,83,.5); 
}
.affix.header-1.black-header #main-menu .navbar .nav > li.current > a{
	border-color:rgba(75,78,83,0); 
}

/***** #INDENT MAIN CONTAINER	( responsive in "media query" section)*****/
.page-main-content.indent-header-1.affix-indent {
	padding-top: 60px;
	transition:padding-top .4s ease;
}
.page-main-content.indent-header-1{
	padding-top: 95px;
	transition:padding-top .4s ease;
}
/************************************/
.header.header-not-sticky{
	position:relative !important;
}
.header.header-always-sticky{
	position:fixed !important;
	z-index:9999;
	-webkit-box-shadow: 0 1px 15px rgba(0,0,0, .15);
	-moz-box-shadow: 0 1px 15px rgba(0,0,0, .15);
	box-shadow: 0 1px 15px rgba(0,0,0, .15);
}
/***** #STICKY ICON BUTTON ANIMATION	*****/


/**********************************************************/
.main-menu-icon {
	display:block;
	font-size:18px;
	font-weight:100 !important;
	padding:12px;
	border:2px solid #a3a7ad;
	border-radius: 50%;
	color:#a3a7ad;
	height:46px;
	width:46px;
}
.main-menu-icon.icon_house_alt.main-menu-icon {
	padding-top:10px;
}.main-menu-icon.icon_toolbox_alt.main-menu-icon {
	padding-top:10px;
}
.main-menu-icon.icon_mail_alt.main-menu-icon {
	padding-bottom:14px;
	padding-top:10px;
}
#main-menu .navbar .nav > li:hover > a {
  color:#111; 
}
.black-header #main-menu .navbar .nav > li:hover > a {
  color:#fff; 
}
#main-menu .navbar .nav .parent {
  position: relative;
}
#main-menu .sub {
  background: #4B4E53;
  -webkit-box-shadow: 0 4px 5px rgba(0,0,0, .2);
  -moz-box-shadow: 0 4px 5px rgba(0,0,0, .2);
  box-shadow: 0 4px 5px rgba(0,0,0, .2);
  display: none;
  margin:0;
  padding: 10px 10px 10px 24px;
  position: absolute;
  z-index: 905;
}
#main-menu .sub li {
  font-size: 12px;
  line-height: 24px;
  text-transform:uppercase;
}
/*****	#ANIMATION FOR SUB MENU	*****/
#main-menu .parent:hover > .sub {	
  display: block ; 
  -webkit-animation: fadeIn .4s;
  animation: fadeIn .4s;
}
#main-menu .sub a {
  color: #d3d3d3;
  display: block;
  text-decoration: none;
  min-width: 160px;
  padding:9px;
  padding-left:0; 
  transition: color .3s ease 0s, padding .3s ease 0s;
  /* -ms-transition: none; */
}
#main-menu .sub a:hover {
	color: #fff;
	padding-left:7px;
	transition: color .2s ease 0s, padding .3s ease 0s;
}
#main-menu .sub a.current {
	color: #fff;
	transition: all .2s ease 0s;
}
#main-menu .sub .parent:hover > a {
	color: #fff;
	padding-left: 7px;
}
#main-menu .sub .sub {
  left: 100%;
  top: 0;
}
#main-menu .navbar .nav .parent .parent > a:after {
  content: "\e232";
  font-family: linea-icons;
  font-style: normal;
  font-weight: normal;
  text-transform: none;
  speak: none;
  font-size: 15px;
  display: block;
  line-height: 1;
  position: absolute;
  right: 10px;
  top: 14px;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

/*	#MEGAMENU
============================================================*/
#main-menu .navbar .nav .parent.megamenu {
  position: static; 
}
#main-menu .navbar .nav .parent.megamenu > a {
  position: relative;
} 
#main-menu .megamenu > .sub {
  padding-right:0;
  padding-left:0;
	box-sizing: border-box;
  left:0;
  right:0;
  width: 100%;
  z-index: 900;
}
nav ul {
	list-style: none;
	margin: 0;
	padding: 0;
}

/****************************************************************************/
.header .primary .parent:hover > .sub {
  display: block;
}
#main-menu .megamenu .title {
	font-size: 18px;
	line-height: 25px;
	margin: 0;
	margin-top:20px;
	margin-bottom: 3px;
	padding-bottom: 10px;
	text-transform: uppercase;
	font-family: 'Lato',Arial,Helvetica,sans-serif;
	font-weight:300;
	color:#fff;
  border-bottom: 1px solid #585a5f;
}
.megamenu .box.col-md-3, .megamenu .box.col-md-2, .megamenu .box.col-md-4{
	padding-left:24px;
}
.megamenu .box.col-md-3 .icon{
  float:left;
  display: inline-block;
  margin-right: 14px;
  margin-top: 3px;
  font-size:16px;
  line-height: 16px;
}
#main-menu .megamenu > .sub a {
  width: 100%;
}
#main-menu .sub .sub .sub .sub {
  background: none;
  border: 0 none;
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  box-shadow: none;
  display: block;
  left: 0;
  padding: 0;
  position: relative;
  -webkit-animation: fadeIn 0;
  animation: fadeIn 0;
}
#main-menu .sub .sub .sub .parent > a {
  background: none !important;
}
.navbar-collapse.right {
	border:none;
}

/* #PRIMARY MENU
================================================== */
.header .primary {
  margin-top: 7px;
}
.header .primary .navbar {
  margin: 0 0 0 80px;
}
.header .nav .open>a, .header .nav .open>a:hover, .header .nav .open>a:focus {
background:none;
}
.header .primary .navbar .nav {
  float: none;
  margin: 0;
}
.header .primary .navbar .nav > li {
  padding: 0 4px;
  position: relative;
}
.header .primary .navbar .nav > li > a{
  border-width: 1px;
  border-color: transparent;
  color: #1e1e1e;
  padding: 11px 15px;
  text-shadow: none;
  -webkit-transition: all .2s linear;
  transition: all .2s linear;
}
.header .primary .navbar .nav > li:hover > a {
  background: #fff;
  border-color: #ccc;
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
  border-radius: 3px;
  -webkit-box-shadow: 0 0 5px rgba(0,0,0,.15);
  -moz-box-shadow: 0 0 5px rgba(0,0,0,.15);
  box-shadow: 0 0 5px rgba(0,0,0,.15);
  -webkit-transition: all .2s linear;
  transition: all .2s linear;
}
.header .primary .navbar .nav .parent {
  position: relative;
}
.header .primary .navbar .nav > li.parent:hover > a {
  background: #fff;
  border-bottom-color: transparent;
  -webkit-border-radius: 3px 3px 0 0;
  -moz-border-radius: 3px 3px 0 0;
  border-radius: 3px 3px 0 0;
}
.header .primary .navbar .nav > li.parent:hover > a:after {
  background: #fff;
  bottom: 0;
  content: "";
  display: block;
  height: 10px;
  left: 5px;
  margin: 0 0 -3px 0;
  position: absolute;
  right: 5px;
  z-index: 49;
}
.header .primary .sub {
  background: #fff;
  border: 1px solid #ccc;
  border-radius: 0 3px 3px 3px;
  -webkit-box-shadow: 0 0 5px rgba(0,0,0,.15);
  -moz-box-shadow: 0 0 5px rgba(0,0,0,.15);
  box-shadow: 0 0 5px rgba(0,0,0,.15);
  display: none;
  font-size: 13px;
  line-height: 24px;
  padding: 15px 20px;
  position: absolute;
  z-index: 48;
}
.header .primary .sub li {
  font-size: 13px;
  line-height: 24px;
}
.header .primary .parent:hover > .sub {
  display: block;
  -webkit-animation: fadeIn .4s;
  animation: fadeIn .4s;
}
.header .primary .sub a {
  color: #1e1e1e;
  display: block;
  text-decoration: none;
  width: 175px;
  -webkit-transition: opacity .2s linear;
  transition: opacity .2s linear;
}
.header .primary .sub a:hover {
  opacity: .6;
  filter: alpha(opacity=60);
  -webkit-transition: opacity .2s linear;
  transition: opacity .2s linear;
}
.header .primary .sub .sub {
  left: 100%;
  top: 0;
}
.header .primary .navbar .nav .parent .parent > a,
.header .primary .navbar .nav .parent:hover .parent > a{
/*****  background-image: url("../images/caret.png");*/
  background-repeat: no-repeat;
  background-position: 100% 50%;
}
.header .primary .navbar .nav .parent.megamenu {
  position: static;
}
.header .primary .navbar .nav .parent.megamenu > a {
  position: relative;
}
.header .primary .navbar .nav > li.parent.megamenu:hover > a:after {
  margin-left: -5px;
  margin-right: -5px;
}
.header .primary .megamenu > .sub {
  border-radius: 3px;
  left: 4px;
  margin: 0;
  padding: 35px 40px 35px 0;
  width: 844px;
}
.header .primary .megamenu > .sub .box {
  float: left;
  padding: 0 0 28px 40px;
  width: 240px;
}
.header .primary .megamenu.four-columns > .sub .box {
  width: 170px;
}
.header .primary .megamenu.five-columns > .sub .box {
  width: 128px;
}
.header .primary .megamenu > .sub .box.first {
  clear: left;
}
.header .primary .megamenu > .sub .promo-block {
  float: right;
  font-size: 14px;
  line-height: 18px;
  position: relative;
}
.header .primary .megamenu > .sub .promo-block:before {
  border-left: 1px solid #e6e6e6;
  bottom: -35px;
  content: "";
  display: block;
  position: absolute;
  left: 5px;
  top: -35px;
}
.header .primary .megamenu > .sub .promo-block .promo-article {
  margin: 2px 0 11px;
}
.header .primary .megamenu > .sub .promo-block .promo-article img {
  float: left;
  margin: 7px 19px 15px 0;
  max-height: 70px;
  max-width: 70px;
}
.header .primary .megamenu > .sub .promo-block p {
  font-size: 14px;
  line-height: 18px;
  margin: 0 0 18px 0;
}
.header .primary .megamenu > .sub .promo-block .big-image {
  margin-bottom: -63px;
}
.header .primary .megamenu .title {
  font-size: 13px;
  line-height: 23px;
  margin: 0 0 3px;
  text-transform: uppercase;
}
.header .primary .megamenu > .sub a {
  width: 100%;
}
.header .primary .sub .sub .sub .sub {
  background: none;
  border: 0 none;
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  box-shadow: none;
  display: block;
  left: 0;
  padding: 0;
  position: relative;
  -webkit-animation: fadeIn 0;
  animation: fadeIn 0;
}
.header .primary .sub .sub .sub .parent > a {
  background: none !important;
}
/***********************************************************/

/* #HEADER FIX #NEW
=====================================================*/
.header .search .main-menu-icon {
	transition: margin-top .4s ease, margin-bottom .4s ease, border .9s ease .2s;
}
.affix.header .search .main-menu-icon {
	border-color: rgba(0,0,0, 0);
	opacity:1;
	height:46px;
	width:46px;
	padding:12px;
	margin-bottom: 7px;
	margin-top: 7px;
	transition: margin-bottom .3s ease, margin-top .3s ease, border .3s ease;
}  
.header .sb-search-open  .main-menu-icon{
	transition: opacity .5s ease .2s, height .3s ease 0s, margin-bottom 0s ease 0s, margin-top 0s ease 0s, border .5s ease;
}
.header {
	position:fixed; 
	left:0;
	transition: background .3s ease, height .4s ease;
	width:100%;
	z-index:29;
}
.affix.header {
	position: fixed;
	height: 60px;
	transition: height .4s ease;
}
.header-big-offset {
  padding-top:136px;
}
.header-wrapper{
	position: relative; 
	width:100%;	
	transition: height .3s ease 0s, background .3s ease 0s;
	-webkit-transform: translateZ(0);
  -moz-transform: translateZ(0);
  -ms-transform: translateZ(0);
  -o-transform: translateZ(0);
  transform: translateZ(0);
	z-index:29;
}
.header.no-transparent .header-wrapper{
  background:#fff;
}
.border-b{
  border-bottom: 1px solid #eee;
}
.affix .header-wrapper {
	/* position:fixed; */
	top:0;
	left:0;
	z-index:100;
	background: #fff;
	transition: height .3s ease 0s, background .3s ease 0s; 
	-webkit-transform: translateZ(0);
  -moz-transform: translateZ(0);
  -ms-transform: translateZ(0);
  -o-transform: translateZ(0);
  transform: translateZ(0);
	-webkit-box-shadow: 0 1px 15px rgba(0,0,0, .15);
	-moz-box-shadow: 0 1px 15px rgba(0,0,0, .15);
	box-shadow: 0 1px 15px rgba(0,0,0, .15);
}
.affix.black-header .header-wrapper {
	background: #303236;
}
#nav-below.header {
	position:relative;
}
.is-sticky .header {
	-webkit-box-shadow: 0 1px 15px rgba(0,0,0, .15);
	-moz-box-shadow: 0 1px 15px rgba(0,0,0, .15);
	box-shadow: 0 1px 15px rgba(0,0,0, .15);
} 
.header .container-m-30 {
	position: relative;
}
.header-no-bg, .header-no-bg .header-wrapper {
	background: none;
}
.affix.header-no-bg, .affix.header-no-bg .header-wrapper {
	background: #fff;
}
.container-m-30 {
	padding-right: 30px;
	padding-left: 30px;
	margin-right: auto;
	margin-left: auto;
	position:relative;
	width:100%;
}

@media (min-width: 1025px) {
  .indent-header {
    padding-top:93px;
  }
}

/* #LOGO
================================================== */
.logo-container {
  width: 120px;
	background-color: #414146;
	transition: all .3s ease 0s;
}
/* .logo-container-2 {
	transition: all .3s ease 0s;
} */
header .logo {
	display: block;
	padding-bottom: 15px;
	padding-left: 45px;
  padding-top: 81px;
	transition: all .4s ease 0s;
}
footer .logo {
	display: block;
	padding-bottom: 15px;
	padding-left: 45px;
  padding-top: 81px;
	transition: all .4s ease 0s;
	height: 120px;
}
header.affix .logo {
	padding-top:20px;
	padding-bottom: 16px;
	transition: all .4s ease 0s;
}
header.affix  .logo-2, .header.affix.no-transparent .logo-2 {
	padding-top:9px;
	padding-bottom:9px;
	transition: all .4s ease 0s;
}
/*.logo-img {
  height: 42px;
}*/

header .logo .logo-img {
	transition: all .4s ease 0s;
}
header.affix .logo .logo-img {
	transition: all .4s ease 0s;
}
.logo-2 {
	display: block;
	padding:33px 0 25px 0;
	transition: all .4s ease 0s;
}
.header.no-transparent .logo-2 {
	padding:26px 0 25px 0;
}

/* #BREADCRUMB 
================================================== */
.breadcrumb {
	background:none;
	border:none;
	border-radius: 0;
  font-weight: 300;
	font-size:12px;
	text-transform:uppercase;
	padding-top:2px;
	padding-left:0;
	margin-bottom: 0;
}

/* #CAROUSEL 
================================================== */
/*****	#OWL CONTROLS STYLE 2	*****/
.item{
	margin:0 15px 30px 15px;
}
.owl-no-row .item{
	margin:0 0 30px 0;
}
#owl-1-pag .item{
	margin:0 15px 0px 15px;
}
.customNavigation{
	float:right;
}
.customNavigation a{
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

/*****************************************/
.carousel-va-container {
	float:left;
}
.customNavigation-container {
	float:left;
}
.carousel-va {
	cursor: pointer;
	padding-right: 15px;
  padding-top: 8px;
	font-family: 'Lato',Arial,Helvetica,sans-serif;
	font-weight:300;
}
.pos-relative {
	position:relative;
}

/* #NEWSLETTER NEW
=====================================================*/
.nl-cont {
  padding-top:80px;
  padding-bottom:45px;
  background:#eee;
}
#mc-embedded-subscribe {
  font-family: 'Open Sans',Arial,Helvetica,sans-serif;
  font-weight:normal;
  margin-bottom:30px;
}
.newsletterform {
	float:right;
	border: none;
	margin:0;
	padding:0;
}
.nl-email-input {
	width:350px;
  margin-right:80px;
  margin-bottom:30px;
  font-size:21px;
  font-family: 'Lato',Arial,Helvetica,sans-serif;
  font-weight: lighter;
  border-color:#4b4e53;
}
#notification_container {
	position:absolute;
	top:0px;
	right:0;
	z-index:3;
  width:100%;
}
#notification_container .alert{	
	padding-top:11px;
	padding-bottom:10px;
	box-shadow: 0 2px 5px 0 rgba(0,0,0,.26);
}
#notification_container a{
	color:#245269;
	font-weight: 700;
}
#notification_container a:hover{
	text-decoration: underline;
}

@media only screen and (max-width : 992px) {
  .nl-email-input {
    width: 250px;
    margin-right: 30px;
  }
  .newsletterform {
    float:none;
    text-align: center;
  }
}
@media only screen and (max-width : 480px) {
  #google-map, #google-map.contact-form-with-catcha, .google-map iframe {
    height:300px;
  }
}

/* #OUR CLIENTS #NEW
================================================== */ 
#owl-clients .item{
	margin: 0 50px;
}
#owl-clients .item img{
  width:100px;
}
.our-clients-cont {
	padding:60px 0;
}

/* #TABS 3 #NEW
================================================== */
.tabs-3 .nav-tabs>li>a {
  border-radius: 0; 
  font-size:12px;
  letter-spacing:1px;
  padding:14px 20px;
}
.tabs-3 .nav-tabs>li.active>a, .tabs-3 .nav-tabs>li.active>a:focus, .tabs-3 .nav-tabs>li.active>a:hover {
  color:#101010;
}

@media only screen and (max-width: 480px) {

  .xs-tabs-transform.nav-tabs {
    border-bottom: none;
  }
	.xs-tabs-transform.nav-tabs>li{
    /* float:none; */
    margin-bottom:0;
  }
  .xs-tabs-transform.nav-tabs>li.active>a, .xs-tabs-transform.nav-tabs>li.active>a:focus, .xs-tabs-transform.nav-tabs>li.active>a:hover {
    border-color:transparent;
    border-bottom-color:#101010;
    
  }
  .xs-tabs-transform.nav-tabs>li>a, .xs-tabs-transform.nav-tabs>li>a:focus, .xs-tabs-transform.nav-tabs>li>a:hover {
    padding-right:10px;
    padding-left:10px;
    /* border-bottom-color:#dedede; */
  }

}

/* #TABS
================================================== */ 
.tab-pane {
	background-color:#fff;
	border:1px solid #d6d6d6;
	border-top:none;
}
.tabs.custom-tabs .nav-tabs li.active a{
	color:#4b4e53;
	background:#eee;
		border:none;
}
.tabs.custom-tabs .nav-tabs a{
	color:#7e8082;
	border:none;
	border-radius:0px;
	padding: 15px 20px;
}
.tabs.custom-tabs .nav-tabs {
	border-bottom: 1px solid #eee;
}
.tabs.custom-tabs .tab-pane {
	background-color: #fff;
	border: 15px solid #eee;
	border-top: 14px solid #eee;
	border-left: none;
}
.tabs.custom-tabs .tab-content-container {
	padding:15px 15px 5px 0;
}

/***** #CUSTOM TAB VERTICAL	*****/
.tabs-vertical .nav-tabs > li {
	display: block;
	width:100%;
}
.tabs-vertical.custom-tabs.nav-tabs>li>a {
	border: none;
}
.tabs-vertical.tabs.custom-tabs .nav-tabs {
	border-bottom: none;
}
.tabs-vertical.custom-tabs .tab-pane {
	background-color: #fff;
	border: 15px solid #eee;
	border-right: none;
}
.tabs-vertical.custom-tabs .tab-content-container {
	padding:15px 0 5px 15px;
}
.tabs-vertical.tabs .nav-tabs>li>a {
	margin-right: 0;
}
.tabs-vertical .nav-tabs {
	border-bottom: none;
	display: table-cell;
	height: 100%;
	float: none;
	padding: 0;
	vertical-align: top;
}
.tabs-vertical .tab-content {
	display: table-cell;
	vertical-align: top;
}
.bootstrap-tabs {
	margin-bottom: 20px!important;
}
.tab-pane {
	border:none;
}

/* #BLOCKQUOTES #NEW
==================================================*/
blockquote {
  padding: 10px 20px;
  padding-left:30px;
  margin: 0 0 20px;
  border-left: 3px solid #eee;
}
blockquote, blockquote p {
  font-style: normal;
  font-family: 'Lato',Arial,Helvetica,sans-serif;
  font-size: 21px;
  line-height:32px;
  color:#4b4e53;
	font-weight:100;
}
blockquote p {
  margin-bottom:13px;
}
blockquote .small, blockquote footer, blockquote small{
  font-family: 'Open Sans',Arial,Helvetica,sans-serif;
  font-size: 13px;
  color: #7e8082;
  font-style: normal;
}

/* #BLOCKQUOTES
==================================================*/

blockquote {
  font-size:16px;
}
.blockquote-reverse, blockquote.pull-right {
  padding-right: 25px;
}

blockquote.custom-blockquote, blockquote.custom-blockquote p {
	font-size: 16px;
	line-height: 24px;
	color: #777;
	font-style: italic;
}
blockquote.custom-blockquote {
	margin: 0 0 10px 0;
	padding: 25px 20px 19px 26px;
	position:relative;
	background-color:#f8f8f8;
  border-left: 40px solid #ffea00;
}
blockquote.custom-blockquote:before {
	z-index:9;
	height:100%;
	width:38px;
	display:block;
	content:'"';
	position:absolute;
	left:-29px;
	top:47%;
  font-size:42px;
  font-style:normal;
}

/* #TESTIMONIALS
================================================== */
.ts-container {
	position:relative;
}
.ts-icon-container-bg {
	background-color: rgba(255,234,0,.5);
	padding-top:52px;
	padding-bottom:53px;
	width:85px;
	float:left;
	position:absolute;
	top:0;
	left:0;
}
.ts-icon-container {
	background-color:#ffea00;
	width:85px;
	height:85px;
	opacity:1;
	text-align:center;
	padding:19px;
}
.ts-icon-container span {
	padding:0;
	margin:0;
	padding-bottom: 14px;
	padding-top: 8px;
	font-size:26px;
	color:#4b4e53;
	border-color:#4b4e53;
}
.ts-main-container-bg {
	background-color:#eee;
}
.ts-main-container-bg{
	margin-left: 85px;
}
.ts-lines:before {
	content: "";
	display: block;
	width: 300%;
	height: 40px;
	background: #dfdfdf;
	position: absolute;
	top: 0;
	left: -300%;
	filter: alpha(opacity=50);
	height:190px;
}
.ts-text-container {
	position:relative;
	min-height:190px; 
	padding-top: 70px;
	padding-left: 40px;
	padding-right: 240px;
	font-size: 14px;
	line-height: 22px;
}
.ts-img-container {
	width:200px; 
	height:190px; 
	position:absolute;
	right:0;
	top:0;
}
.ts-img-container img{
	width:200px; 
	height:190px; 
}
.tls-small-text-container {
	background-color:#eee;
	padding:25px;
	padding-bottom:75px;
}
.tls-small-img-container {
	float:left;
	margin-right: 15px;
}
.tls-small-author-name{
	font-size:14px;
  line-height:15px;
	color:#4b4e53;
}
.tls-small-author-role {
  margin-top:5px;
  font-size:12px;
}
.tls-small-author-container {
	margin-top: -55px;
}
.bg-light-gray {
	background:#eee;
}
.bg-white {
	background:#fff;
}
.testimonials {
	position:relative;
}
.quote-author {
	position: relative;
	left: 15px;
	margin-top: 20px;
}
.quote-author-description {
	display: block;
	font-size: 12px;
}
.author-testimonial {
	display: inline;
	margin-top: 7px;
	font-size: 16px;
	font-family: 'Lato',Arial,Helvetica,sans-serif;
	color: #4b4e53;
}

/* #PAGE TITLE
================================================== */
.page-title-bg {
	background:rgba(255, 234, 0, .5);
}

/* #404
================================================== */
.error404-text {
	text-align:center;
	color:#5a5a5a;
	font-family: 'Lato',Arial,Helvetica,sans-serif;
	font-size:30px;
	line-height:30px;
	margin-bottom: 25px;
  margin-top: 25px;
}
.error404-numb {
	color:#909090;
	text-align:center;
	font-family: 'Lato',Arial,Helvetica,sans-serif;
	font-size:250px;
	line-height:250px;
	margin-bottom: 40px;
  margin-top: 30px;
}
.error404-main-text h2 {
	text-align:center;
	font-family: 'Lato',Arial,Helvetica,sans-serif;
	font-size:30px;
	line-height:30px;
	padding-bottom: 15px;
  padding-top: 20px;
	padding-left: 15px;
  padding-right: 15px;
}

/* #COMING SOON
================================================== */
.countdown-item-container {
	margin: 0 auto;
	margin-bottom: 20px;
	padding: 20px;
	padding-top: 38px;
	width: 150px;
	height: 150px;
	background: #4b4e53;
}
.countdown-item-container2 {
	margin: 0 auto;
	margin-bottom: 20px;
	padding: 20px;
	padding-top: 33px;
	width: 150px;
	height: 150px;
  border:5px solid #fff;
  border-radius:50%;
}
.countdown-amount {
	display:block;
	font-family: 'Lato',Arial,Helvetica,sans-serif;
	font-weight:300;
	font-size: 40px;
	line-height: 50px;
	color: #fff;
}
.countdown-period {
	display:block;
	font-weight:normal;
	font-size:14px;
  color:rgba(255,255,255, .7);
	text-transform:uppercase;
}
.coming-soon-main-text-container h1{
  font-size: 60px;
  line-height: 65px;
  margin-top: 0;
  margin-bottom:45px;
  letter-spacing: 10px;
}
.coming-soon-main-text-container h3.font-white{
    font-weight:300;
}
@media only screen and (max-width : 1024px) {
  .coming-soon-main-text-container h1{
    font-weight: 600;
    font-size:40px;
    line-height:45px;
    margin-top:0;
  }
  .coming-soon-main-text-container h3{
    font-size:18px;
    line-height:25px;
  }
}

/* #ALERT BOXES 
================================================== */
.alert {
	border-radius: 0px;
}
.alert-icon {
	margin-right: 7px;
}

.iconed-box.error:before {
  background-position: -36px center;
}
.iconed-box.success:before {
  background-position: 0 center;
}
.iconed-box.info:before {
  background-position: -72px center;
}
.iconed-box.notice:before {
  background-position: -108px center;
}
.iconed-box:before {
	height: 100%;
	left: 10px;
	max-height: 80px;
	top: 0;
	width: 36px;
	background-attachment: scroll;
	background-color: transparent;
/*****	background-image: url(../images/box-icons.png);*/
	background-repeat: no-repeat;
	background-position: 0 0;
}
.iconed-box:before {
  content: "";
  position: absolute;
}
.styled-box.success {
  background: none repeat scroll 0 0 #EDFFD7;
  border-color: #80c080;
	color:#32a432;
}
.styled-box.error {
  background: none repeat scroll 0 0 #fdebeb;
  border-color: #ffa7a1;
	color:#e17585;
}
.styled-box.info {
  background: none repeat scroll 0 0 #E2F1FF;
  border-color: #73bfe4;
	color:#4ac2f3;
}
.styled-box.notice {
  background: none repeat scroll 0 0 #FFFFEE;
  border-color: #fbc778;
	color:#ff9900;
}
.styled-box {
	background: none repeat scroll 0 0 #FFFFFF;
	border-top-width: 1px;
	border-right-width: 1px;
	border-bottom-width: 1px;
	border-left-width: 3px;
	border-top-style: solid;
	border-right-style: solid;
	border-bottom-style: solid;
	border-left-style: solid;
	border-top-color: #D6D6D6;
	border-right-color: #D6D6D6;
	border-bottom-color: #D6D6D6;
	border-left-color: #D6D6D6; 
}
.styled-box {
	margin-bottom: 15px;
	position: relative;
	padding-top: 10px;
	padding-right: 20px;
	padding-bottom: 10px;
	padding-left: 20px;
}
.demo .styled-box {
	padding-top: 20px;
	padding-bottom: 20px;
}
.iconed-box {
  padding-left: 60px;
}
#contact-form .iconed-box {
  padding-left: 60px;
	width:220px;
	-moz-box-sizing: border-box; 
	-webkit-box-sizing: border-box;
	-o-box-sizing: border-box;
  box-sizing: border-box;
}

/* #SHORTCODES #DEMO
================================================== */
.demo-popup-gallery a img{
	margin-bottom: 3px;
}
.demo-grid-block {
	background-color: #EEE;
	line-height: 40px;
	min-height: 40px;
	text-align: center;
	display: block;
}
.demo-total-icons {
	font-family: 'Lato',Arial,Helvetica,sans-serif;
	font-weight: 300;
	font-size: 50px;
	line-height: 50px;
	padding-top:22px;
}
.fontawesome-icon-list i {
	font-size: 1.3em;
	margin-right: 5px;
	display: inline-block;
	width: 30px;
	text-align: center;
	position: relative;
	top: 2px;
}
.fontawesome-icon-list [class*="col-md-"] {
	margin-bottom: 12px;
}
.demo-elegant-font span.col-md-3.col-sm-4{
	display:block;
	margin-bottom: 12px;
}
.demo-elegant-font span.col-md-3.col-sm-4 span{
	font-size: 1.3em;
	margin-right: 5px;
}
.demo-bs-glyphicons .col-md-3.col-sm-4{
	margin-bottom: 12px;
}
.demo-bs-glyphicons span.glyphicon{
	font-size: 1.3em;
	margin-right: 5px;
}
.demo-anim-text {
	margin-top:15px;
} 

/* #BLOG
================================================== */
/*****	#SIDEBAR	*****/
#sidebar-stiky {
	margin-bottom:20px;
}
#sidebar-stiky.affix-top{
	position:relative;
}
#sidebar-stiky.affix-bottom{
	position:absolute; 
}
#sidebar-stiky.affix{
	position:fixed;
	top:70px;
}
.blog-categories {
	margin:0;
	padding:0;
	text-transform: uppercase;
}
.blog-categories li{
	list-style: none;
}
.blog-categories li a{
	color:#7e8082;
	height:38px;
	line-height:38px;
	display:block;
	padding:0;
	background: none !important;
}
.blog-categories li a:hover{
  color:#101010;
}
.blog-cat-icon {
	display:block;
	text-align:center;
	float:left;
	margin-right: 15px;
	height:38px;
	width:38px;
	font-size:17px;
	line-height:36px;
	color:#4b4e53;
	background-color:#dfdfdf;
	transition: all .3s ease 0s;
}
.blog-categories li a:hover .blog-cat-icon{
	background-color:#ffea00;
	height:48px;
	width:48px;
	line-height:46px;
	transition: all .3s ease 0s;
}
.blog-categories li a:hover {
	height:48px;
	line-height:48px;
	transition: all .3s ease 0s;
}
.blog-categories li.current .blog-cat-icon{
	background-color:#ffea00;
	height:48px;
	width:48px;
	line-height:46px;
	transition: all .3s ease 0s;
}
.blog-categories li.current a {
	height:48px;
	line-height:48px;
	transition: all .3s ease 0s;
}
.ml-min-10 {
	margin-left:-10px;
}

/*****	#PAGINATION #NEW	*****/
.blog-pag .pagination .active a, .blog-pag  .pagination>.active>a:focus, .blog-pag  .pagination>.active>a:hover, .blog-pag  .pagination>.active>span, .blog-pag  .pagination>.active>span:focus, .blog-pag  .pagination>.active>span:hover {
  z-index: 2;
  color: #fff;
  cursor: default;
  background-color: #4b4e53;
  border-color: #337ab7;
}
.blog-pag .pagination>li>a, .blog-pag .pagination>li>span {
  color: #7e8082;
  margin-right: 7px;
  padding: 8px 14px;
  border:none;
  border-radius: 0;
}
.blog-pag .pagination li i {
  font-size:16px;
}

/* #COMMENTS 
================================================== */
.post-author .panel {
	border-radius:0;
}
.post-author .panel-heading {
	border-radius:0;
}
.post-author .panel-heading {
	color: #7e8082;
}
.comment-list {
	margin:0;
	padding:0;
	list-style: none;
}
.post-author-container {
	position: relative;
  padding:25px;
	margin-bottom: 30px;
	background: #eee;
	min-height:130px;
}
.post-author-container p {
  font-size:13px;
  line-height:22px;
}
.post-author-avatar {
  margin-right:20px;
  float:left;
}
.post-author-container .comment-author {
  margin-top:7px;
  margin-bottom:7px;
  font-size:16px;
}
.comment-author .comment-date {
	font-size: 12px;
	margin-bottom:10px;
}

/* #COMMENTS #NEW 
================================================== */
.blog-page-title {
  font-size:18px;
  text-transform:uppercase;
  font-weight:600;
}
.blog-page-title small{
  font-size:12px;
}
.comment-item{
	padding-top: 30px !important;
	border-top: 1px dotted #e9e9e9;
  font-size: 14px;
  overflow: hidden;
}
.comment-item:first-child{
	padding-top: 0;
	border-top: none;
}
#comments .media-body{
  overflow: visible;
} 
.comment-reply {
  margin-left:-25px;
}
.comment-avatar{
	width: 70px;
  margin-right: 15px;
	-webkit-border-radius: 50%;
	-moz-border-radius: 50%;
	border-radius: 50%;
}
.comment-item-title{
	margin: 0 0 5px;
}
.comment-author {
  text-transform: uppercase;
}
.comment-date {
  font-size:12px;
}
.comment-author{
  display: inline-block;
  margin-right: 20px;
  font-weight: 700; 
}
#comments .media {
  margin-top:0;
}
@media only screen and (max-width: 1024px) {
  .comment-avatar {
    width:50px;
    margin-right: 10px;
  }
  .comment-reply {
    margin-left:-35px;
  } 
}
@media only screen and (max-width: 480px) {
  .comment-avatar {
    width:40px;
    margin-right: 10px;
    margin-top: 7px;
  }
  .comment-reply {
    margin-left:-45px;
  }
  .post-author-avatar {
    margin-bottom: 10px;
    float: none;
  }
}

/* #WORK NAV #NEW
================================================== */
.work-navigation{
  padding:18px 0;
	width: 100%;
	background: #fff;	
	font-size: 12px;
	text-transform: uppercase;
	letter-spacing: 2px;	
  z-index: 99;
}
.work-navigation .icon{
	font-size: 24px;
	vertical-align: middle;
}
.work-navigation .work-all .icon{
	font-size: 20px;
  line-height: 19px;
}
.work-navigation .fa-times{
	font-size: 16px;
	margin-top: -2px;
}
.work-navigation a{
	display: block;
	float: left;
	width: 33.33333%;
	overflow: hidden;
	vertical-align: middle;
	text-decoration: none;
	white-space: nowrap;
	cursor: default;
}
.work-navigation a span{
	display: inline-block;
}
.work-prev{
	text-align: left;
}
.work-all{
	text-align: center;
}
.work-next{
	text-align: right;
}

/* #RELATED POSTS #NEW
================================================== */
.related-posts .post-prev-title h3 {
  font-size:14px;
  line-height:25px;
}
.related-posts  .post-prev-img {
  margin-bottom:15px;
}

/* #PROGRESS BAR #NEW
=================================================*/
.over-hide {
  overflow:hidden;
}
.skill-bar {
	height: 36px;
	position: relative;
	background: #f6f6f6;
	margin: 0 0 5px 0;
}
.skill-bar-content[data-percentage] { text-indent: -9999px; }
.skill-bar-content {
	background: #101010;
	height: 36px;
	width: 0%;
}
.skill-bar .skill-title {
	color: #4b4e53;
	top: 10px;
	left: 15px;
	position: absolute;
}
.skill-bar .percentage {
	color: #666;
	position: absolute;
	top: 9px;
	left: 90%;
	background-color:#0C0;
}

/***** #SKILL BAR 2 #NEW ******/
.skill-bar2 .skill-bar .skill-title {
  color: #fff;
  top: 7px;
  left: 15px;
}
.skill-bar2 .skill-bar-content {
  background: #4b4e53;
  height: 40px;
}
.skill-bar2 .skill-bar {
  height: 40px;
  margin: 0 0 10px 0;
}

/***** #SKILL BAR ANIMATED #NEW ******/
.skillbar {
	position:relative;
	display:block;
	margin-top:25px;
	margin-bottom:55px;
	width:100%;
	background:#eee;
	height:2px;
	-webkit-transition:.4s linear;
	-moz-transition:.4s linear;
	-ms-transition:.4s linear;
	-o-transition:.4s linear;
	transition:.4s linear;
	-webkit-transition-property:width, background-color;
	-moz-transition-property:width, background-color;
	-ms-transition-property:width, background-color;
	-o-transition-property:width, background-color;
	transition-property:width, background-color;
}
.skillbar-title {
	position:absolute;
	bottom:0;
	left:0;
	color:#4b4e53;
}
.skillbar-title span {
	display:block;
	height:40px;
	line-height:40px;
}
.skillbar-bar {
	height:2px;
	width:0px;
	background:#4b4e53; 
}
.skill-bar-percent {
	position:absolute;
	right:0px;
	bottom:0;
	height:40px;
	line-height:40px;
	color:rgba(0, 0, 0, .6); 
}
.audio-box audio{
	width:100%;
}

/* #MAINTENANCE 
================================================== */
.maintenance-icon-container {
	margin-top:10px;
	margin-bottom:50px;
}
.maintenance-icon {
	font-size:175px;
}
.maintenance-text-container h1 {
	margin-top:0;
	margin-bottom:10px;
	font-size:70px;
	line-height:70px;
	font-weight:600;
}
.maintenance-text-container h2 {
	margin-top:0;
}
.maintenance-container {
	padding-top:250px;
	padding-bottom:150px;
}
.under-constr-bg {
/*****  background: url(../images/under-const.jpg) 50% 50%;*/
  background-size: cover !important;
  
  width: 100%;
  display: block;	
  position: relative;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
     
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  overflow: hidden;
  background-repeat: no-repeat;
  background-position: center center;
}

@media only screen and (max-width: 767px) {
  .maintenance-container {
    padding-top:150px;
    padding-bottom:100px;
  }
  .coming-soon-main-text-container h3 {
    font-size: 16px;
    line-height: 25px;
  }
  .text-left-767 {
    text-align:left;
  }
  .text-center-767 {
    text-align:center;
  }
  .w-100-767 {
    width:100%;
  }
}

/* #PORTFOLIO
============================================*/
ul#portfolio {
	margin:0;
	padding:0;
	list-style:none;
	transition:none !important;
}

/*****	#START: RECOMMENDED ISOTOPE STYLES	*****/

/**** Isotope Filtering ****/
.isotope-item {
  z-index: 2;
}
.isotope-hidden.isotope-item {
  pointer-events: none;
  z-index: 1;
}

/**** Isotope CSS3 transitions ****/
.isotope,
.isotope .isotope-item {
  -webkit-transition-duration: .8s;
     -moz-transition-duration: .8s;
      -ms-transition-duration: .8s;
       -o-transition-duration: .8s;
          transition-duration: .8s;
}
.isotope {
  -webkit-transition-property: height, width;
     -moz-transition-property: height, width;
      -ms-transition-property: height, width;
       -o-transition-property: height, width;
          transition-property: height, width;
}
.isotope .isotope-item {
  -webkit-transition-property: -webkit-transform, opacity;
     -moz-transition-property:    -moz-transform, opacity;
      -ms-transition-property:     -ms-transform, opacity;
       -o-transition-property:      -o-transform, opacity;
          transition-property:         transform, opacity;
}

/**** disabling Isotope CSS3 transitions ****/
.isotope.no-transition,
.isotope.no-transition .isotope-item,
.isotope .isotope-item.no-transition {
  -webkit-transition-duration: 0s;
     -moz-transition-duration: 0s;
      -ms-transition-duration: 0s;
       -o-transition-duration: 0s;
          transition-duration: 0s;
}

/* #ELEMENTS
=======================================*/

/*****	#DROPCAPS	*****/
.dropcap1 {
	background: none repeat scroll 0 0 #EAEAEA;
  color: #777;
  display: inline-block;
  float: left;
  font-size: 50px;
  font-weight: normal;
  line-height: 75px;
  margin: 6px 20px 0 0;
  padding-bottom: 2px;
  text-align: center;
  width: 56px;
}
.dropcap2 {
	color: #fff;
	display: inline-block;
	float: left;
	font-size: 40px;
	height: 75px;
	line-height: 70px;
	text-align: center;
	width: 75px;
	border-radius: 50%;
	padding-left: 1px;
	background-attachment: scroll;
	background-color: #8f8f8f;
	background-image: none;
	background-repeat: repeat;
	background-position: 0 0;
	margin:8px 20px 0 0;
}
.dropcap3 {
  color: #777;
  display: inline-block;
  float: left;
  font-size: 60px;
  font-weight: lighter;
  line-height: 60px;
  margin: 0 20px 0 0;
  padding-bottom: 2px;
  text-align: center;
}
.dropcap4 {
	background: none repeat scroll 0 0 #ffea00;
  color: #fff;
  display: inline-block;
  float: left;
  font-size: 50px;
  font-weight: normal;
  line-height: 75px;
  margin: 6px 20px 0 0;
  padding-bottom: 2px;
  text-align: center;
  width: 56px;
}
/*****	#STYLED LIST	*****/
.styled-list {
  list-style: none outside none;
  margin: 0;
	padding:0;
}
.styled-list.style-1 > li:before {
  background-position: 0 -30px;
}
.styled-list.style-2 > li:before {
  background-position: 0 -60px;
}
.styled-list.style-3 > li:before {
  background-position: 0 -14px;
}
.styled-list.style-4 > li:before {
  background-position: 0 0;
}
.styled-list.style-5 > li:before {
  background-position: 0 -45px;
}
.styled-list.style-6 > li:before {
  background-position: 0 -75px;
}
.styled-list > li:before {
	height: 15px;
	left: 0;
	top: 1.5px;
	width: 15px;
	background-attachment: scroll;
	background-color: transparent;
/*****	background-image: url(../images/list-icons.png);*/
	background-repeat: no-repeat;
	background-position: 0 0;
}
.styled-list > li:before {
  content: "";
  position: absolute;
}
.styled-list > li {
  padding-left: 19px;
  position: relative;
}
.demo-list-styles ul {
	margin:0;
	padding:0;
	list-style:none;
	margin-bottom:10px;
}

/* #FOOTER
================================================== */

/*****	#CONTACT FOOTER	*****/
.contact-text-container {
	padding-left:50px;
	padding-top:12px;
	min-height:46px;
	opacity:.8;
}
.right-1024 {
	float:right;	
}
.right {
	float:right;	
}
.left {
	float:left;
}
.right-text {
	text-align:right;	
}
.center-text {
	text-align:center;	
}

/*	#TYPOGRAPHY
===============================================================*/
/***** #HEADINGS *****/
h1, h2, h3{
	font-family: 'Lato',Arial,Helvetica,sans-serif;
	font-weight:normal;
	color: #4b4e53;
	margin: 0;
}
h1, h2, h3, h4, h5, h6 {
	color: #4b4e53;
	margin: 0;
}
h1 {
	font-size: 32px;
	line-height: 45px;
	margin: 40px 0 32px 0;
}
h2 {
	font-size: 28px;
	line-height: 32px;
	margin: 27px 0 18px 0;
}
h3 {
	font-size: 24px;
	letter-spacing: normal;
	line-height: 33px;
	margin: 26px 0 16px 0;
}
h4 {
	font-size: 18px;
	letter-spacing: normal;
	line-height: 25px;
	margin: 21px 0 19px 0;
}
h5 {
	font-size: 14px;
	letter-spacing: normal;
	line-height: 25px;
	margin: 18px 0 13px 0;
}
h6 {
	font-size: 12px;
	letter-spacing: normal;
	line-height: 18px;
	margin: 18px 0 13px 0;
}
.heading-line {
	position:relative;
}
.heading-line h1, .heading-line h2, .heading-line h3, .heading-line h4, .heading-line h5, .heading-line h6{
	position: relative;
	display: inline-block;
	background-color: #FFF;
	padding-right: 15px;
}
.heading-line:before {
	content: '';
	position: absolute;
	width: 100%;
	height: 0;
	border-top: 2px solid #eee;
	left: auto;
	top: 58%;
	right: 0;
}
.h2-line:before {
	top: 59%;
}
.h3-line:before {
	top: 61%;
}
.h4-line:before {
	top: 51.5%;
}
.h5-line:before, .h6-line:before {
	top: 55%;
}
.heading-underline h1, .heading-underline h2, .heading-underline h3, .heading-underline h4, .heading-underline h5, .heading-underline h6{
	border-bottom: 1px solid #eee;
	padding-bottom:20px;
}
.heading-underline h2{
	padding-bottom:20px;
}
.heading-underline h3{
	padding-bottom:16px;
}
.heading-underline h4{
	padding-bottom:16px;
}
.heading-underline h5, .heading-underline h6 {
	padding-bottom:16px;
}

/*****	#DIVIDERS	*****/
.divider {
	position: relative;
	overflow: hidden;
	margin: 35px 0;
	color: #eee;
	width: 100%;
}
.divider i {
	position: relative;
	line-height: 1;
	font-size: 14px!important;
	text-align: center;
}
.divider:after {
	content: '';
	position: absolute;
	width: 100%;
	top: 12px;
	left: 25px;
	height: 0;
	border-top: 2px solid #EEE;
}
.divider.divider-center {
	text-align: center;
}
.divider.divider-center  i	{
	border-left: 10px solid #fff;
	border-right: 10px solid #fff;
	z-index: 1;
}
.divider.divider-center:after {
	left: 0;
}
.divider-line {
	height:2px;
	width:100%;
	background: #eee;
}
.divider-dashed {
	height:0px;
	width:100%;
	margin:20px 0 20px 0;
	border-top: 2px dashed #eee;
}
hr {
	border-top: 1px solid #eee;
	margin: 22px 0 22px 0;
}
hr.gradient {
	background-image: -webkit-linear-gradient(left, transparent, rgba(0, 0, 0, .2), transparent);
	background-image: linear-gradient(to right, transparent, rgba(0, 0, 0, .2), transparent);
	border: 0;
	height: 1px;
	margin: 22px 0 22px 0;
}
hr.short {
	margin: 11px 0 11px 0;
}
hr.tall {
	margin: 44px 0 44px 0;
}
hr.taller {
	margin: 66px 0 66px 0;
}
hr.light {
	background-image: -webkit-linear-gradient(left, transparent, rgba(255, 255, 255, .2), transparent);
	background-image: linear-gradient(to right, transparent, rgba(255, 255, 255, .2), transparent);
}
hr.invisible {
	background: none;
}
.label {
	font-weight: normal;
}

/***** #HIGHLIGHTS *****/
.highlight-text, .highlight-text-grey {
	background-color: #ffea00;
  color:#4b4e53;
	padding: 0 3px;
}
.highlight-text-grey {
	background-color: #C0C0C0;
	color: #fff;
}
/***** #ICONS LIST *****/
.icon-list {
	padding-left: 0;
	margin-left: 24px;
	list-style-type: none;
}
.icon-list li {
	position: relative;
}
.icon-list>li [class*=" fa-"]{
	position: absolute;
	left: -21px;
	text-align: center;
	top: 4px;
	width: 14px;
}
/***** #ICONS LIST ELEGANT ICONS *****/
.icon-list.elegant-font {
	margin-left: 0;
	margin-bottom: 0;
}
.icon-li {
	margin-right:10px;
}
.glyphs.css-mapping {
  margin: 0 ;
  padding: 0;
  color: rgba(0,0,0,0.5);
}
.glyphs.css-mapping li {
  margin: 0 30px 20px 0;
  padding: 0;
  display: inline-block;
  overflow: hidden;
}
.glyphs.css-mapping .icon {
  margin: 0;
  margin-right: 10px;
  padding: 13px;
  height: 50px;
  width: 50px;
  color: #162a36 !important;
  overflow: hidden;
  float: left;
  font-size: 24px;
}
.glyphs.css-mapping input {
  margin: 0;
  margin-top: 5px;
  padding: 8px;
  line-height: 16px;
  font-size: 16px;
  display: block;
  width: 150px;
  height: 40px;
  border: 1px solid #d8e0e5;
  -webkit-border-radius: 5px;
  border-radius: 5px;
  background: #fff;
  outline: 0;
  float: right;
}

/***** #CODE *****/
pre {
  border-radius:0;
  margin: 0 0 20px;
  padding: 0;
  background-color: transparent;
  border: 0;
}

/***** #MODAL TITLES *****/
.modal-header h3 {
	color: #333;
	text-transform: none;
}

/***** #RESPONSIVE HEADINGS*****/

@media (max-width: 767px) {
  .pt-b-0-767{
    padding-top:0 !important;
    padding-bottom:0 !important;
  }
	h1.big {
		font-size: 3.2em;
		line-height: 42px;
	}
  /*****  #CONTACT INFO SECTION NEW *****/
  .cis-icon {
    top: 25px;
    font-size: 25px;
  }
  .cis-cont {
    padding-left: 50px;
  }
  /*****  #CONTACT FORM NEW *****/
  .contact-form-cont {
    padding:80px 20px 80px 20px;
  }
}

/*****	#BUTTONS	*****/
button {
	border:none;
	cursor:pointer;
  text-decoration: none;
  transition: all .3s ease 0s;
}
.button {
	background-color:#ffea00;
	color:#4b4e53;
	border:none;
	border-radius:2px;
	transition: all .3s ease 0s;
/****	add in anim	*******/
	outline: none;
	position: relative;	
	
}
.button:hover {
	background-color:#101010;
	color:#fff;
	transition: all .3s ease 0s;
}
.button.medium  {
	padding: 10px 37px 10px 37px;
	display:inline-block;
}
.button.medium-compare  {
	font-size:14px;
	line-height:36px;
	padding:0px 16px 0px 16px;
	display:inline-block;
	margin-top: 10px;
	margin-bottom: 10px;
	width:90%;
}
.button.medium-border  {
	font-size:14px;
	line-height:36px;
	height:36px;
	padding:0px 16px 0px 16px;
	display:inline-block;
	border-bottom: 1px solid #FFFFFF !important;
  border-right: 1px solid #FFFFFF !important;
  box-shadow: 2px 2px 0 rgba(0, 0, 0, .1) !important;
}
.button.large {
	font-size:16px;
	line-height:20px;
	padding:20px 40px;
	display:inline-block;
}
.button.large-border {
	font-size:20px;
	line-height:20px;
	padding:15px 39px 15px 39px;
	display:inline-block;
	border-bottom: 1px solid #FFFFFF !important;
  border-right: 1px solid #FFFFFF !important;
  box-shadow: 2px 2px 0 rgba(0, 0, 0, .1) !important;
}
.button.small {
	font-size:11px;
	line-height:16px;
	padding: 10px 15px 10px 15px;
	display:inline-block;
}
.button.small-border {
	font-size:12px;
	line-height:16px;
	padding: 6px 10px;
	display:inline-block;
	border-bottom: 1px solid #FFFFFF !important;
  border-right: 1px solid #FFFFFF !important;
  box-shadow: 2px 2px 0 rgba(0, 0, 0, .1) !important;
}
.button-icon-right {
	margin-left:6px;
}
.button-icon-left {
	margin-right:6px;
}

/*****	#ANIMATION BUTTONS	*****/
.btn-4 {
	overflow: hidden;
	padding-right: 40px !important;
	padding-left: 40px !important;
}
.btn-4 .button-icon-anim  {
	position: absolute;
	font-size: 125%;
	top: 50%;
	-webkit-transform: translateY(-50%);
	-moz-transform: translateY(-50%);
	-ms-transform: translateY(-50%);
	-o-transform: translateY(-50%);
	transform: translateY(-50%);
	transition: left .3s ease;
}

/* Button 4aa */
.btn-4aa .button-icon-anim  {
	left: 130%;
}
.btn-4aa:hover .button-icon-anim  {
	left: 76%;
}

/* Button 4aa-inv */
.btn-4aa-inv .button-icon-anim  {
	left: 76%;
}
.btn-4aa-inv:hover .button-icon-anim  {
	left: 102%;
}

/* Button 4cc */
.btn-4cc .button-icon-anim  {
	left: 70%;
	opacity: 0;
}
.btn-4cc:hover .button-icon-anim  {
	left: 76%;
	opacity: 1;
}

/* Button 4cc-inv */
.btn-4cc-inv .button-icon-anim {
	left: 76%;
	opacity: 1;
}
.btn-4cc-inv:hover .button-icon-anim {
	left: 70%;
	opacity: 0;
}

/* Button 5 */
.btn-5 {
	overflow: hidden;
	-webkit-backface-visibility: hidden;
	-moz-backface-visibility: hidden;
	backface-visibility: hidden;
	padding-right: 45px;
	padding-left: 45px;	
}
.btn-5 .button-text-anim {
	display: inline-block;
	width: 100%;
	height: 100%;
	-webkit-backface-visibility: hidden;
	-moz-backface-visibility: hidden;
	transition: all ease .3s;
	backface-visibility: hidden;
}
.btn-5 .button-icon-anim{
	position: absolute;
	top: 50%;
	left: 50%;
	-webkit-transform: translate(-50%, -50%);
	-moz-transform: translate(-50%, -50%);
	-ms-transform: translate(-50%, -50%);
	-o-transform: translate(-50%, -50%);
	transform: translate(-50%, -50%);
	font-size: 180%;
	transition: all ease .3s;
}

/* Button 5aa */
.btn-5aa:hover .button-text-anim {
	-webkit-transform: translateY(300%);
	-moz-transform: translateY(300%);
	-ms-transform: translateY(300%);
	transform: translateY(300%);
}
.btn-5aa .button-icon-anim {
	top: -100%;
}
.btn-5aa:hover .button-icon-anim {
	top: 50%;
	left: 50%;
}

/* Button 5bb */
.btn-5bb:hover .button-text-anim  {
	-webkit-transform: translateX(200%);
	-moz-transform: translateX(200%);
	-ms-transform: translateX(200%);
	transform: translateX(200%);
}
.btn-5bb .button-icon-anim {
	left: -100%;
	/* top: 0; */
}
.btn-5bb:hover .button-icon-anim  {
	left: 50%;
}

/*****	#THIN BUTTONS	*****/
.button.thin, .button.small.thin, .button.medium.thin, .button.large.thin   {
	position:relative;
	display:inline-block;
	padding: 13px 17px 13px 17px;
	background-color:transparent;
	color:#7a7b80;
	border: 2px solid #c5c2c2;	
	transition: all .3s ease 0s;
}
.button.small.thin {
	padding: 10px 15px 10px 15px !important;
}
.button.medium.thin {
	padding: 8px 35px 8px 35px !important;
}
.button.large.thin {
	padding: 15px 20px 15px 20px !important;
	line-height:22px;
}
.button.thin:hover {
	background-color:#d7d9dc;
	color:#4b4e53;
	border: 2px solid #d7d9dc;
	transition: all .3s ease 0s;
}
.button.thin.hover-dark:hover {
	background-color:#4b4e53 !important;
	border: 2px solid #4b4e53 !important;
	color:#fff !important;
}
.button.thin-bg-dark {
	position:relative;
	display:inline-block;
	padding: 13px 17px 13px 17px !important;
	background-color:transparent !important;
	color:#4b4e53 !important;
	border: 2px solid #727578 !important;	
	transition: all .3s ease 0s;
}
.button.thin-bg-dark:hover {
	background-color:#ffea00 !important;
	color:#4b4e53 !important;
	border: 2px solid #ffea00 !important;
	transition: all .3s ease 0s;
}

/*****  #BUTTONS THIN COLORS  *****/
.button.thin.yellow{
	border-color:#ffea00;
	color:#ffea00;
}
.button.thin.yellow:hover {
	background-color:#ffea00;
	color:#4b4e53;
}
.button.thin.blue {
	border-color:#37a2d6;
	color:#37a2d6;
}
.button.thin.blue:hover {
	background-color:#37a2d6;
	color:#fff;
}
.button.thin.cyan {
	border-color:#4cc1d3;
	color:#4cc1d3;
}
.button.thin.cyan:hover {
	background-color:#4cc1d3;
	color:#fff;
}
.button.thin.teal {
	border-color:#1bbc9b;
	color:#1bbc9b;
}
.button.thin.teal:hover {
	background-color:#1bbc9b;
	color:#fff;
}
.button.thin.gray {
	border-color:#535961;
	color:#535961;
}
.button.thin.gray:hover {
	background-color:#535961;
	color:#fff;
}
.button.thin.white {
	border-color:#fff;
	color:#fff;
}
.button.thin.white:hover {
	background-color:#fff;
	color:#4b4e53;
}
.button.thin.gray-light {
	border-color:#d7d9dc;
	color:#d7d9dc;
}
.button.thin.gray-light:hover {
	background-color:#d7d9dc;
	color:#fff;
}
.button.thin.deeporange {
	border-color:#ff5722;
	color:#ff5722;
}
.button.thin.deeporange:hover {
	background-color:#ff5722;
	color:#fff;
}
.button.thin.green {
	border-color:#259b24;
	color:#259b24;
}
.button.thin.green:hover {
	background-color:#259b24;
	color:#fff;
}
.button.thin.lime {
	border-color:#cddc39;
	color:#cddc39;
}
.button.thin.lime:hover {
	background-color:#cddc39;
	color:#fff;
}

/*****	#HOVER THIN BUTTONS		*****/
.button.hover-thin  {
	border: 2px solid #ffea00;
	transition: all .3s ease 0s;
}
.button.hover-thin:hover  {
	background:none;
	border: 2px solid #dfdfdf;
	color:#4b4e53;
}
.button.medium.hover-thin  {
	padding: 10px 37px;
}
.button.large.hover-thin {
	padding:18px 23px 18px 23px;
}
.button.small.hover-thin {
	padding: 8px 13px 8px 13px;
}

/*****	#HOVER THIN BUTTONS COLORS		*****/
.button.yellow.hover-thin {
	border-color: #ffea00;
}
.button.yellow.hover-thin:hover {
	background:none;
	border: 2px solid #ffea00;
	color:#ffea00;
}
.button.blue.hover-thin {
	border-color: #37a2d6;
}
.button.blue.hover-thin:hover {
	background:none;
	border: 2px solid #37a2d6;
	color:#37a2d6;
}
.button.cyan.hover-thin {
	border-color: #4cc1d3;
}
.button.cyan.hover-thin:hover {
	background:none;
	border: 2px solid #4cc1d3;
	color:#4cc1d3;
}
.button.teal.hover-thin {
	border-color: #1bbc9b;
}
.button.teal.hover-thin:hover {
	background:none;
	border: 2px solid #1bbc9b;
	color:#1bbc9b;
}
.button.gray.hover-thin {
	border-color: #535961;
}
.button.gray.hover-thin:hover {
	background:none;
	border: 2px solid #535961;
	color:#535961;
}
.button.gray-light.hover-thin {
	border-color: #d7d9dc;
}
.button.gray-light.hover-thin:hover {
	background:none;
	border: 2px solid #d7d9dc;
	color:#4b4e53;
}
.button.deeporange.hover-thin {
	border-color: #ff5722;
}
.button.deeporange.hover-thin:hover {
	background:none;
	border: 2px solid #ff5722;
	color:#ff5722;
}
.button.green.hover-thin {
	border-color: #259b24;
}
.button.green.hover-thin:hover {
	background:none;
	border: 2px solid #259b24;
	color:#259b24;
}
.button.lime.hover-thin {
	border-color: #cddc39;
}
.button.lime.hover-thin:hover {
	background:none;
	border: 2px solid #cddc39;
	color:#cddc39;
}
/*****	#THIN-INV BUTTON	*****/
.button.thin-inv, .button.thin-inv-bg-dark {
	display:inline-block;
	background-color:#ffea00 !important;
	color:#fff !important;
	border: 2px solid #ffea00 !important;	

	transition: all .3s ease 0s;
}
.button.thin-inv:hover {
	background-color:transparent !important;
	border: 2px solid #dfdfdf !important;
	color:#4b4e53 !important;
	
	transition: all .3s ease 0s;
}
.button.thin-inv-bg-dark:hover {
	background-color:transparent !important;
	border: 2px solid #dfdfdf !important;
	color:#fff;
	
	transition: all .3s ease 0s;
}
.button.color-black {
	color:#4b4e53 !important;
}
/*****  #BUTTONS COLORS  *****/
.button.yellow {
	background-color:#ffea00;
	color:#4b4e53;
}
.button.yellow:hover {
	background-color:#4b4e53;
	color:#fff;
}
.button.blue {
	background-color:#37a2d6;
	color:#fff;
}
.button.blue:hover {
	background-color:#4b4e53;
}
.button.cyan {
	background-color:#4cc1d3;
	color:#fff;
}
.button.cyan:hover {
	background-color:#4b4e53;
}
.button.teal {
	background-color:#1bbc9b;
	color:#fff;
}
.button.teal:hover {
	background-color:#4b4e53;
}
.button.gray {
	color:#fff;
	background-color:#535961;
}
.button.gray:hover {
	background-color:#101010;
}
.button.gray-light {
	color: #4b4e53;
	background-color: #d7d9dc;
}
.button.gray-light:hover {
	background-color:#4b4e53;
  color:#fff;
	cursor:pointer;
}
.button.deeporange {
	color:#fff;
	background-color:#ff5722;
}
.button.deeporange:hover {
	background-color:#4b4e53;
	cursor:pointer;
}
.button.green {
	color:#fff;
	background-color:#259b24;
}
.button.green:hover {
	background-color:#4b4e53;
	cursor:pointer;
}
.button.lime {
	color:#fff;
	background-color:#cddc39;
}
.button.lime:hover {
	background-color:#4b4e53;
	cursor:pointer;
}
/****************************************/
.demo-buttons a{
	margin-right:6px;
	margin-bottom:10px;
}
.button i {
  margin: 0 5px 0 -1px;
}
.button.medium i, .button.medium-border i {
  margin: 0 6px 0 -4px;
}
.button.large i, .button.large-border i {
  margin: 0 10px 0 -15px;
}
.button.black {
	font-family: 'Open Sans', sans-serif;
	color:#ffffff;
	font-style:normal;
	background-color:#4d4d4d;
}
.button.black:hover {
	background-color:#575757;
}
a {
  color: #4b4e53;
	outline: none !important;
  text-decoration: none;
  transition: all .3s ease 0s;
}
a:hover {
  color: #97999c;
  text-decoration: none;
  transition: all .3s ease 0s;
	cursor:pointer;
}
.a-dark{
	color: #101010;
}
.a-dark:hover{
	color: #97999c;
}
.a-white{
	color: #fff;
}
.a-white:hover{
	color: #a5a5a5;
}
.a-inv{
	color: #7e8082;
}
.a-inv:hover{
	color: #101010;
}
.a-text, .a-text-cont a{
	color: #7e8082;
}
.a-text:hover, .a-text-cont a:hover{
	color: #101010;
}
.a-blue{
	color: #37a2d6;
}
.a-blue:hover{
	color: #4b4e53;
}
a:focus {
	text-decoration:none !important;
}
a:hover, a:focus {
  color: #97999c;
  text-decoration: none !important;
  transition: all .3s ease 0s;
	cursor:pointer;
}
.button.get-theme {
	font-size:14px;
	padding:10px 17px 10px 40px;
	background-color:#ffea00;
/*****	background: url(../images/icon-get-theme.png) no-repeat scroll 14px center, #ffea00;*/
	display:inline-block;
  border-bottom: 1px solid #FFFFFF;
  border-right: 1px solid #FFFFFF;
	box-shadow: 2px 2px 0 rgba(0, 0, 0, .1);
}
.button.get-theme:hover {
	background-color:#5a5a5a;
}
.button-full-center {
	font-style: normal;
    width: 100%;
	text-align: center;
	font-weight: 300;
}
.container-buttons {
	margin:0;
	padding:0;
	list-style: none;
}
.container-buttons li{
	margin-bottom:10px;
}
.black-text {
	color:#4B4E53 !important;
	border-color:#4B4E53 !important;
}

/* #MARGINS #PADDINGS
================================================== */
.m-0 {margin:0 !important;}
.m-10 {margin:2px;}

.mt-min-30 {margin-top:-30px;}
.mt-min-35 {margin-top:-35px;}
.mt-min-180 {margin-top:-180px;}
.mt-min-120{margin-top:-120px;}
.mt-min-210 {margin-top:-210px;}
.mt-min-500 {margin-top:-500px;}
.mt-0 {margin-top:0 !important;}
.mt-5 {	margin-top:5px !important;}
.mt-10 {margin-top:10px !important;}
.mt-20 {margin-top:20px;}
.mt-25 {margin-top:25px !important;}
.mt-30 {margin-top:30px !important;}
.mt-40 {margin-top:40px !important;}
.mt-50 {margin-top:50px !important;}
.mt-55 {margin-top:55px}
.mt-60 {margin-top:60px !important;}
.mt-70 {margin-top:70px }
.mt-80 {margin-top:80px;}
.mt-90 {margin-top:90px;}
.mt-100 {margin-top:100px;}
.mt-110 {margin-top:110px;}
.mt-150 {margin-top:150px;}
.mt-170 {margin-top:170px;}

.mr-0 {	margin-right:0 !important;}
.mr-5 {margin-right:5px;}
.mr-7 {margin-right:7px;}
.mr-10 {margin-right:10px;}
.mr-15 {margin-right:15px;}
.mr-20 {margin-right:20px;}

.mb-0 {margin-bottom:0 !important;}
.mb-5 {margin-bottom:5px !important;}
.mb-10 {margin-bottom:10px !important;}
.mb-15 {margin-bottom:15px !important;}
.mb-20 {margin-bottom:20px !important;}
.mb-23 {margin-bottom:23px}
.mb-40 {margin-bottom:40px !important;}
.mb-45 {margin-bottom:45px !important;}
.mb-25 {margin-bottom:25px !important;}
.mb-30 {margin-bottom:30px !important;}
.mb-35 {margin-bottom:35px !important;}
.mb-50 {margin-bottom:50px }
.mb-60 {margin-bottom:60px !important;}
.mb-70 {margin-bottom:70px !important;}
.mb-75 {margin-bottom:75px;}
.mb-80 {margin-bottom:80px !important;}
.mb-90 {margin-bottom:90px;}
.mb-100 {margin-bottom:100px;}
.mb-110 {margin-bottom:110px;}
.mb-140 {margin-bottom:140px;}
.mb-160 {margin-bottom:160px;}
.mb-170 {margin-bottom:170px;}
.mb-180 {margin-bottom:180px;}
.mb-210 {margin-bottom:210px;}

.ml-0 {margin-left:0px !important;}
.ml-10 {margin-left:10px;}
.ml-20 {margin-left:20px;}
.ml-30 {margin-left:30px !important;}
.ml-40 {margin-left:40px !important;}

.mlr-1 {margin-left: 1px; margin-right: 1px;}
.mlr-10 {margin-left: 10px; margin-right: 10px;}
.mlr-30 {margin-left: 30px; margin-right: 30px;}
.mlr-min-30 {margin-left: -30px !important; margin-right: -30px !important;}

.p-0 {padding:0 !important;}
.p-10 {padding:10px !important;}
.p-15 {padding:15px !important;}
.p-20 {padding:20px;}
.p-25 {padding:25px;}
.p-40 {padding:40px;}

.pt-0 {padding-top:0px;}
.pt-10 {padding-top:10px;}
.pt-20 {padding-top:20px;}
.pt-30 {padding-top:30px;}
.pt-36 {padding-top:36px;}
.pt-40 {padding-top:40px;}
.pt-50 {padding-top:50px;}
.pt-55 {padding-top:55px;}
.pt-60 {padding-top:60px;}
.pt-65 {padding-top:65px;}
.pt-70{padding-top:70px;}
.pt-75{padding-top:75px;}
.pt-80{padding-top:80px;}
.pt-95 {padding-top:95px;}
.pt-100 {padding-top:100px;}
.pt-130 {padding-top:130px;}
.pt-140 {padding-top:140px;}
.pt-250 {padding-top:250px;}

.pr-0 {	padding-right:0 !important;}
.pr-15{padding-right:15px;}
.pr-75{padding-right:75px;}

.pb-0 {	padding-bottom:0px !important;}
.pb-10 {padding-bottom:10px !important;}
.pb-20 {padding-bottom:20px}
.pb-30 {padding-bottom:30px !important;}
.pb-40 {padding-bottom:40px;}
.pb-45 {padding-bottom:45px;}
.pb-50 {padding-bottom:50px;}
.pb-55 {padding-bottom:55px;}
.pb-60 {padding-bottom:60px;}
.pb-65 {padding-bottom:65px;}
.pb-70 {padding-bottom:70px;}
.pb-80 {padding-bottom:80px;}
.pb-130 {padding-bottom:130px;}
.pb-140 {padding-bottom:140px;}

.pl-0 {padding-left:0 !important;}
.pl-20 {padding-left:20px;}

.plr-0 {padding-left:0 !important;	padding-right:0 !important;}
.plr-1 {padding-left: 1px; padding-right: 1px;}
.plr-5 {padding-left: 5px; padding-right: 5px;}
.plr-10 {padding-left: 10px; padding-right: 10px;}
.plr-15 {padding-left: 15px; padding-right: 15px;}
.plr-20 {padding-left: 20px; padding-right: 20px;}
.plr-30 {padding-left: 30px; padding-right: 30px;}
.plr-40 {padding-left: 40px; padding-right: 40px;}
.plr-50 {padding-left: 50px; padding-right: 50px;}
.pad-login-right {padding-right:0 !important;}

@media (max-width: 1024px) {
  .page-title-cont, .page-title-small {
    padding-top:10px;
    padding-bottom:40px;
  }
  .page-title-cont.page-title-large {
    padding-top:30px;
  }
  .page-title-cont.page-title-large.blog-single-fullwidth-img {
    padding-top:480px;
  }
  .page-title-img {
    background-attachment: scroll;
  }
}
@media (max-width: 768px) {
  .page-title-small .breadcrumbs, .breadcrumbs  {
    margin-top: 0px;
    text-align:left;
  }
}
@media (max-width: 767px) {
  .plr-0-767 {
   padding-left:0; padding-right:0;
  }
  .pt-0-767{
    padding-top:0px !important;
  } 
  .pt-b-10-767{
    padding-top:20px !important;
    padding-bottom:20px !important;
  }
  .page-title {
    margin-bottom:10px;
    font-size:20px;
    line-height:24px;
  }
  .page-title-cont.page-title-large.blog-single-fullwidth-img {
    padding-top:220px;
  }
}
@media only screen and (max-width:767px) {
  .m-xs-0{margin:0!important}
  .mt-xs-0{margin-top:0!important}
  .mt-xs-10{margin-top:10px !important}
  .mt-xs-20{margin-top:20px !important}
  .mt-xs-30{margin-top:30px !important}
  .mt-xs-40{margin-top:40px !important}
  .mt-xs-50{margin-top:50px !important}
  .mt-xs-60{margin-top:60px !important}
  .mt-xs-70{margin-top:70px !important}
  .mt-xs-80{margin-top:80px !important}
  .mt-xs-90{margin-top:90px !important}
  .mt-xs-100{margin-top:100px !important}
  .mt-xs-110{margin-top:110px !important}
  .mt-xs-120{margin-top:120px !important}
  .mt-xs-130{margin-top:130px !important}
  .mt-xs-140{margin-top:140px !important}
  .mb-xs-0{margin-bottom:0 !important}
  .mb-xs-10{margin-bottom:10px !important}
  .mb-xs-20{margin-bottom:20px !important}
  .mb-xs-30{margin-bottom:30px !important}
  .mb-xs-40{margin-bottom:40px !important}
  .mb-xs-50{margin-bottom:50px !important}
  .mb-xs-60{margin-bottom:60px !important}
  .mb-xs-70{margin-bottom:70px !important}
  .mb-xs-80{margin-bottom:80px !important}
  .mb-xs-90{margin-bottom:90px !important}
  .mb-xs-100{margin-bottom:100px !important}
  .mb-xs-110{margin-bottom:110px !important}
  .mb-xs-120{margin-bottom:120px !important}
  .mb-xs-130{margin-bottom:130px !important}
  .mb-xs-140{margin-bottom:140px !important}
  .mr-xs-0{margin-right:0 !important}
  
  .p-xs-0{padding:0!important}
  .pt-xs-0{padding-top:0!important}
  .pt-xs-10{padding-top:10px}
  .pt-xs-20{padding-top:20px}
  .pt-xs-30{padding-top:30px}
  .pt-xs-40{padding-top:40px}
  .pt-xs-50{padding-top:50px}
  .pt-xs-60{padding-top:60px}
  .pt-xs-70{padding-top:70px}
  .pt-xs-80{padding-top:80px}
  .pt-xs-90{padding-top:90px}
  .pt-xs-100{padding-top:100px}
  .pt-xs-110{padding-top:110px}
  .pt-xs-120{padding-top:120px}
  .pt-xs-130{padding-top:130px}
  .pt-xs-140{padding-top:140px}
  .pb-xs-0{padding-bottom:0!important}
  .pb-xs-10{padding-bottom:10px}
  .pb-xs-20{padding-bottom:20px}
  .pb-xs-30{padding-bottom:30px}
  .pb-xs-40{padding-bottom:40px}
  .pb-xs-50{padding-bottom:50px}
  .pb-xs-60{padding-bottom:60px}
  .pb-xs-70{padding-bottom:70px}
  .pb-xs-80{padding-bottom:80px}
  .pb-xs-90{padding-bottom:90px}
  .pb-xs-100{padding-bottom:100px}
  .pb-xs-110{padding-bottom:110px}
  .pb-xs-120{padding-bottom:120px}
  .pb-xs-130{padding-bottom:130px}
  .pb-xs-140{padding-bottom:140px}
  
  .plr-xs-0{padding-left:0;padding-right:0;}
}
@media only screen and (max-width:480px) {
  .mt-xxs-0{margin-top:0!important}
  .mt-xxs-5 {margin-top:5px !important;}
  .mt-xxs-10{margin-top:10px}
  .mt-xxs-20{margin-top:20px}
  .mt-xxs-30{margin-top:30px}
  .mt-xxs-40{margin-top:40px}
  .mt-xxs-50{margin-top:50px}
  .mt-xxs-60{margin-top:60px}
  .mt-xxs-70{margin-top:70px}
  .mt-xxs-80{margin-top:80px}
  .mt-xxs-90{margin-top:90px}
  .mt-xxs-100{margin-top:100px}
  .mt-xxs-110{margin-top:110px}
  .mt-xxs-120{margin-top:120px}
  .mt-xxs-130{margin-top:130px}
  .mt-xxs-140{margin-top:140px}
  .mb-xxs-0{margin-bottom:0!important}
  .mb-xxs-10{margin-bottom:10px}
  .mb-xxs-20{margin-bottom:20px}
  .mb-xxs-30{margin-bottom:30px}
  .mb-xxs-40{margin-bottom:40px}
  .mb-xxs-50{margin-bottom:50px}
  .mb-xxs-60{margin-bottom:60px}
  .mb-xxs-70{margin-bottom:70px}
  .mb-xxs-80{margin-bottom:80px}
  .mb-xxs-90{margin-bottom:90px}
  .mb-xxs-100{margin-bottom:100px}
  .mb-xxs-110{margin-bottom:110px}
  .mb-xxs-120{margin-bottom:120px}
  .mb-xxs-130{margin-bottom:130px}
  .mb-xxs-140{margin-bottom:140px}
  .pt-xxs-0{padding-top:0!important}
  .pt-xxs-10{padding-top:10px}
  .pt-xxs-20{padding-top:20px}
  .pt-xxs-30{padding-top:30px}
  .pt-xxs-40{padding-top:40px}
  .pt-xxs-50{padding-top:50px}
  .pt-xxs-60{padding-top:60px}
  .pt-xxs-70{padding-top:70px}
  .pt-xxs-80{padding-top:80px}
  .pt-xxs-90{padding-top:90px}
  .pt-xxs-100{padding-top:100px}
  .pt-xxs-110{padding-top:110px}
  .pt-xxs-120{padding-top:120px}
  .pt-xxs-130{padding-top:130px}
  .pt-xxs-140{padding-top:140px}
  .pb-xxs-0{padding-bottom:0!important}
  .pb-xxs-10{padding-bottom:10px}
  .pb-xxs-20{padding-bottom:20px}
  .pb-xxs-30{padding-bottom:30px}
  .pb-xxs-40{padding-bottom:40px}
  .pb-xxs-50{padding-bottom:50px}
  .pb-xxs-60{padding-bottom:60px}
  .pb-xxs-70{padding-bottom:70px}
  .pb-xxs-80{padding-bottom:80px}
  .pb-xxs-90{padding-bottom:90px}
  .pb-xxs-100{padding-bottom:100px}
  .pb-xxs-110{padding-bottom:110px}
  .pb-xxs-120{padding-bottom:120px}
  .pb-xxs-130{padding-bottom:130px}
  .pb-xxs-140{padding-bottom:140px}
  
  .pl-xxs-10 {padding-left:10px;}
}


/* #BACK TO TOP BUTTON
================================================== */
#back-top {
	position: fixed;
	bottom: 30px;
  right: 30px;
	padding:0;
	margin:0;
	z-index: 20;
}
#back-top a {
	text-decoration: none;
	display: block;
	color:#4b4e53;
  font-size:34px;
	z-index:999;
}
#back-top a:hover {
	color:#97999c;
}
.center {
  margin: 0 auto;
  max-width: 960px;
  width: 100%;
}

/*	#FAQ #NEW
===============================================*/
.faq-search-form  {
  position:relative;
  width:100%;
  font-size:18px;
}
.faq-search-form  input{
  width:100%;
}
.faq-search-form button {
  position:absolute;
  right:0;
  top:8px;
  background:transparent;
}
.input-dark-bg, .form-dark-bg button {
  color: #ddd;
}
.form-dark-bg button:hover {
  color: #fff;
}
.input-dark-bg {
  font-size:18px;
}

.input-dark-bg::-webkit-input-placeholder {color: #ddd;}
.input-dark-bg::-moz-placeholder           {color:#ddd;}
.input-dark-bg:-moz-placeholder           {color:#ddd;}
.input-dark-bg:-ms-input-placeholder      {color:#ddd;}

.input-dark-bg:focus::-webkit-input-placeholder {color: #7e8082;}
.input-dark-bg:focus::-moz-placeholder          {color:#7e8082;}/* Firefox 19+*/
.input-dark-bg:focus:-moz-placeholder           {color:#7e8082;}/* Firefox 18- */
.input-dark-bg:focus:-ms-input-placeholder      {color:#7e8082;}

/*	#COUNTER 
==================================================*/
.counter-bg {
/*****	background:url(../images/f-box4-bg.png) left top repeat ;*/
}
.counter-bg-color {
	background:#ffea00 ;
}
.stat {
	padding-top: 40px;
	padding-bottom: 65px;

}
.highlight {
	font-family: 'Lato',Arial,Helvetica,sans-serif;
	font-weight:300;
	color:#4b4e53;
  display:block;
	float:left; 
	overflow:hidden;
	margin-bottom: 10px;
	font-size:48px;
	line-height:48px;
}
.stat i {
  color:#fff;
}
.milestone-details {
	margin-left:70px;
 	font-family: 'Lato',Arial,Helvetica,sans-serif;
	font-weight:300;
	font-size:18px;
}
.counter-icon{
 	display: block;
	float:left; 
	height: 50px;
	width: 50px;
	margin-right:20px;
	font-size: 48px;
	line-height: 48px;
	font-weight: 100 !important;
	color: #4b4e53;
	transition: all .3s ease 0s;
}
.milestone-counter {
	margin-top:50px;
	min-height:105px;
}

/* #ACCORDION-CUSTOM
================================================== */
h3.ui-accordion-header {
	margin:0;
	cursor:pointer;
}
h3.ui-accordion-header:first-child {
	margin:0;
}
.ui-accordion-header {
	font-size:14px;
 	font-family: 'Open Sans',Arial,Helvetica,sans-serif;
	line-height:50px;
	margin:0;
	background-color:#eee;
}
.accordion-no-bg  .ui-accordion-header{
	background: none;
}
.ui-accordion-content p{
	margin:0;
	padding-top:0px;
	padding-bottom:12px;
}
.ui-accordion-content p:last-child{
	padding-bottom:0px;
}
.ui-accordion-content{
	margin-left:50px;
	border-right: 15px solid #eee;
  display: none;
  margin-left: 49px;
  padding: 15px;
  padding-right: 17px;
}
.accordion-no-bg  .ui-accordion-content{
	border-left: 5px solid #dfdfdf;
	border-right: none;
	display: none;
	margin-left: 23px;
	padding: 15px 0 15px 36px;
}
.ui-accordion-header a{
	color:#4b4e53;
}
.accordion-no-bg .ui-accordion-header a{
	font-size:16px;
	line-height:16px;
}
.ui-accordion-header span:before{
	display:block;
	content:'+';
	position:absolute;
	left:19px;
  font-size:18px;
  font-style:normal;
  color:#fff;
}
.ui-accordion-header span{
  position:relative;
	background-color: #7c8187;
	display: block;
	float: left;
	height: 50px;
	width: 49px;
	margin-right: 15px;
	transition: all .3s ease 0s;
}
.ui-accordion-header:hover span{
	background-color: #ffea00;
	transition: all .3s ease 0s;
}
.ui-accordion-header:hover span:before{
  color:#4b4e53;
}
.ui-accordion-header-active span {
	background-color: #ffea00;
}
.ui-accordion-header-active span:before {
	content:'–';
  left:20px;
  color:#4b4e53;
}

/* #ACCORDION 3 #NEW
=========================================================*/
.accord-2 .panel {
  box-shadow:none;
}
.accord-2 .panel-title {
  font-size: 14px;
  font-family: 'Open Sans',Arial,Helvetica,sans-serif;
}
.accord-2 .panel-group .panel, .accord-2 .panel-heading {
  border-radius: 0;
}
.accord-2 .panel-default, .accord-2 .panel-default>.panel-heading, .accord-2 .panel-default>.panel-heading+.panel-collapse>.panel-body {
  border:none;
}
.accord-2 .panel-title a {
  display:block;
  padding: 12px 18px;
}
.accord-2 .panel-heading {
  padding: 0;
}  
.accord-2 .panel-body {
  margin-top: 7px;
  margin-bottom: 10px;
  padding: 10px 20px 20px;
}

/* #ACCORDION #NEW
=========================================================*/
.accordion > dt{
	margin-bottom: 10px;
	font-size: 12px;
	font-weight: normal;
	letter-spacing: 1px;
}
.accordion > dt > a{
	display: block;
	position: relative;
	color: #777;
	text-decoration: none;
	padding: 14px 20px;
	border: 1px solid #e5e5e5;

	-webkit-transition: all 0.25s cubic-bezier(0.000, 0.000, 0.580, 1.000);  
    -moz-transition: all 0.25s cubic-bezier(0.000, 0.000, 0.580, 1.000); 
    -o-transition: all 0.25s cubic-bezier(0.000, 0.000, 0.580, 1.000);
    -ms-transition: all 0.25s cubic-bezier(0.000, 0.000, 0.580, 1.000); 
    transition: all 0.25s cubic-bezier(0.000, 0.000, 0.580, 1.000);
}
.accordion > dt > a:hover{
	text-decoration: none;
	border-color: #ddd;
	background-color: #fcfcfc;	
	color: #444;
}
.accordion > dt > a.active{
	color: #000;
	border-color: #ddd;
	background-color: #fcfcfc;
	cursor: default;
}
.accordion > dt > a:after{
	content: "\f107";
	width: 15px;
	height: 15px;
	margin-top: -8px;
	position: absolute;
	top: 50%;
	right: 10px;
	font-family: 'FontAwesome';
	speak: none;
	font-style: normal;
	font-weight: normal;
	font-variant: normal;
	text-transform: none;
	font-size: 15px;
	line-height: 15px;
	text-align: center;
	color: #999;
	-webkit-font-smoothing: antialiased;
	-webkit-transition: all 0.25s cubic-bezier(0.000, 0.000, 0.580, 1.000);  
    -moz-transition: all 0.25s cubic-bezier(0.000, 0.000, 0.580, 1.000); 
    -o-transition: all 0.25s cubic-bezier(0.000, 0.000, 0.580, 1.000);
    -ms-transition: all 0.25s cubic-bezier(0.000, 0.000, 0.580, 1.000); 
    transition: all 0.25s cubic-bezier(0.000, 0.000, 0.580, 1.000);
}
.accordion > dt > a.active:after,
.accordion > dt > a.active:hover:after{
	content: "\f106";
	color: #111;
}
.accordion > dt > a:hover:after{
	color: #444;
}
.accordion > dd{
	margin-bottom: 10px;
	padding: 10px 20px 20px;
	font-size: 14px;
	line-height: 1.8;
	color: #7e8082;
}

/* #TOGGLE #NEW
=========================================================*/
.toggle > dt{
	margin-bottom: 10px;
	font-size: 12px;
	font-weight: normal;
	letter-spacing:1px;
}
.toggle > dt > a{
	display: block;
	position: relative;
	color: #7e8082;
	text-decoration: none;
	padding: 14px 20px;
	border: 1px solid #e5e5e5;
	
	-webkit-transition: all 0.25s cubic-bezier(0.000, 0.000, 0.580, 1.000);  
    -moz-transition: all 0.25s cubic-bezier(0.000, 0.000, 0.580, 1.000); 
    -o-transition: all 0.25s cubic-bezier(0.000, 0.000, 0.580, 1.000);
    -ms-transition: all 0.25s cubic-bezier(0.000, 0.000, 0.580, 1.000); 
    transition: all 0.25s cubic-bezier(0.000, 0.000, 0.580, 1.000);
}
.toggle > dt > a:hover{
	text-decoration: none;
	border-color: #ddd;
	background-color: #fcfcfc;	
	color: #444;
}
.toggle > dt > a.active{
	color: #101010;
	border-color: #ddd;
	background-color: #fcfcfc;
}
.toggle > dt > a:after{
	content: "\f107";
	width: 15px;
	height: 15px;
	margin-top: -8px;
	position: absolute;
	top: 50%;
	right: 10px;
	font-family: 'FontAwesome';
	speak: none;
	font-style: normal;
	font-weight: normal;
	font-variant: normal;
	text-transform: none;
	font-size: 15px;
	line-height: 15px;
	text-align: center;
	color: #999;
	-webkit-font-smoothing: antialiased;
	-webkit-transition: all 0.25s cubic-bezier(0.000, 0.000, 0.580, 1.000);  
    -moz-transition: all 0.25s cubic-bezier(0.000, 0.000, 0.580, 1.000); 
    -o-transition: all 0.25s cubic-bezier(0.000, 0.000, 0.580, 1.000);
    -ms-transition: all 0.25s cubic-bezier(0.000, 0.000, 0.580, 1.000); 
    transition: all 0.25s cubic-bezier(0.000, 0.000, 0.580, 1.000);
}
.toggle > dt > a.active:after,
.toggle > dt > a.active:hover:after{
	content: "\f106";
	color: #111;
}
.toggle > dt > a:hover:after{
	color: #444;
}
.toggle > dd{
	margin-bottom: 10px;
	padding: 10px 20px 20px;
	font-size: 14px;
	line-height: 1.8;
}
  
/* #TOGGLE
=========================================================*/
.toggle-view-custom {
	margin:0;
	padding:0;
	list-style:none;
}
.toggle-view-custom li {
	cursor:pointer;
}
.toggle-view-custom li:first-child {
	margin-top:0px;
}
.toggle-view-custom .panel {
	display:none;
	background:none;
	border:none;
	border-radius:0;
	border-right: 15px solid #eee;
	margin:0;
	margin-left: 49px;
	padding: 15px;
	padding-right: 17px;
	-webkit-box-shadow: none; 
	-moz-box-shadow: none; 
	box-shadow: none;
}
.toggle-no-bg.toggle-view-custom .panel {
	border-left: 5px solid #dfdfdf;
	border-right: none;
	display: none;
	margin-left: 23px;
	padding: 15px 0 15px 36px;
}
.toggle-no-bg  .ui-accordion-header{
	background: none;
}
.toggle-view-custom .panel p {
	margin-top:0;
}	
.panel p:last-child {
	margin-bottom:0px;
}

/* #PRICING TABLE
=========================================*/

/***** #NEW PRICE TABLE	*****/
.pt-h4-container {
	margin:0;
	padding:80px 20px 25px 20px;
	border-bottom:none;
  font-weight:600;
}
.price-container-4 {
  padding: 0 20px 15px 20px;
  position: relative;
}
.currency-4, .price-4, .cents-4, .place2-4{
  font-family: 'Lato', Arial, Helvetica, sans-serif;
  display: inline-block;
  color: #4b4e53;
}
.price-4 {
  font-size:100px;
  line-height:100px;
  font-weight:600;
}
.currency-4 {
  margin-right:5px;
  font-size:18px;
}
.place2-4 {
  margin-left:4px;
  font-size:18px;
  font-weight:100;
}
.pricing-table-4 {
  margin:0 auto;
  width:300px;
}
.pricing-table-4 ul {
  margin:25px 0 40px 0;
  padding:0;
}
.pricing-table-4 ul li {
  margin: 0;
  padding: 0;
  display: block;
  color: #696c6f;
  line-height: 35px;
  margin-left: 20px;
}
.price-button-container-4 {
  padding:0 0 80px 20px;
}
/***** #OLD PRICE TABLE	*****/
.pt-h1-container {
	margin:0;
	padding:40px 20px 40px 20px;
	border:2px solid #4b4e53;
	border-bottom:none;
	text-align:center;
}
.pt-content-container {
	border-right:2px solid #4b4e53;
	border-left:2px solid #4b4e53;
}
.pt-best .pt-content-container {
	border-right:2px solid #ffea00;
	border-left:2px solid #ffea00;
}
.pt-best .pt-h1-container {
	border:2px solid #ffea00;
	border-bottom:none;
}
.pt-best .price-container {
	background:#ffea00;
	color:#4b4e53;
}
.pt-best .currency, .pt-best .price, .pt-best .cents , .pt-best .place2{
	color:#4b4e53;
}
.price-container .price {
	font-size: 60px;
	line-height: 50px;
}
.pricing-table ul {
	margin: 0;
	padding: 0;
	list-style: none;
	background-color: #fff;
}
.pricing-table ul li {
	background: #fff;
	margin: 0;
	padding: 0;
	display: block;
	color: #6b6b6b;
	line-height: 50px;
	border-bottom: 1px solid #efefef;
	margin-left: 20px;
}
.price-container {
	background-color: #4b4e53;
	padding: 20px 20px 15px 20px;
	position: relative;
	text-align: center;
}

/*****	#PRICING TABLE HORIZONTAL	*****/
.pricing-horizontal {
	
}
.pricing-horizontal-content-container {
	border:2px solid #eee;
	border-right:none;
	padding: 5px 0 25px 30px;
}
.pricing-horizontal-price-container {
	padding:0;
	background:#eee;
	height:100%;
	position:absolute;
	right:15px;
}
.pricing-horizontal-price-container .price-container{
	background:#eee;
}
.pricing-horizontal-price-container .price-container .currency, .pricing-horizontal-price-container .price-container .place2, .pricing-horizontal-price-container .price-container .price, .pricing-horizontal-price-container .price-container .cents {
	color: #4b4e53;
}
/***** #NEW PRICE TABLE END	*****/

.price-col-gray {
	float: left;
	width: 235px;
	height: auto;
	text-align: center;
	margin: 20px 0 0 0;
}
.m-left-0 {
	margin-left:0 !important;
}	
.price-col-gray h1 {
	margin: 0;
	padding-bottom: 35px;
  padding-top: 35px;
	height: 40px;
	display: block;
	background-color: #4b4e53;
	color: #fcfcfc;
	text-transform: uppercase;
	font-size: 24px;
	line-height: 40px;
	font-weight: normal;
	font-family: 'Lato', Arial, Helvetica, sans-serif;
}
.month {
	color:#808080;
	font-family: 'Lato', Arial, Helvetica, sans-serif;
	font-size:14px;
	background-color:#fff;
	padding:14px 0 14px 0;
	margin:0;
}
.price-col-gray ul {
	margin:0;
	padding:0;
	list-style:none;
}
.price-col-gray ul li {
	margin:0;
	padding:0;
	height: 40px;
	background: #fff;
	margin: 0;
	display: block;
	color: #6b6b6b;
	font-size: 12px;
	line-height: 40px;
	text-align: center;
	border-bottom:1px solid #efefef;
	margin-left:5px;
	margin-right:5px;
	-moz-box-sizing: border-box; 
  box-sizing: border-box;
}
.price-col-gray ul li:last-child {
	border-bottom:none;
}
.price-button-container {
	background-color:#f5f5f5;
	height: 75px;
}

.price-button-container-main {
	background-color:#f5f5f5;
	height: 95px;
}
a.button.medium.price-button {
	display: inline-block;
  font-size: 14px;
  height: 100%;
  line-height: 72px;
  padding: 0;
  width: 100%;
	font-size:18px;
	cursor:pointer;
	text-align:center;
}
.price-col-gray2 a.button.medium.price-button:hover {
	color:#fff;
}
.price-button-container-main a.button.medium.price-button {
	line-height:92px;
}
.price-marginleft-20 {
	margin-left: -20px;
}
.price-col-main {
	float: left;
	width: 234px;
	height: auto;
	text-align: center;
	margin: 0;
	position: relative;
	z-index: 20;
}
.price-col-main h1 {
	margin: 0;
	padding-bottom: 40px;
  padding-top: 41px;
	height: 49px;
	display: block;
	background-color: #ffea00;
	color: #414146;
	text-transform: uppercase;
	font-size: 24px;
	line-height: 49px;
	font-weight: normal;
	font-family: 'Lato', Arial, Helvetica, sans-serif;
}
.price-col-main p.white {
	height: 30px;
	font-size: 12px;
	font-weight: normal;
	display: block;
	background: #ffbe11;
	color: #fff;
	margin: 0;
}
.price-col-main ul {
	margin:0;
	padding:0;
	list-style:none;
	background: none repeat scroll 0 0 #d8dadd;
}
.price-col-main ul li {
	margin:0;
	padding:0;
  -moz-box-sizing: border-box;
  border-bottom: 1px solid #efefef;
  color: #6B6B6B;
  display: block;
  font-size: 12px;
  height: 40px;
  line-height: 40px;
  margin: 0 5px;
  text-align: center;
}
.price-col-main ul li:last-child {
  border-bottom: none;
}
.col-border {
	border-left:1px solid #d1d1d1;
}
.col-border-right {
	border-left:1px solid #d1d1d1;
	border-right:1px solid #d1d1d1;
}
.currency, .price, .cents {
	font-family: 'Lato', Arial, Helvetica, sans-serif;
	display:inline-block;
	color:#fff;
}
.price-col-main .currency, .price-col-main .price, .price-col-main .cents {
	color:#fff;
}
.currency {
	font-size:30px;
}
.price {
	font-size:40px;
	line-height:50px;
}
.price-container2 .price {
  font-size: 60px;
  line-height: 50px;
}
.cents {
	font-size:30px;
	position:absolute;
	top:19px
}
.cents-cont {
	width:5px;
	font-size:20px;
	font-family: 'Lato', Arial, Helvetica, sans-serif;
}
.place {
	display:inline-block;
	height:10px;
	width:25px;
	color:#9da2a8;
}
.price-col-main .price-container {
	background-color:#4b4e53;
}

/* #PRICING TABLE 2
=========================================*/
.place2 {
	display:inline-block;
	height:13px;
	width:35px;
	font-family: 'Lato', Arial, Helvetica, sans-serif;
	font-size:14px;
	color:#bcbcbc;
}
.pricing-table-2 h1 {
	margin: 0;
	padding: 30px 20px 30px 20px ;
	display: block;
	background-color: #fff;
	color:#4b4e53;
	text-transform: uppercase;
	font-size: 30px;
	line-height: 40px;
	font-weight: normal;
	font-family: 'Lato', Arial, Helvetica, sans-serif;
}
.pricing-table-2 ul {
	margin:0;
	padding:0;
	list-style:none;
	background-color:#fff;
}
.pricing-table-2 ul li {
	background: #fff;
	margin: 0;
	padding:0;
	display: block;
	color: #6b6b6b;
	line-height: 50px;
	border-bottom:1px solid #efefef;
	margin-left:20px;
}
.pricing-table-2 ul li:last-child {
	border-bottom:none;
}
.price-container2 {
  background-color: #4b4e53;
	padding:20px 20px 15px 20px;
  position: relative;
}
 .col-border2 {
  border: 1px solid #D1D1D1;
	margin-bottom:1px;
} 
.pt-col-main {
	background-color:#ffea00;

}
h1.pt-col-main {
	background-color:#ffea00;
	color:#414146;
}
.pt-col-main .currency, .pt-col-main .price, .pt-col-main .cents, .pt-col-main .place2 {
	color:#fff;
}
.price-table-grey-bg {
	background-color:#efefef;
	padding:0px 15px 30px 15px;
}

/* #TIMELINE
================================================== */
.cd-timeline-start{
	position: relative;
	display: block;
	clear: both;
	width: 250px;
	padding: 15px;
	border: 3px solid #E5E5E5;
	margin: 0 auto 10px;
	background: #FFF;
	z-index: 1;
	text-align:center;
}
.cd-final {
	margin-bottom:0;
}
.cd-timeline-start-caption, .cd-timeline-start-date  {
	font-family: 'Lato',Arial,Helvetica,sans-serif;
}
.cd-timeline-start-caption {
	color: #4b4e53;
	font-size:20px;
	padding-top:10px;
	padding-bottom:10px;
}
.cd-timeline-start-date  {
	margin-bottom:5px;
}
.cd-timeline-content h2 {
	line-height:36px;
	margin-bottom:0;
}
/*****	#MODULES - reusable parts of our design	*****/
.cd-container {
  /* this class is used to give a max-width to the element it is applied to, and center it horizontally when it reaches that max-width */
  width: 90%;
  max-width: 1170px;
  margin: 0 auto;
}
.cd-container::after {
  /* clearfix */
  content: '';
  display: table;
  clear: both;
}
#cd-timeline {
  position: relative;
  padding: 2em 0;
  padding-top:0;
  padding-bottom:0;
  margin-top: 2em;
  margin-bottom: 2em;
}
#cd-timeline::before {
  /* this is the vertical line */
  content: '';
  position: absolute;
  top: 0;
  left: 18px;
  height: 100%;
  width: 4px;
  background: #e5e5e5;
}
@media only screen and (min-width: 991px) {
  #cd-timeline {
    margin-top: 3em;
    margin-bottom: 3em;
  }
  #cd-timeline::before {
    left: 50%;
    margin-left: -2px;
  }
}
.cd-timeline-block {
  position: relative;
  margin: 2em 0;
}
.cd-timeline-block:after {
  content: "";
  display: table;
  clear: both;
}
.cd-timeline-block:first-child {
  margin-top: 0;
}
.cd-timeline-block:last-child {
  margin-bottom: 0;
}
@media only screen and (min-width: 991px) {
  .cd-timeline-block {
    margin: 4em 0;
  }
  .cd-timeline-block:first-child {
    margin-top: 0;
  }
  .cd-timeline-block:last-child {
    margin-bottom: 0;
  }
}
.cd-timeline-img {
  position: absolute;
  top: 0;
  left: 0;
  width: 40px;
  height: 40px;
  border-radius: 50%;
}
.cd-timeline-img img {
  display: block;
  width: 24px;
  height: 24px;
  position: relative;
  left: 50%;
  top: 50%;
  margin-left: -12px;
  margin-top: -12px;
}
.cd-timeline-img.cd-picture {
  background: #dfdfdf;
  text-align:center;
  font-size:24px;
  padding-top: 16px;
  color:#fff;
}
.cd-timeline-img.cd-movie {
  background: #c03b44;
}
.cd-timeline-img.cd-location {
  background: #f0ca45;
}
@media only screen and (min-width: 991px) {
  .cd-timeline-img {
    width: 60px;
    height: 60px;
    left: 50%;
    margin-left: -30px;
    /* Force Hardware Acceleration in WebKit */
    -webkit-transform: translateZ(0);
    -webkit-backface-visibility: hidden;
  }
  .cssanimations .cd-timeline-img.is-hidden {
    visibility: hidden;
  }
  .cssanimations .cd-timeline-img.bounce-in {
    visibility: visible;
    -webkit-animation: cd-bounce-1 .6s;
    -moz-animation: cd-bounce-1 .6s;
    animation: cd-bounce-1 .6s;
  }
}

@-webkit-keyframes cd-bounce-1 {
  0% {
    opacity: 0;
    -webkit-transform: scale(.5);
  }

  60% {
    opacity: 1;
    -webkit-transform: scale(1.2);
  }

  100% {
    -webkit-transform: scale(1);
  }
}
@-moz-keyframes cd-bounce-1 {
  0% {
    opacity: 0;
    -moz-transform: scale(.5);
  }

  60% {
    opacity: 1;
    -moz-transform: scale(1.2);
  }

  100% {
    -moz-transform: scale(1);
  }
}
@keyframes cd-bounce-1 {
  0% {
    opacity: 0;
    -webkit-transform: scale(.5);
    -moz-transform: scale(.5);
    -ms-transform: scale(.5);
    -o-transform: scale(.5);
    transform: scale(.5);
  }

  60% {
    opacity: 1;
    -webkit-transform: scale(1.2);
    -moz-transform: scale(1.2);
    -ms-transform: scale(1.2);
    -o-transform: scale(1.2);
    transform: scale(1.2);
  }

  100% {
    -webkit-transform: scale(1);
    -moz-transform: scale(1);
    -ms-transform: scale(1);
    -o-transform: scale(1);
    transform: scale(1);
  }
}
.cd-timeline-content {
  position: relative;
  margin-left: 60px;
  background: #f3f3f3;
  padding: 20px;
}
.cd-timeline-content:after {
  content: "";
  display: table;
  clear: both;
}
.cd-timeline-content h2 {
	font-size:20px;
  line-height: 28px;
	margin-top:0;
	margin-bottom:10px;
}
.cd-timeline-content .cd-read-more, .cd-timeline-content .cd-date {
  display: inline-block;
}
.cd-timeline-content .cd-read-more {
  float: right;
  padding: .8em 1em;
  background: #acb7c0;
  color: white;
  border-radius: .25em;
}
.no-touch .cd-timeline-content .cd-read-more:hover {
  background-color: #bac4cb;
}
.cd-timeline-content .cd-date {
  float: left;
}
.cd-date-year, .cd-date-month {
	display:block;
	font-family: 'Lato',Arial,Helvetica,sans-serif;
	font-weight:400;
}
.cd-date-year {
	font-size:20px;
}
.cd-date-month {
	font-size:14px;
}
.cd-timeline-content::before {
  content: '';
  position: absolute;
  top: 16px;
  right: 100%;
  height: 0;
  width: 0;
  border: 7px solid transparent;
  border-right: 7px solid #f3f3f3;
}
h2.portfolio-timeline-title {
	margin:10px 0 0 0;
}

@media only screen and (min-width: 991px) {
  .cd-timeline-content {
    margin-left: 0;
    padding: 1.6em;
    width: 45%;
  }
  .cd-timeline-content::before {
    top: 24px;
    left: 100%;
    border-color: transparent;
    border-left-color: #f3f3f3;
  }
  .cd-timeline-content .cd-read-more {
    float: left;
  }
  .cd-timeline-content .cd-date {
    position: absolute;
    width: 100%;
    left: 122%;
    top: 6px;
  }
  .cd-timeline-block:nth-child(even) .cd-timeline-content {
    float: right;
  }
  .cd-timeline-block:nth-child(even) .cd-timeline-content::before {
    top: 24px;
    left: auto;
    right: 100%;
    border-color: transparent;
    border-right-color: #f3f3f3;
  }
  .cd-timeline-block:nth-child(even) .cd-timeline-content .cd-read-more {
    float: right;
  }
  .cd-timeline-block:nth-child(even) .cd-timeline-content .cd-date {
    left: auto;
    right: 122%;
    text-align: right;
  }
  .cssanimations .cd-timeline-content.is-hidden {
    visibility: hidden;
  }
  .cssanimations .cd-timeline-content.bounce-in {
    visibility: visible;
    -webkit-animation: cd-bounce-2 .6s;
    -moz-animation: cd-bounce-2 .6s;
    animation: cd-bounce-2 .6s;
  }
}

@media only screen and (min-width: 991px) {
  .cssanimations .cd-timeline-block:nth-child(even) .cd-timeline-content.bounce-in {
    -webkit-animation: cd-bounce-2-inverse .6s;
    -moz-animation: cd-bounce-2-inverse .6s;
    animation: cd-bounce-2-inverse .6s;
  }
}
@-webkit-keyframes cd-bounce-2 {
  0% {
    opacity: 0;
    -webkit-transform: translateX(-100px);
  }

  60% {
    opacity: 1;
    -webkit-transform: translateX(20px);
  }

  100% {
    -webkit-transform: translateX(0);
  }
}
@-moz-keyframes cd-bounce-2 {
  0% {
    opacity: 0;
    -moz-transform: translateX(-100px);
  }

  60% {
    opacity: 1;
    -moz-transform: translateX(20px);
  }

  100% {
    -moz-transform: translateX(0);
  }
}
@keyframes cd-bounce-2 {
  0% {
    opacity: 0;
    -webkit-transform: translateX(-100px);
    -moz-transform: translateX(-100px);
    -ms-transform: translateX(-100px);
    -o-transform: translateX(-100px);
    transform: translateX(-100px);
  }

  60% {
    opacity: 1;
    -webkit-transform: translateX(20px);
    -moz-transform: translateX(20px);
    -ms-transform: translateX(20px);
    -o-transform: translateX(20px);
    transform: translateX(20px);
  }

  100% {
    -webkit-transform: translateX(0);
    -moz-transform: translateX(0);
    -ms-transform: translateX(0);
    -o-transform: translateX(0);
    transform: translateX(0);
  }
}
@-webkit-keyframes cd-bounce-2-inverse {
  0% {
    opacity: 0;
    -webkit-transform: translateX(100px);
  }

  60% {
    opacity: 1;
    -webkit-transform: translateX(-20px);
  }

  100% {
    -webkit-transform: translateX(0);
  }
}
@-moz-keyframes cd-bounce-2-inverse {
  0% {
    opacity: 0;
    -moz-transform: translateX(100px);
  }

  60% {
    opacity: 1;
    -moz-transform: translateX(-20px);
  }

  100% {
    -moz-transform: translateX(0);
  }
}
@keyframes cd-bounce-2-inverse {
  0% {
    opacity: 0;
    -webkit-transform: translateX(100px);
    -moz-transform: translateX(100px);
    -ms-transform: translateX(100px);
    -o-transform: translateX(100px);
    transform: translateX(100px);
  }

  60% {
    opacity: 1;
    -webkit-transform: translateX(-20px);
    -moz-transform: translateX(-20px);
    -ms-transform: translateX(-20px);
    -o-transform: translateX(-20px);
    transform: translateX(-20px);
  }

  100% {
    -webkit-transform: translateX(0);
    -moz-transform: translateX(0);
    -ms-transform: translateX(0);
    -o-transform: translateX(0);
    transform: translateX(0);
  }
}

/*	#CLEARFIX
============================================================*/
.clearfix:before, .clearfix:after, .row:before, .row:after {
  content: '\0020';
  display: block;
  overflow: hidden;
  visibility: hidden;
  width: 0;
  height: 0; 
}
.row:after, .clearfix:after { clear: both; }
.row, .clearfix { zoom: 1; }
	  
/******************************************************************************
*******************************************************************************
  
  #RESPONSIVE STYLES
  
******************************************************************************* 
*******************************************************************************/

.display-block-xs {display:none;}

/******************************************************************************
  Max Width "480px"
*******************************************************************************/
@media (max-width: 480px) {
  .text-center-xxs {
    text-align:center;
  }
  .mt-0-xxs {margin-top:0;}
  .ml-10-xxs {margin-left:10px;}
  
  .font-32-wide-xxs {
    font-size:32px;
    letter-spacing:5px;
    line-height:40px;
  }
  .display-no-xxs {
    display:none !important;
  }
}
/******************************************************************************
  Max Width "768px"
*******************************************************************************/
@media (max-width: 768px) {
  /*	#FOOTER 2 ====================================*/
  .footer-2-copy-cont .left, .footer-2-copy-cont .right {
    float:none;
  }
  .footer-2-copy-cont {
    text-align:center;
  }
  .text-center-sm {
    text-align:center;   
  }
}

/******************************************************************************
  Max Width "600px"
*******************************************************************************/
@media (max-width: 600px) {
	.header .menu-btn-respons-container .btn-navbar.collapsed:before {
		display: none;
	}
	/*	#TESTIMONIALS ====================================*/
	.ts-text-container {
		padding-top: 20px;
		padding-left: 15px;
		padding-right: 15px;
		font-size: 13px;
	}
	.t-a-container {
		padding-bottom: 10px;
		margin-top: 10px;
	}
	.ts-img-container {
		display:none;
	}
}

/******************************************************************************
  Max Width "640px"
*******************************************************************************/
@media (max-width: 640px) {
	.nl-caption {
		display: none;
	}
}

/******************************************************************************
  Max Width "767px"
*******************************************************************************/
@media (max-width: 767px) {
  #main-menu .navbar-collapse.in  {
    overflow: visible !important;
  }
  .text-center-xs { text-align:center;}
  
  .pt-20-xs{ padding-top:20px;}
  .pt-30-xs{ padding-top:30px;}
  
  .mt-0-xs{ margin-top:0;}
  .mt-40-xs{ margin-top:40px;}
  
  .display-block-xs {display:block;}
}

/******************************************************************************
  Min Width "768px"
*******************************************************************************/
/* @media (min-width:768px){
	.navbar-nav{float:none}
	.navbar-nav>li{float:none}
} */

/******************************************************************************
  Max Width "991px" - Min Width "768px"
*******************************************************************************/
@media (min-width: 768px) and (max-width: 1024px) {
  #main-menu .navbar-collapse.collapse {
    display: none !important;
  }
  #main-menu .navbar-collapse.collapse.in {
    display: block !important;
  }
  .navbar-nav{float:none}
  .navbar-nav>li{float:none}  

}

/******************************************************************************
  Max Width "991px"  PHONE
*******************************************************************************/
@media (max-width: 991px) {

  .wow {
    visibility: visible !important;
    -webkit-animation: none !important;
       -moz-animation: none !important;
         -o-animation: none !important;
        -ms-animation: none !important;
            animation: none !important;
  }
  .hide-max-960 {
		display:none;
	}  
  
	/*****	#MARGINS MOBILE	*****/
	.p-top-60-mobile {
		padding-top:60px;
	}
	.m-top-0-mobile {
		margin-top:0;
	}
	.m-top-min-40-mobile {
		margin-top:-40px;
	}
	
	/*****	#NEWSLETTER	*****/
	.nl-button{
		padding-left: 10px;
		padding-right: 10px;
	}
	.nl-icon-container-bg {
		display:none;
	}
	.nl-main-container-bg {
		margin-left: 0px;
	}
	
	/*****	#SLIDER 1	*****/
	.tp-bullets, .tp-banner-container .tp-bullets.simplebullets.preview4 div span:before{
		display:none;
	}
	.tp-button, .tp-button.button.medium.thin {
		font-size:12px !important;
		line-height:18px !important;
		padding: 8px 35px 8px 35px !important;
	}
	.tp-button.button.medium.thin {
		font-size:12px !important;
		line-height:18px !important;
		padding: 6px 33px 6px 33px !important;
	}

	/*****	#TWITTER	*****/
/* 	.tweet {
		min-height: inherit;
	} */

	/*	#TESTIMONIALS ====================================*/
	.ts-text-container {
		padding-top: 40px;
		padding-left: 30px;
	}
	.t-a-container {
		margin-top: 20px;
	}

	/* #TIMELINE  ====================================*/
	.cd-timeline-start{
		margin:0;
	}
	.cd-timeline-img.cd-picture {
		font-size: 18px;
		padding-top: 8px;
	}
	.cd-date{
		margin-top: 15px;
		background: #fff;
		margin-left: -20px;
		margin-bottom: -5px;
		padding: 15px;
	}
	.cd-date-year, .cd-date-month {
		display:inline-block;
		font-size:14px;
	}
	.cd-date-year {
		margin-right:10px;
		margin-bottom:0;
	}
	
	/* #MAINTENANCE  ================================ */
	.maintenance-icon-container {
		margin-top:0px;
	}
	.maintenance-icon {
		font-size:100px;
	}
	.maintenance-text-container h1 {
		margin-top:0;
		margin-bottom:10px;
		font-size:40px;
		line-height:40px;
		font-weight:600;
	}
	.maintenance-text-container h2 {
		margin-top:0;
	}
	.img-container-404 {
		margin-bottom:65px;
	}
	
	/*	#PRICING TABLE ====================================*/	
	.pricing-horizontal-content-container  {
		border: 2px solid #eee;
	}
	.pricing-horizontal-price-container {
		position: relative;
		right: 0;
	}
}

/******************************************************************************
  Max Width "1024px" DESKTOP
*******************************************************************************/
@media (max-width: 1024px) {
  .hide-lg{
    display: none;
  }

  /*	#BACK TO TOP ====================================*/	
  #back-top {
    bottom: 9px;
    right: 12px;
  }

  /*	#HEADER MENU ====================================*/	
	/*****	#HEADER	*****/
	.container-m-30 {
		padding:0;
	}
  .nofloat.col-md-3, .nofloat.col-md-4, .nofloat.col-md-2{
    float:none;
    width:auto;
  }
	.logo-2, .header.no-transparent .logo-2 {
	  padding: 17px 0 17px 13px;
	}
	.logo-row {
		width:auto;
	}
	.header-side-menu .logo-row {
		width:180px;
	}
	.cd-header-buttons {
	  top: 21px;
	  right: 50px;
	  margin:0;
	  padding:0;
	}
	.cd-search-trigger::before {
	  width: 12px;
	  height: 12px;
	  left: 15px;
	  top:10px;
	  border: 2px solid #4b4e53;
	}
	.cd-search-trigger::after {
	  width: 7px;
	  bottom: 21px;
	  right: 14px;
	  height: 2px;
	}
 	.affix .cd-header-buttons {
	  top: 13px;
	}
	.cd-search {
	  height: 76px;
	}
	.cd-search-trigger span::before, .cd-search-trigger span::after {
	  width: 17px;
	  margin-top: -5px;
	  margin-left: -8px;
	}
	.cd-search-trigger.search-is-visible{
	  right: -37px;	
	}
	.cd-search input {
	  padding: 0 20px;
	}	
	.hamb-mob-icon {
	  display: block;
	  float: left;
	  padding: 25px 17px 22px 5px; 
	  font-size: 29px;
	  color: #4b4e53;
	  transition: padding .4s ease 0s;
	  transform: translateZ(0);
	}
  .black-header .hamb-mob-icon {
	  color: #fff;
	}
	.affix .hamb-mob-icon {
	  padding: 17px 17px 13px 5px;
	  transition: padding .4s ease 0s;
	  transform: translateZ(0);
	}

	/****************************************/
	.menu-row  {
		margin-top:0px !important;
	}
	.affix.header .btn-navbar .main-menu-icon {
		display:block;
	}
	.header.header-always-sticky {
		height:60px;
	}
	.header-1 .main-content {
		margin-top: 0px;
	}
	.menu-btn-respons-container{
	  position: absolute;
	  right: 0;
	  top: 0;
	}
	.main-menu-container {
		background-color: #4b4e53;
	}
 	.header .logo, .header-maintenance .logo {
		padding-top:21px;
		transition: all .3s ease 0s;
	}
	.menu-contact-info .contact-list {
		padding-top: 30px;
		padding-bottom: 30px;
	}
	.menu-address-container {
		padding-top: 12px;
	}
	.menu-map-container {
		margin-left: -20px;
		margin-bottom: -5px;
	}
	.menu-contact-info {
		margin: 0;
	}
	#main-menu .sub .a-mail {
		padding:0 !important;
	}
	nav .main-menu-icon {
		display:none;
	}
	.btn-navbar .icon_menu.main-menu-icon {
		float:left;
		margin:0;
		margin-top: 7px;
		padding-bottom: 0px;
		padding-top: 13px;
	}

	/***** #INDENT MAIN CONTAINER	*****/	
  .header {
    position: relative;  
    margin-bottom:-76px; 
    width: auto;
    height:auto;
  }
  /* .header-side-menu.header {
    position: fixed;  
    margin-bottom:0; 
    width: auto;
    height:auto;
  } */
  .header.mobile-no-transparent {
    margin-bottom:0px; 
  }
  .header.black-header.mobile-no-transparent {
    background:#303236;
  }
  .header .right-box-wrapper {
    text-align: right;
  }
  .right-1024 {
    float:none;
  } 
  #main-menu {
    margin: 0;
    text-align: left;
  }
  .header .navbar {
    margin: 0;
    min-height: inherit;
    position: static;
  }
  .header .menu-btn-respons-container .btn-navbar {
    background: #fff;
    border:none !important;
    display: block;
    margin:0;
    padding:0;
  }
  .header .menu-btn-respons-container .btn-navbar:before {
    content: "";
    display: none;
  }
  .header .menu-btn-respons-container .btn-navbar.collapsed:before {
    display: block;
  }
  .header .menu-btn-respons-container .btn-navbar.collapsed {
    background: none;
    border: 1px solid transparent;
    border-bottom: 0 none;
  }
  .header .btn-navbar .text {
    color: #1e1e1e;
    float: left;
    line-height: 16px;
    padding: 0;
    font-family: 'Lato',Arial,Helvetica,sans-serif;
    font-weight:300;
    font-size:14px;
    padding-top: 23px;
    padding-right: 15px;
  }
  #main-menu .navbar .nav  {
    padding-top:10px;
  }
  #main-menu .navbar .nav li  a {
    font-size:13px;
  }
  #main-menu .navbar .nav > li > a {
    font-weight: normal;
    margin: 0 !important;
    padding: 16px 9px 16px 17px !important;
    text-decoration: none;
    width:100%;
    text-align: left;
    color:#d3d3d3 !important; 
    border:none !important;
  }
  #main-menu .navbar .nav .parent .parent > a:after {
    display: none;
  }
  #main-menu .navbar .nav .open-sub {
    position: absolute;
    bottom: 0;
    left: 0;
    top: 0;
    width: 100%;
  }
  #main-menu .navbar .nav .open-sub:before {
    content: "\e232";
    font-size: 20px;
    font-family: "linea-icons" !important;
    color:#d3d3d3;
    padding-top: 15px;
    right: 14px;
    position: absolute;
    font-style: normal !important;
    font-weight: normal !important;
    font-variant: normal !important;
    text-transform: none !important;
    speak: none;
    line-height: 1;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }
  #main-menu .navbar .nav .current .open-sub:before {
    color:#fff;
  }
  #main-menu .navbar .nav .active .open-sub:before {
    content: "\e200";
  }
  #main-menu .navbar .nav .active .sub .open-sub:before {
    content: "\e232";
  } 
  #main-menu .navbar .nav .active .sub  .active .open-sub:before {
    content: "\e200";
  } 
  #main-menu .navbar .nav > li.parent:hover > a:after {
    display: none;
  }
  #main-menu .sub,
  #main-menu .parent  .sub ,
  #main-menu .parent:hover  .sub  {
    display: none;
    position: relative;
    margin: 0;
    padding: 0;
    background: #3e4045;
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
    box-shadow: none;
  }
  #main-menu  .parent .sub .sub {
    background: #323538;
  }
  #main-menu .sub a.current {
    color:#fff;
  }
  #main-menu .navbar .nav > li.current > a {
	  margin:0;
	  color: #fff !important;
	  border:none !important;
	}
  #main-menu .navbar .nav > .parent.active > a,
  #main-menu .navbar .nav > .parent.active:hover > a{
    background: transparent;
  }
  #main-menu .parent:hover > .sub {
    -webkit-animation: fadeIn 0;
    animation: fadeIn 0;
  }
  #main-menu .sub li {
    line-height: 22px;
  }
  #main-menu .sub a {
    padding: 15px 9px 15px 27px !important;
  }
  #main-menu .sub .sub a {
    padding: 15px 9px 15px 37px !important;
  }
  #main-menu .sub .sub {
    left: 0;
    margin-left: 25px;
    padding-top: 0px !important;
    padding-bottom: 0px !important;
  }
  #main-menu .megamenu > .sub {
    width: 100%;
  }
  #main-menu .megamenu > .sub .sub-wrapper {
    display: block;
  }
  #main-menu .megamenu > .sub .mob-menu-hide {
    display: none;
  }
  #main-menu .megamenu > .sub .sub-list {
    display: block;
    padding: 0;
    width: 100% !important;
  }
  #main-menu .megamenu > .sub .box {
    padding: 0;
  }
  #main-menu .megamenu .title {
    cursor: pointer;
    font-weight: normal;
    line-height: 22px;
    margin: 0;
    padding: 15px 9px 15px 27px !important;
    position: relative;
    text-transform: none;
    font-family: 'Open Sans', Arial, Helvetica, sans-serif;
    font-size:13px;
    color:#d3d3d3;
    border: none;
  }
  #main-menu .megamenu > .sub .box.closed ul {
    display: none;
    background: #323538;
    padding-left:10px;
  }
  
  .mt-60-md {margin-top:60px;}
  .mb-md-30 {margin-bottom:30px;}
}

/******************************************************************************
  Min Width "1025px" DESKTOP
*******************************************************************************/
@media (min-width: 1025px) {
  .navbar-collapse {
    display: block !important;
    height: auto !important;
  }
  .navbar-collapse.collapse {
    padding-bottom: 0;
    overflow: visible !important;
  }
  .header-1 #main-menu .nav.navbar-nav {
    margin-right: 54px;
  }
  .main-menu-container {
    position: absolute;
    top: 0;
    right: 0;
    width: 100%;
  }
  #header-left .cd-header-buttons, #header-left #cd-search {
    display:none;
  }
  .side-content .container{
    width:100%;
  }

}

/******************************************************************************
  Max Width "1024px" TABLET
*******************************************************************************/
@media (max-width: 1024px) {

	/*	#TWITTER =======================================*/
/* 	.tweet .fa-twitter{
		margin-right: 15px;	
		font-size: 24px;
	}
	.tweet_text {
		padding: 0 18px 5px 36px;
	} */

}


/******************************************************************************
  Max Width "479px"
*******************************************************************************/
@media (max-width: 479px) {
  .nl-email-input {
    width: 240px;
    margin-right: 0px;
  }
}

/******************************************************************************
* 	CSS SOURCE #OWL & #Magnific Popup CSS 
******************************************************************************/

/******************************************************************************
* 	Core #Owl Carousel CSS File
* 	v1.3.3
******************************************************************************/

/* clearfix */
.owl-carousel .owl-wrapper:after {
	content: ".";
	display: block;
	clear: both;
	visibility: hidden;
	line-height: 0;
	height: 0;
}
/* display none until init */
.owl-carousel{
	display: none;
	position: relative;
	width: 100%;
	-ms-touch-action: pan-y;
}
.owl-carousel .owl-wrapper{
	display: none;
	position: relative;
	-webkit-transform: translate3d(0px, 0px, 0px);
}
.owl-carousel .owl-wrapper-outer{
	overflow: hidden;
	position: relative;
	width: 100%;
    z-index: 5;
}
.owl-carousel .owl-wrapper-outer.autoHeight{
	-webkit-transition: height 500ms ease-in-out;
	-moz-transition: height 500ms ease-in-out;
	-ms-transition: height 500ms ease-in-out;
	-o-transition: height 500ms ease-in-out;
	transition: height 500ms ease-in-out;
}
.owl-carousel .owl-item{
	float: left;
}
.owl-controls .owl-page,
.owl-controls .owl-buttons div{
	cursor: pointer;
}
.owl-controls {
	-webkit-user-select: none;
	-khtml-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
	user-select: none;
	-webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}
/* mouse grab icon */
.grabbing { 
/*****  cursor:url(grabbing.png) 8 8, move;*/
}
/* fix */
.owl-carousel  .owl-wrapper,
.owl-carousel  .owl-item{
	-webkit-backface-visibility: hidden;
	-moz-backface-visibility:    hidden;
	-ms-backface-visibility:     hidden;
  -webkit-transform: translate3d(0,0,0);
  -moz-transform: translate3d(0,0,0);
  -ms-transform: translate3d(0,0,0);
}

/******************************************* 
 *  Owl Carousel CSS3 Transitions 
 *  v1.3.2
 *******************************************/
.owl-origin {
	-webkit-perspective: 1200px;
	-webkit-perspective-origin-x : 50%;
	-webkit-perspective-origin-y : 50%;
	-moz-perspective : 1200px;
	-moz-perspective-origin-x : 50%;
	-moz-perspective-origin-y : 50%;
	perspective : 1200px;
}
/* fade */
.owl-fade-out {
  z-index: 10;
  -webkit-animation: fadeOut .7s both ease;
  -moz-animation: fadeOut .7s both ease;
  animation: fadeOut .7s both ease;
}
.owl-fade-in {
  -webkit-animation: fadeIn .7s both ease;
  -moz-animation: fadeIn .7s both ease;
  animation: fadeIn .7s both ease;
}
/* backSlide */
.owl-backSlide-out {
  -webkit-animation: backSlideOut 1s both ease;
  -moz-animation: backSlideOut 1s both ease;
  animation: backSlideOut 1s both ease;
}
.owl-backSlide-in {
  -webkit-animation: backSlideIn 1s both ease;
  -moz-animation: backSlideIn 1s both ease;
  animation: backSlideIn 1s both ease;
}
/* goDown */
.owl-goDown-out {
  -webkit-animation: scaleToFade .7s ease both;
  -moz-animation: scaleToFade .7s ease both;
  animation: scaleToFade .7s ease both;
}
.owl-goDown-in {
  -webkit-animation: goDown .6s ease both;
  -moz-animation: goDown .6s ease both;
  animation: goDown .6s ease both;
}
/* scaleUp */
.owl-fadeUp-in {
  -webkit-animation: scaleUpFrom .5s ease both;
  -moz-animation: scaleUpFrom .5s ease both;
  animation: scaleUpFrom .5s ease both;
}
.owl-fadeUp-out {
  -webkit-animation: scaleUpTo .5s ease both;
  -moz-animation: scaleUpTo .5s ease both;
  animation: scaleUpTo .5s ease both;
}
/* Keyframes */
/*empty*/
@-webkit-keyframes empty {
  0% {opacity: 1}
}
@-moz-keyframes empty {
  0% {opacity: 1}
}
@keyframes empty {
  0% {opacity: 1}
}
@-webkit-keyframes fadeIn {
  0% { opacity:0; }
  100% { opacity:1; }
}
@-moz-keyframes fadeIn {
  0% { opacity:0; }
  100% { opacity:1; }
}
@keyframes fadeIn {
  0% { opacity:0; }
  100% { opacity:1; }
}
@-webkit-keyframes fadeOut {
  0% { opacity:1; }
  100% { opacity:0; }
}
@-moz-keyframes fadeOut {
  0% { opacity:1; }
  100% { opacity:0; }
}
@keyframes fadeOut {
  0% { opacity:1; }
  100% { opacity:0; }
}
@-webkit-keyframes backSlideOut {
  25% { opacity: .5; -webkit-transform: translateZ(-500px); }
  75% { opacity: .5; -webkit-transform: translateZ(-500px) translateX(-200%); }
  100% { opacity: .5; -webkit-transform: translateZ(-500px) translateX(-200%); }
}
@-moz-keyframes backSlideOut {
  25% { opacity: .5; -moz-transform: translateZ(-500px); }
  75% { opacity: .5; -moz-transform: translateZ(-500px) translateX(-200%); }
  100% { opacity: .5; -moz-transform: translateZ(-500px) translateX(-200%); }
}
@keyframes backSlideOut {
  25% { opacity: .5; transform: translateZ(-500px); }
  75% { opacity: .5; transform: translateZ(-500px) translateX(-200%); }
  100% { opacity: .5; transform: translateZ(-500px) translateX(-200%); }
}
@-webkit-keyframes backSlideIn {
  0%, 25% { opacity: .5; -webkit-transform: translateZ(-500px) translateX(200%); }
  75% { opacity: .5; -webkit-transform: translateZ(-500px); }
  100% { opacity: 1; -webkit-transform: translateZ(0) translateX(0); }
}
@-moz-keyframes backSlideIn {
  0%, 25% { opacity: .5; -moz-transform: translateZ(-500px) translateX(200%); }
  75% { opacity: .5; -moz-transform: translateZ(-500px); }
  100% { opacity: 1; -moz-transform: translateZ(0) translateX(0); }
}
@keyframes backSlideIn {
  0%, 25% { opacity: .5; transform: translateZ(-500px) translateX(200%); }
  75% { opacity: .5; transform: translateZ(-500px); }
  100% { opacity: 1; transform: translateZ(0) translateX(0); }
}
@-webkit-keyframes scaleToFade {
  to { opacity: 0; -webkit-transform: scale(.8); }
}
@-moz-keyframes scaleToFade {
  to { opacity: 0; -moz-transform: scale(.8); }
}
@keyframes scaleToFade {
  to { opacity: 0; transform: scale(.8); }
}
@-webkit-keyframes goDown {
  from { -webkit-transform: translateY(-100%); }
}
@-moz-keyframes goDown {
  from { -moz-transform: translateY(-100%); }
}
@keyframes goDown {
  from { transform: translateY(-100%); }
}

@-webkit-keyframes scaleUpFrom {
  from { opacity: 0; -webkit-transform: scale(1.5); }
}
@-moz-keyframes scaleUpFrom {
  from { opacity: 0; -moz-transform: scale(1.5); }
}
@keyframes scaleUpFrom {
  from { opacity: 0; transform: scale(1.5); }
}

@-webkit-keyframes scaleUpTo {
  to { opacity: 0; -webkit-transform: scale(1.5); }
}
@-moz-keyframes scaleUpTo {
  to { opacity: 0; -moz-transform: scale(1.5); }
}
@keyframes scaleUpTo {
  to { opacity: 0; transform: scale(1.5); }
}

/***********************************************************
 * #Owl Carousel #CUSTOMS
 **********************************************************/
.owl-carousel{
  overflow: hidden;
}
.owl-buttons{
	position: static;
}
.owl-prev, .owl-next{
	opacity: 0;
	display: block;
	position: absolute;
	top: 50%;
	margin-top: -50px;
	width: 105px;
	height: 105px;
	line-height: 105px;
	font-size: 40px;
	text-align: center;
	color: rgba(255,255,255, .7);
  z-index: 6;
	-webkit-transition: all 0.27s cubic-bezier(0.000, 0.000, 0.580, 1.000);  
  -moz-transition: all 0.27s cubic-bezier(0.000, 0.000, 0.580, 1.000); 
  -o-transition: all 0.27s cubic-bezier(0.000, 0.000, 0.580, 1.000);
  -ms-transition: all 0.27s cubic-bezier(0.000, 0.000, 0.580, 1.000); 
  transition: all 0.27s cubic-bezier(0.000, 0.000, 0.580, 1.000);
}
.owl-arrows-bg .owl-prev, .owl-arrows-bg .owl-next{
	margin-top: -35px;
  padding-top:19px;
	width: 40px;
	height: 70px;
  background:#fff;
	line-height: 30px;
	font-size: 30px;
	color: rgba(16,16,16, .7);
  -webkit-box-shadow: 1px 1px 0px rgba(0, 0, 0, .1);
  -moz-box-shadow: 1px 1px 0px rgba(0, 0, 0, .1);
  box-shadow: 1px 1px 0px rgba(0, 0, 0, .1);
}
.owl-white-bg .owl-prev,
.owl-white-bg .owl-next{
  color: rgba(75,78,83, .7);
}
.owl-prev{
	left: -50px;
}
.owl-next{
	right: -50px;
}
.owl-prev .icon,
.owl-next .icon{
  position: relative;
}
.owl-prev:hover,
.owl-next:hover{	
  color:rgba(255,255,255, 1);
}
.owl-prev:hover,
.owl-next:hover{	
  color:rgba(75,78,83, 1);
}
.owl-carousel:hover .owl-prev{
	opacity: 1;
	left: -15px;
}
.owl-arrows-bg.owl-carousel:hover .owl-prev{
	left: 0;
}
.owl-carousel:hover .owl-next{
	opacity: 1;
	right: -15px;
}
.owl-arrows-bg.owl-carousel:hover .owl-next{
	right: 0;
}

@media only screen and (max-width: 992px) {
  .owl-carousel:hover .owl-prev{
    opacity: 1;
    left: -27px;
  }
  .owl-carousel:hover .owl-next{
    opacity: 1;
    right: -27px;
  }
}

.owl-pagination{
  z-index:5;
	display: block;
	width: 100%;
	position: absolute;
	bottom: 0;
	left: 0;
	text-align: center;
}
.owl-pag-2 .owl-pagination{
	bottom: 10px;
}
.owl-page{
	display: inline-block;
	padding: 6px;
  position: relative;
}
.owl-page span{
	width: 6px;
	height: 6px;
	display: block;
	background: rgba(255,255,255, .7);
  position: relative;
  border-radius: 50%;
	
	-webkit-transition: all 0.27s cubic-bezier(0.000, 0.000, 0.580, 1.000);  
  -moz-transition: all 0.27s cubic-bezier(0.000, 0.000, 0.580, 1.000); 
  -o-transition: all 0.27s cubic-bezier(0.000, 0.000, 0.580, 1.000);
  -ms-transition: all 0.27s cubic-bezier(0.000, 0.000, 0.580, 1.000); 
  transition: all 0.27s cubic-bezier(0.000, 0.000, 0.580, 1.000);
}
.owl-page:hover span{
	background: rgba(255,255,255, 1);
}
.owl-page.active span{    
  -webkit-transform: scale(1.5);
  -moz-transform: scale(1.5);
  -o-transform: scale(1.5);
  -ms-transform: scale(1.5);
  transform: scale(1.5);
}
.owl-white-bg .owl-page span{
  background: rgba(75,78,83, .5);
}
.owl-dark-bg .owl-page span{
  background: rgba(255,255,255, 1);
  box-shadow: 0px 1px 0px rgba(0, 0, 0, .1);
}
.item-carousel .owl-prev{
	left: -125px;
}
.item-carousel .owl-next{
	right: -125px;
}
section:hover .item-carousel.owl-carousel .owl-prev{
	opacity: 1;
	left: -80px;
}
section:hover .item-carousel.owl-carousel .owl-next{
	opacity: 1;
	right: -80px;
}
.item-carousel{
  margin-bottom: -50px;
  padding-bottom: 50px;
}
.item-carousel .owl-pagination{
	bottom: 0;
}
.work-full-slider .owl-pagination,
.content-slider .owl-pagination{
	bottom: 15px;
}
.single-carousel,
.small-item-carousel{
  margin-bottom: 35px;
}
.single-carousel .owl-pagination,
.small-item-carousel .owl-pagination{
  position: relative;
  left: 0;
  top: 35px;
  margin: 0;
}
.single-carousel.black .owl-page span,
.small-item-carousel.black .owl-page span{
  background: rgba(0,0,0, .15);
    
  -webkit-box-shadow: none;
	-moz-box-shadow: none;
	box-shadow: none;
}
.single-carousel.black .owl-page:hover span,
.small-item-carousel.black .owl-page:hover span{
  background: rgba(0,0,0, .4);
}
.single-carousel.black .owl-page.active span,
.small-item-carousel.black .owl-page.active span{
	background: rgba(0,0,0, .4);
}
.single-carousel.black .owl-prev,
.single-carousel.black .owl-next,
.small-item-carousel.black .owl-prev,
.small-item-carousel.black .owl-next{
  color: rgba(0,0,0, .2);
  text-shadow: none;
    
  -webkit-box-shadow: none;
	-moz-box-shadow: none;
	box-shadow: none;
}
.single-carousel.black .owl-prev:hover,
.single-carousel.black .owl-next:hover,
.small-item-carousel.black .owl-prev:hover,
.small-item-carousel.black .owl-next:hover{
  color: rgba(0,0,0, .35);
}
.single-carousel .owl-prev,
.small-item-carousel .owl-prev{
	left: -125px;
}
.single-carousel .owl-next,
.small-item-carousel .owl-next{
	right: -125px;
}
section:hover .single-carousel .owl-prev,
section:hover .small-item-carousel .owl-prev{
	opacity: 1;
	left: -80px;
}
section:hover .single-carousel .owl-next,
section:hover .small-item-carousel .owl-next{
	opacity: 1;
	right: -80px;
}
.black-arrows .owl-prev,
.black-arrows .owl-next{
  color: #fff;
}
.black-arrows .owl-prev:before,
.black-arrows .owl-next:before{
  background: rgba(0,0,0, 1);
}

/************************************************************
*	#Magnific Popup CSS 
************************************************************/
.mfp-bg {
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1042;
  overflow: hidden;
  position: fixed;
  background: #0b0b0b;
  opacity: .8;
  filter: alpha(opacity=80); }

.mfp-wrap {
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1043;
  position: fixed;
  outline: none !important;
  -webkit-backface-visibility: hidden; }

.mfp-container {
  text-align: center;
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  padding: 0 8px;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box; }

.mfp-container:before {
  content: '';
  display: inline-block;
  height: 100%;
  vertical-align: middle; }

.mfp-align-top .mfp-container:before {
  display: none; }

.mfp-content {
  position: relative;
  display: inline-block;
  vertical-align: middle;
  margin: 0 auto;
  text-align: left;
  z-index: 1045; }

.mfp-inline-holder .mfp-content, .mfp-ajax-holder .mfp-content {
  width: 100%;
  cursor: auto; }

.mfp-ajax-cur {
  cursor: progress; }

.mfp-zoom {
  cursor: pointer;
  cursor: -webkit-zoom-in;
  cursor: -moz-zoom-in;
  cursor: zoom-in; }

.mfp-auto-cursor .mfp-content {
  cursor: auto; }

.mfp-close, .mfp-arrow, .mfp-preloader, .mfp-counter {
  -webkit-user-select: none;
  -moz-user-select: none;
  user-select: none; }

.mfp-loading.mfp-figure {
  display: none; }

.mfp-hide {
  display: none !important; }

.mfp-preloader {
  color: #cccccc;
  position: absolute;
  top: 50%;
  width: auto;
  text-align: center;
  margin-top: -.8em;
  left: 8px;
  right: 8px;
  z-index: 1044; }
  .mfp-preloader a {
    color: #cccccc; }
    .mfp-preloader a:hover {
      color: white; }

.mfp-s-ready .mfp-preloader {
  display: none; }

.mfp-s-error .mfp-content {
  display: none; }

button.mfp-close, button.mfp-arrow {
  overflow: visible;
  cursor: pointer;
  background: transparent;
  border: 0;
  -webkit-appearance: none;
  display: block;
  outline: none;
  padding: 0;
  z-index: 1046;
  -webkit-box-shadow: none;
  box-shadow: none; }
button::-moz-focus-inner {
  padding: 0;
  border: 0; }

.mfp-close {
  width: 44px;
  height: 44px;
  line-height: 44px;
  position: absolute;
  right: 0;
  top: 0;
  text-decoration: none;
  text-align: center;
  opacity: .65;
  padding: 0 0 18px 10px;
  color: white;
  font-style: normal;
  font-size: 28px;
  font-family: Arial, Baskerville, monospace; }
  .mfp-close:hover, .mfp-close:focus {
    opacity: 1; }
  .mfp-close:active {
    top: 1px; }

.mfp-close-btn-in .mfp-close {
  color: #333333; }

.mfp-image-holder .mfp-close, .mfp-iframe-holder .mfp-close {
  color: white;
  right: -6px;
  text-align: right;
  padding-right: 6px;
  width: 100%; }

.mfp-counter {
  position: absolute;
  top: 0;
  right: 0;
  color: #cccccc;
  font-size: 14px;
  line-height: 18px; }

.mfp-arrow {
  position: absolute;
  opacity: .65;
  margin: 0;
  top: 50%;
  margin-top: -55px;
  padding: 0;
  width: 90px;
  height: 110px;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0); }
  .mfp-arrow:active {
    margin-top: -54px; }
  .mfp-arrow:hover, .mfp-arrow:focus {
    opacity: 1; }
  .mfp-arrow:before, .mfp-arrow:after, .mfp-arrow .mfp-b, .mfp-arrow .mfp-a {
    content: '';
    display: block;
    width: 0;
    height: 0;
    position: absolute;
    left: 0;
    top: 0;
    margin-top: 35px;
    margin-left: 35px;
    border: medium inset transparent; }
  .mfp-arrow:after, .mfp-arrow .mfp-a {
    border-top-width: 13px;
    border-bottom-width: 13px;
    top: 8px; }
  .mfp-arrow:before, .mfp-arrow .mfp-b {
    border-top-width: 21px;
    border-bottom-width: 21px; }

.mfp-arrow-left {
  left: 0; }
  .mfp-arrow-left:after, .mfp-arrow-left .mfp-a {
    border-right: 17px solid white;
    margin-left: 31px; }
  .mfp-arrow-left:before, .mfp-arrow-left .mfp-b {
    margin-left: 25px;
    border-right: 27px solid #3f3f3f; }

.mfp-arrow-right {
  right: 0; }
  .mfp-arrow-right:after, .mfp-arrow-right .mfp-a {
    border-left: 17px solid white;
    margin-left: 39px; }
  .mfp-arrow-right:before, .mfp-arrow-right .mfp-b {
    border-left: 27px solid #3f3f3f; }

.mfp-iframe-holder {
  padding-top: 40px;
  padding-bottom: 40px; }
  .mfp-iframe-holder .mfp-content {
    line-height: 0;
    width: 100%;
    max-width: 900px; }
  .mfp-iframe-holder .mfp-close {
    top: -40px; }

.mfp-iframe-scaler {
  width: 100%;
  height: 0;
  overflow: hidden;
  padding-top: 56.25%; }
  .mfp-iframe-scaler iframe {
    position: absolute;
    display: block;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    box-shadow: 0 0 8px rgba(0, 0, 0, .6);
    background: black; }

/* Main image in popup */
img.mfp-img {
  width: auto;
  max-width: 100%;
  height: auto;
  display: block;
  line-height: 0;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  padding: 40px 0 40px;
  margin: 0 auto; }

/* The shadow behind the image */
.mfp-figure {
  line-height: 0; }
  .mfp-figure:after {
    content: '';
    position: absolute;
    left: 0;
    top: 40px;
    bottom: 40px;
    display: block;
    right: 0;
    width: auto;
    height: auto;
    z-index: -1;
    box-shadow: 0 0 8px rgba(0, 0, 0, .6);
    background: #444444; }
  .mfp-figure small {
    color: #bdbdbd;
    display: block;
    font-size: 14px;
    line-height: 14px; }
  .mfp-figure figure {
    margin: 0; }

.mfp-bottom-bar {
  margin-top: -36px;
  position: absolute;
  top: 100%;
  left: 0;
  width: 100%;
  cursor: auto; }

.mfp-title {
  text-align: left;
  line-height: 18px;
  color: #f3f3f3;
  word-wrap: break-word;
  padding-right: 36px; }

.mfp-image-holder .mfp-content {
  max-width: 100%; }

.mfp-gallery .mfp-image-holder .mfp-figure {
  cursor: pointer; }

@media screen and (max-width: 800px) and (orientation: landscape), screen and (max-height: 300px) {
  /**
       * Remove all paddings around the image on small screen
       */
  .mfp-img-mobile .mfp-image-holder {
    padding-left: 0;
    padding-right: 0; }
  .mfp-img-mobile img.mfp-img {
    padding: 0; }
  .mfp-img-mobile .mfp-figure {
    /* The shadow behind the image */ }
    .mfp-img-mobile .mfp-figure:after {
      top: 0;
      bottom: 0; }
    .mfp-img-mobile .mfp-figure small {
      display: inline;
      margin-left: 5px; }
  .mfp-img-mobile .mfp-bottom-bar {
    background: rgba(0, 0, 0, .6);
    bottom: 0;
    margin: 0;
    top: auto;
    padding: 3px 5px;
    position: fixed;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box; }
    .mfp-img-mobile .mfp-bottom-bar:empty {
      padding: 0; }
  .mfp-img-mobile .mfp-counter {
    right: 5px;
    top: 3px; }
  .mfp-img-mobile .mfp-close {
    top: 0;
    right: 0;
    width: 35px;
    height: 35px;
    line-height: 35px;
    background: rgba(0, 0, 0, .6);
    position: fixed;
    text-align: center;
    padding: 0; } }

@media all and (max-width: 900px) {
  .mfp-arrow {
    -webkit-transform: scale(.75);
    transform: scale(.75); }
  .mfp-arrow-left {
    -webkit-transform-origin: 0;
    transform-origin: 0; }
  .mfp-arrow-right {
    -webkit-transform-origin: 100%;
    transform-origin: 100%; }
  .mfp-container {
    padding-left: 6px;
    padding-right: 6px; } }

.mfp-ie7 .mfp-img {
  padding: 0; }
.mfp-ie7 .mfp-bottom-bar {
  width: 600px;
  left: 50%;
  margin-left: -300px;
  margin-top: 5px;
  padding-bottom: 5px; }
.mfp-ie7 .mfp-container {
  padding: 0; }
.mfp-ie7 .mfp-content {
  padding-top: 44px; }
.mfp-ie7 .mfp-close {
  top: 0;
  right: 0;
  padding-top: 0; }
  
/******************************************************************
#ANIMATION
*******************************************************************/

/* text-based popup styling */
.white-popup {
  position: relative;
  background: #FFF;
  padding: 25px;
  width: auto;
  max-width: 400px;
  margin: 0 auto;
}

/* 
====== Zoom effect ======
*/
.mfp-zoom-in .mfp-with-anim {
  opacity: 0;
  -webkit-transition: all .2s ease-in-out;
  -moz-transition: all .2s ease-in-out;
  -ms-transition: all .2s ease-in-out;
  -o-transition: all .2s ease-in-out;
  transition: all .2s ease-in-out;
  -webkit-transform: scale(.8);
  -moz-transform: scale(.8);
  -ms-transform: scale(.8);
  -o-transform: scale(.8); 
  transform: scale(.8);
}
.mfp-zoom-in.mfp-bg {
  opacity: 0;
  -webkit-transition: all .3s ease-out;
  -moz-transition: all .3s ease-out;
  -ms-transition: all .3s ease-out;
  -o-transition: all .3s ease-out;
  transition: all .3s ease-out;
}
.mfp-zoom-in.mfp-ready .mfp-with-anim {
  opacity: 1;
  -webkit-transform: scale(1);
  -moz-transform: scale(1);
  -ms-transform: scale(1);
  -o-transform: scale(1);
  transform: scale(1);
}
.mfp-zoom-in.mfp-ready.mfp-bg {
  opacity: .8;
}
.mfp-zoom-in.mfp-removing .mfp-with-anim {
  -webkit-transform: scale(.8);
  -moz-transform: scale(.8);
  -ms-transform: scale(.8);
  -o-transform: scale(.8); 
  transform: scale(.8);
  opacity: 0;
}
.mfp-zoom-in.mfp-removing.mfp-bg {
  opacity: 0;
}

/* 
====== Newspaper effect ======
*/
.mfp-newspaper .mfp-with-anim {
  opacity: 0;
  -webkit-transition: all .5s;
  -moz-transition: all .5s;
  transition: all .5s;
  -webkit-transform: scale(0) rotate(500deg);
  -moz-transform: scale(0) rotate(500deg);
  -ms-transform: scale(0) rotate(500deg);
  -o-transform: scale(0) rotate(500deg);
  transform: scale(0) rotate(500deg);
}
.mfp-newspaper.mfp-bg {
  opacity: 0;
  -webkit-transition: all .5s;
  -moz-transition: all .5s;
  transition: all .5s;
}
.mfp-newspaper.mfp-ready .mfp-with-anim {
  opacity: 1;
  
  -webkit-transform: scale(1) rotate(0deg);
  -moz-transform: scale(1) rotate(0deg);
  -ms-transform: scale(1) rotate(0deg);
  -o-transform: scale(1) rotate(0deg);
  transform: scale(1) rotate(0deg);
}
.mfp-newspaper.mfp-ready.mfp-bg {
  opacity: .8;
}
.mfp-newspaper.mfp-removing .mfp-with-anim {
  -webkit-transform: scale(0) rotate(500deg);
  -moz-transform: scale(0) rotate(500deg);
  -ms-transform: scale(0) rotate(500deg);
  -o-transform: scale(0) rotate(500deg);
  transform: scale(0) rotate(500deg);
  opacity: 0;
}
.mfp-newspaper.mfp-removing.mfp-bg {
  opacity: 0;
}

/* 
====== Move-horizontal effect ======
*/
.mfp-move-horizontal .mfp-with-anim {
  opacity: 0;
  transition: all .3s;
  transform: translateX(-50px);
}
.mfp-move-horizontal.mfp-bg {
  opacity: 0;
  transition: all .3s;
}
.mfp-move-horizontal.mfp-ready .mfp-with-anim {
  opacity: 1;
  transform: translateX(0);
}
.mfp-move-horizontal.mfp-ready.mfp-bg {
  opacity: .8;
}
.mfp-move-horizontal.mfp-removing .mfp-with-anim {
  transform: translateX(50px);
  opacity: 0;
}
.mfp-move-horizontal.mfp-removing.mfp-bg {
  opacity: 0;
}

/* 
====== Move-from-top effect ======
*/
.mfp-move-from-top .mfp-content {
  vertical-align: top;
}
.mfp-move-from-top .mfp-with-anim {
  opacity: 0;
  transition: all .2s;
  transform: translateY(-100px);
}
.mfp-move-from-top.mfp-bg {
  opacity: 0;
  transition: all .2s;
}
.mfp-move-from-top.mfp-ready .mfp-with-anim {
  opacity: 1;
  transform: translateY(0);
}
.mfp-move-from-top.mfp-ready.mfp-bg {
  opacity: .8;
}
.mfp-move-from-top.mfp-removing .mfp-with-anim {
  transform: translateY(-50px);
  opacity: 0;
}
.mfp-move-from-top.mfp-removing.mfp-bg {
  opacity: 0;
}

/* 
====== 3d unfold ======
*/
.mfp-3d-unfold .mfp-content {
  perspective: 2000px;
  -webkit-perspective: 2000px;
}
.mfp-3d-unfold .mfp-with-anim {
  opacity: 0;
  -webkit-transition: all .3s ease-in-out;
  -moz-transition: all .3s ease-in-out;
  transition: all .3s ease-in-out;
  -webkit-transform-style: preserve-3d;
  transform-style: preserve-3d;
  -webkit-transform: rotateY(-60deg);
  -moz-transform: rotateY(-60deg);
  -ms-transform: rotateY(-60deg);
  -o-transform: rotateY(-60deg);
  transform: rotateY(-60deg);
}
.mfp-3d-unfold.mfp-bg {
  opacity: 0;
  transition: all .5s;
}
.mfp-3d-unfold.mfp-ready .mfp-with-anim {
  opacity: 1;
  -webkit-transform: rotateY(0deg);
  -moz-transform: rotateY(0deg);
  -ms-transform: rotateY(0deg);
  -o-transform: rotateY(0deg);
  transform: rotateY(0deg);
  z-index:9999;
}
.mfp-3d-unfold.mfp-ready.mfp-bg {
  opacity: .8;
}
.mfp-3d-unfold.mfp-removing .mfp-with-anim {
  -webkit-transform: rotateY(60deg);
  -moz-transform: rotateY(60deg);
  -ms-transform: rotateY(60deg);
  -o-transform: rotateY(60deg);
  transform: rotateY(60deg);
  opacity: 0;
}
.mfp-3d-unfold.mfp-removing.mfp-bg {
  opacity: 0;
}
.modal-dialog {
  z-index: 1090;
}

/* 
====== "Hinge" close effect ======
*/
@keyframes hinge {
  0% {
    transform: rotate(0);
    transform-origin: top left;
    animation-timing-function: ease-in-out;
  }

  20%, 60% {
    transform: rotate(80deg);
    transform-origin: top left;
    animation-timing-function: ease-in-out;
  }

  40% {
    transform: rotate(60deg);
    transform-origin: top left;
    animation-timing-function: ease-in-out;
  }

  80% {
    transform: rotate(60deg) translateY(0);
    opacity: 1;
    transform-origin: top left;
    animation-timing-function: ease-in-out;
  }

  100% {
    transform: translateY(700px);
    opacity: 0;
  }
}
/*	MAGNIFIC END	****************************************/


/* #INDEX-PORTFOLOLIO 2 NEW 	****************************************/
@media (min-width: 1025px) {
  #header-left {
    position:relative;
    margin-bottom: 0;
  }
  #header-left .main-menu-container {
    position:relative;
  }
  #header-left ul li {
    float:none;
  }

  /***** MENU INDEX-PORTFOLOLIO 2 NEW *****/
  #header-left .main-menu-container .container-m-30 {
    padding: 0 0 0 20px;
  }
  #header-left .logo-row {
    width: auto;
  }
  #header-left .logo-2 {
    text-align:left;
    margin-top:40px;
    margin-bottom:20px;
  }
  .hl-search {
    margin:60px 30px 0 20px;
    width:auto;
  }
  .hl-search  input{
    border-bottom: 1px solid #eee;
  }
  .hl-search button {
    color:#d3d3d3;
  }
  .hl-search button:hover {
    color:#101010;
  }
  .hl-search-input::-webkit-input-placeholder {color: #7e8082; letter-spacing:2px; font-size:13px;}
  .hl-search-input::-moz-placeholder  {color:#7e8082; letter-spacing:2px; font-size:13px;}/* Firefox 19+*/
  .hl-search-input:-moz-placeholder {color:#7e8082; letter-spacing:2px; font-size:13px;}/* Firefox 18- */
  .hl-search-input:-ms-input-placeholder {color:#7e8082; letter-spacing:2px; font-size:13px;}
  #header-left #main-menu {
    margin: 0;
    text-align: left;
  }
  #header-left.header .navbar {
    margin: 0;
    min-height: inherit;
    position: static;
  }
  #header-left #main-menu .navbar-collapse.collapse.in {
    display: block !important;
  }
  #header-left #main-menu .navbar .nav {
    padding-top: 10px;
  }
  #header-left .navbar-nav {
    float: none;
  }
  #header-left .navbar-nav>li {
    float: none;
  }
  #header-left #main-menu .navbar .nav > li.current > a {
    margin: 0;
    color: #101010 !important;
  }
  #header-left #main-menu .navbar .nav > li > a {
    font-weight: normal;
    margin: 0 !important;
    padding: 16px 9px 16px 17px !important;
    text-decoration: none;
    width: 100%;
    text-align: left;
    color: #7e8082 ;
    border: none !important;
  }
  #header-left #main-menu .navbar .nav > li:hover > a {
    color: #111;
  }
  #header-left #main-menu .navbar .nav li a {
    font-size: 13px;
  }
  #header-left #main-menu .navbar .nav .open-sub {
    position: absolute;
    bottom: 0;
    left: 0;
    top: 0;
    width: 100%;
  }
  #header-left #main-menu .sub, #header-left #main-menu .parent .sub, #header-left #main-menu .parent:hover .sub {
    display: none;
    position: relative;
    margin: 0;
    padding: 0;
    background: #3e4045;
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
    box-shadow: none;
  }
  #header-left #main-menu .sub li {
    line-height: 22px;
  }
  #header-left #main-menu .sub a.current {
    color: #fff;
  }
  #header-left #main-menu .sub .sub {
    left: 0;
    margin-left: 25px;
    padding-top: 0px !important;
    padding-bottom: 0px !important;
  }
  #header-left #main-menu .sub, #header-left #main-menu .parent .sub, #header-left #main-menu .parent:hover .sub {
    display: none;
    position: relative;
    margin: 0;
    padding: 0;
    background: #3e4045;
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
    box-shadow: none;
  }
  #header-left #main-menu .sub li {
    line-height: 22px;
  }
  #header-left #main-menu .sub .sub a {
    padding: 15px 9px 15px 37px !important;
  }
  #header-left #main-menu .sub a.current {
    color: #fff;
  }
  #header-left #main-menu .megamenu > .sub {
    width: 100%;
  }
  #header-left #main-menu .megamenu > .sub .box {
    padding: 0;
  }
  #header-left .nofloat.col-md-3 {
    float: none;
    width: auto;
  }
  #header-left #main-menu .megamenu .title {
    cursor: pointer;
    font-weight: normal;
    line-height: 22px;
    margin: 0;
    padding: 15px 9px 15px 27px !important;
    position: relative;
    text-transform: none;
    font-family: 'Open Sans', Arial, Helvetica, sans-serif;
    font-size: 13px;
    color: #d3d3d3;
  }
  #header-left #main-menu .sub a {
    padding: 15px 9px 15px 27px !important;
  }
  #header-left #main-menu .megamenu > .sub .box.closed ul {
    display: none;
    background: #323538;
    padding-left: 10px;
  }
  #header-left .menu-contact-info {
    margin: 0;
  }
  #header-left .menu-contact-info .contact-list {
    padding-top: 30px;
    padding-bottom: 30px;
  }
  #header-left .menu-map-container {
    margin-left: -20px;
    margin-bottom: -5px;
  }
  #header-left #main-menu .navbar-collapse.collapse {
    display: none !important;
  }
  #header-left .right-1024 {
    float: none;
  }
  #header-left .main-menu-container {
    position:relative;
  }
  #header-left.header-1 #main-menu .nav.navbar-nav {
    margin-right: 0;
  }
  #header-left #main-menu .navbar .nav .open-sub:before {
    content: "\e232";
    font-size: 20px;
    font-family: "linea-icons" !important;
    color: #d3d3d3;
    padding-top: 15px;
    right: 14px;
    position: absolute;
    font-style: normal !important;
    font-weight: normal !important;
    font-variant: normal !important;
    text-transform: none !important;
    speak: none;
    line-height: 1;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }
  #header-left #main-menu .navbar .nav .parent .parent > a:after {
    display:none;
  }
  #header-left #main-menu .navbar .nav > .parent.active > a, #header-left #main-menu .navbar .nav > .parent.active:hover > a {
    background: transparent;
  }
  #header-left #main-menu .parent:hover > .sub {
    animation:none;
  }
  #header-left #main-menu .sub, #header-left #main-menu .parent .sub, #header-left #main-menu .parent:hover .sub {
    background: #3e4045;
  }
  #header-left #main-menu .parent .sub .sub, #header-left #main-menu .parent:hover .sub .sub {
    background: #323538;
  }
  #header-left #main-menu .navbar .nav .active .sub .active .open-sub:before {
    content: "\e200";
  }
  #header-left #main-menu .navbar .nav .active .sub .open-sub:before {
    content: "\e232";
  }
  #header-left #main-menu .navbar .nav .active .open-sub:before {
    content: "\e200";
  }

  /***** End menu *****/
  .side-header {
    position: fixed;
    top: 0;
    left: 0;
    width: 280px;
    height: 100%;
    padding-bottom: 60px;
    overflow-y: auto;
    background: #fff;
    text-align: center;
    z-index: 1045;
    box-sizing: border-box;
  }
  .side-content {
    margin-left: 280px;
  }

}

/* #TRAVEL NEW ****************************************/
.tab-content-opacity {
  background:rgba(255, 255, 255, .7);
}
.tab-nav-opacity  {
  border-color:rgba(255, 255, 255, .9);
}
.bg-opacity  {
  padding:30px;
  background:rgba(255, 255, 255, .7);
}
.tab-nav-opacity  li.active>a{
  border-color:rgba(255, 255, 255, .9) !important;
  background-color:rgba(255, 255, 255, .9);
}
.tab-nav-opacity>li>a:hover {
  border-color: #eee #eee rgba(255, 255, 255, .9);
}
.tabs-3 .tab-nav-opacity>li>a {
  font-weight: 600;
}
.tab-content-border {
  border:1px solid #ddd;
  border-top:none;
  padding:30px 15px;
}
.travel-form label{
  display:block;
}
.display-block {
  display:block;
}
.input-group-addon2 {
  background:none;
  border:none;
}
.travel-carousel .owl-wrapper-outer {
  padding-top:105px;
}

/* #SIDE MENU NEW *******************************************/
.cd-img-replace {
  /* replace text with a background-image */
  display: inline-block;
  overflow: hidden;
  text-indent: 100%;
  white-space: nowrap;
}

#nav-stick {
  transition: all .4s ease;
  width: 100%;
}
.sliding-content {
  /* set a min-height and a z-index to be sure that the main element completely covers the lateral menu */
  min-height: 100%;
  position: relative;
  background-color: #fff;
  z-index: 2;
  /* Force Hardware Acceleration in WebKit */
  -webkit-transform: translateZ(0);
  -webkit-backface-visibility: hidden;
  -webkit-transition-property: -webkit-transform;
  -moz-transition-property: -moz-transform;
  transition-property: transform;
  -webkit-transition-duration: 0.4s;
  -moz-transition-duration: 0.4s;
  transition-duration: 0.4s;
}
.sliding-content.lateral-menu-is-open {
  /* translate to show the lateral menu - all content needs to be put in the .cd-main-content to translate*/
  -webkit-transform: translateX(-260px);
  -moz-transform: translateX(-260px);
  -ms-transform: translateX(-260px);
  -o-transform: translateX(-260px);
  transform: translateX(-260px);
}
header.lateral-menu-is-open {
  /* translate to show the lateral menu */
  -webkit-transform: translateX(-260px);
  -moz-transform: translateX(-260px);
  -ms-transform: translateX(-260px);
  -o-transform: translateX(-260px);
  transform: translateX(-260px);
}
header.is-fixed {
  position: fixed;
}
#cd-logo {
  display: block;
  float: left;
  margin: 12px 0 0 20px;
}
#cd-logo img {
  display: block;
}
@media only screen and (min-width: 768px) {
  #cd-logo {
    margin: 22px 0 0 30px;
  }
}
#cd-top-nav {
  position: absolute;
  top: 0;
  right: 120px;
  height: 100%;
  display: none;
}
#cd-top-nav ul {
  height: 100%;
  padding-top: 18px;
}
#cd-top-nav li {
  display: inline-block;
  margin-right: 1em;
}
#cd-top-nav a {
  display: inline-block;
  padding: .5em;
  color: #FFF;
  text-transform: uppercase;
  font-weight: 600;
}
#cd-top-nav a.current {
  background-color: #242e30;
}
.no-touch #cd-top-nav a:hover {
  color: rgba(255, 255, 255, 0.7);
}
@media only screen and (min-width: 768px) {
  #cd-top-nav {
    display: block;
  }
}
#cd-menu-trigger {
  position: absolute;
  right: 45px;
  top: 0;
  height: 100%;
  width: 50px;
}

@media only screen and (max-width: 1024px){
  #cd-menu-trigger {
    right: 25px;
  }
} 
#cd-menu-trigger .cd-menu-icon {
  /* this span is the central line in the menu menu */
  display: inline-block;
  position: absolute;
  top: 50%;
  left: auto;
  right: 0;
  bottom: auto;
  width: 24px;
  height: 2px;
  background-color: #4b4e53;
}
#cd-menu-trigger .cd-menu-icon::before, #cd-menu-trigger .cd-menu-icon:after {
  content: '';
  width: 100%;
  height: 100%;
  position: absolute;
  background-color: inherit;
  left: 0;
}
#cd-menu-trigger .cd-menu-icon::before {
  bottom: 7px;
}
#cd-menu-trigger .cd-menu-icon::after {
  top: 7px;
}
#cd-menu-trigger.is-clicked .cd-menu-icon {
  background-color: rgba(255, 255, 255, 0);
}
#cd-menu-trigger .cd-menu-icon, #cd-menu-trigger .cd-menu-icon::before, #cd-menu-trigger .cd-menu-icon::after {
  transition: all .3s cubic-bezier(0.300, 0.100, 0.580, 1.000);
}
#cd-menu-trigger.is-clicked .cd-menu-icon::before, #cd-menu-trigger.is-clicked .cd-menu-icon::after {
  background-color: #4b4e53;
  transition: all .3s cubic-bezier(0.300, 0.100, 0.580, 1.000);
}
#cd-menu-trigger.is-clicked .cd-menu-icon::before {
  bottom: 0;
  -webkit-transform: rotate(45deg);
  -moz-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  -o-transform: rotate(45deg);
  transform: rotate(45deg);
}
#cd-menu-trigger.is-clicked .cd-menu-icon::after {
  top: 0;
  -webkit-transform: rotate(-45deg);
  -moz-transform: rotate(-45deg);
  -ms-transform: rotate(-45deg);
  -o-transform: rotate(-45deg);
  transform: rotate(-45deg);
}
#cd-lateral-nav {
  position: fixed;
  height: 100%;
  right: 0;
  top: 0;
  visibility: hidden;
  padding-top:10px;
  /* the secondary navigation is covered by the main element */
  z-index: 1;
  width: 260px;
  background-color: #2a2b2f;
  overflow-y: auto;
  /* Force Hardware Acceleration in WebKit */
  -webkit-transform: translateZ(0);
  -webkit-backface-visibility: hidden;
  -webkit-transition: -webkit-transform .4s 0s, visibility 0s .4s;
  -moz-transition: -moz-transform .4s 0s, visibility 0s .4s;
  transition: transform .4s 0s, visibility 0s .4s;
  /* this creates the subtle slide in animation of the navigation */
  -webkit-transform: translateX(80px);
  -moz-transform: translateX(80px);
  -ms-transform: translateX(80px);
  -o-transform: translateX(80px);
  transform: translateX(80px);
}
#cd-lateral-nav .cd-navigation {
  margin: 10px 0 16px;
}
#cd-lateral-nav .sub-menu {
  padding: 0 10px 20px 15px;
  display: none;
}
#cd-lateral-nav a {
  display: block;
  line-height: 38px;
  letter-spacing:1px;
  padding: 0 16px 0 32px;
  color: #aab5b7;
}
#cd-lateral-nav a.current {
  background-color: #484A51;
  color: #FFF;
}
.no-touch #cd-lateral-nav a:hover {
  color: #FFF;
}
@media only screen and (min-width: 768px) {
  #cd-lateral-nav .cd-navigation {
    margin: 20px 0;
  }
}
#cd-lateral-nav.lateral-menu-is-open {
  -webkit-transform: translateX(0);
  -moz-transform: translateX(0);
  -ms-transform: translateX(0);
  -o-transform: translateX(0);
  transform: translateX(0);
  visibility: visible;
  -webkit-transition: -webkit-transform .4s 0s, visibility 0s 0s;
  -moz-transition: -moz-transform .4s 0s, visibility 0s 0s;
  transition: transform .4s 0s, visibility 0s 0s;
  /* smooth the scrolling on touch devices - webkit browsers */
  -webkit-overflow-scrolling: touch;
}
/* style menu items which have a submenu  */
#cd-lateral-nav .item-has-children > a {
  position: relative;
  text-transform: uppercase;
  font-weight: 600;
  /* this is the right arrow to show that the item has a submenu  */
}
#cd-lateral-nav .item-has-children > a::after {
  content: '';
  display: block;
  position: absolute;
  top: 50%;
  bottom: auto;
  -webkit-transform: translateY(-50%);
  -moz-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  -o-transform: translateY(-50%);
  transform: translateY(-50%);
  right: 1em;
  
  content: "\e232";
  font-family: linea-icons;
  font-style: normal;
  font-weight: normal;
  text-transform: none;
  speak: none;
  font-size: 16px;
  display: block;
  line-height: 1;
  position: absolute;
  right: 25px;
  top: 14px;
  -webkit-transition-property: -webkit-transform;
  -moz-transition-property: -moz-transform;
  transition-property: transform;
  -webkit-transition-duration: 0.2s;
  -moz-transition-duration: 0.2s;
  transition-duration: 0.2s;
}
#cd-lateral-nav .item-has-children > a.submenu-open::after {
  -webkit-transform: translateY(-50%) rotate(90deg);
  -moz-transform: translateY(-50%) rotate(90deg);
  -ms-transform: translateY(-50%) rotate(90deg);
  -o-transform: translateY(-50%) rotate(90deg);
  transform: translateY(-50%) rotate(90deg);
}
#cd-lateral-nav .socials {
  padding: 0 32px;
}
#cd-lateral-nav .socials:after {
  content: "";
  display: table;
  clear: both;
}
#cd-lateral-nav .socials a {
  display:inline-block;
  padding: 9px;
}

/* #FULL SCREEN MENU NEW ***********************************************/
.fs-header {
  position: absolute;
  top: 0;
  left: 0;
  background: rgba(2, 23, 37, 0.95);
  height: 50px;
  width: 100%;
  z-index: 3;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.3);
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
} 
.fs-primary-nav-trigger {
  position: absolute;
  right: 0;
  top: 0;
  height: 100%;
  width: 50px;
}
.fs-primary-nav-trigger .fs-menu-icon {
  /* this span is the central line of the menu icon */
  display: inline-block;
  position: absolute;
  left: 50%;
  top: 50%;
  bottom: auto;
  right: auto;
  -webkit-transform: translateX(-100%) translateY(-50%);
  -moz-transform: translateX(-100%) translateY(-50%);
  -ms-transform: translateX(-100%) translateY(-50%);
  -o-transform: translateX(-100%) translateY(-50%);
  transform: translateX(-100%) translateY(-50%);
  width: 24px;
  height: 2px;
  background-color: #4b4e53;
  -webkit-transition: background-color 0.3s;
  -moz-transition: background-color 0.3s;
  transition: background-color 0.3s;
  /* these are the upper and lower lines in the menu icon */
}
.black-header .fs-primary-nav-trigger .fs-menu-icon {
  background-color: #fff;
}
.fs-primary-nav-trigger .fs-menu-icon::before, .fs-primary-nav-trigger .fs-menu-icon:after {
  content: '';
  width: 100%;
  height: 100%;
  position: absolute;
  background-color: #4b4e53;
  right: 0;
  -webkit-transition: -webkit-transform .3s, top .3s, background-color 0s;
  -moz-transition: -moz-transform .3s, top .3s, background-color 0s;
  transition: transform .3s, top .3s, background-color 0s;
}
.black-header .fs-primary-nav-trigger .fs-menu-icon::before, .black-header .fs-primary-nav-trigger .fs-menu-icon:after {
  background-color: #fff;
}
.fs-primary-nav-trigger .fs-menu-icon::before {
  top: -7px;
}
.fs-primary-nav-trigger .fs-menu-icon::after {
  top: 7px;
}
.fs-primary-nav-trigger .fs-menu-icon.is-clicked {
  background-color: rgba(255, 255, 255, 0);
}
.black-header .fs-primary-nav-trigger .fs-menu-icon.is-clicked {
  background-color: rgba(255, 255, 255, 0);
}
.fs-primary-nav-trigger .fs-menu-icon.is-clicked::before, .fs-primary-nav-trigger .fs-menu-icon.is-clicked::after {
  background-color: #4b4e53;
}
.black-header .fs-primary-nav-trigger .fs-menu-icon.is-clicked::before, .black-header .fs-primary-nav-trigger .fs-menu-icon.is-clicked::after {
  background-color: #fff;
}
.fs-primary-nav-trigger .fs-menu-icon.is-clicked::before {
  top: 0;
  -webkit-transform: rotate(135deg);
  -moz-transform: rotate(135deg);
  -ms-transform: rotate(135deg);
  -o-transform: rotate(135deg);
  transform: rotate(135deg);
}
.fs-primary-nav-trigger .fs-menu-icon.is-clicked::after {
  top: 0;
  -webkit-transform: rotate(225deg);
  -moz-transform: rotate(225deg);
  -ms-transform: rotate(225deg);
  -o-transform: rotate(225deg);
  transform: rotate(225deg);
}
@media only screen and (min-width: 768px) {
  .fs-primary-nav-trigger {
    width: 100px;
    padding-left: 1em;
    background-color: transparent;
    height: 30px;
    line-height: 30px;
    right: 15px;
    top: 50%;
    bottom: auto;
    -webkit-transform: translateY(-50%);
    -moz-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    -o-transform: translateY(-50%);
    transform: translateY(-50%);
  }
  .fs-primary-nav-trigger .fs-menu-icon {
    left: auto;
    right: 1em;
    -webkit-transform: translateX(0) translateY(-50%);
    -moz-transform: translateX(0) translateY(-50%);
    -ms-transform: translateX(0) translateY(-50%);
    -o-transform: translateX(0) translateY(-50%);
    transform: translateX(0) translateY(-50%);
  }
}

.fs-primary-nav {
  /* by default it's hidden - on top of the viewport */
  position: fixed;
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
  background: rgba(200, 200, 200, 0.97);
  z-index: 26;
  text-align: center;
  padding: 50px 0;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  /* overflow: auto; */
  overflow-x:hidden;
  overflow-y: auto;
  /* this fixes the buggy scrolling on webkit browsers - mobile devices only - when overflow property is applied */
  -webkit-overflow-scrolling: touch;
  -webkit-transform: translateY(-100%);
  -moz-transform: translateY(-100%);
  -ms-transform: translateY(-100%);
  -o-transform: translateY(-100%);
  transform: translateY(-100%);
  -webkit-transition-property: -webkit-transform;
  -moz-transition-property: -moz-transform;
  transition-property: transform;
  -webkit-transition-duration: 0.4s;
  -moz-transition-duration: 0.4s;
  transition-duration: 0.4s;
}
.fs-primary-nav.fs-nav-black {
  background: rgba(41, 41, 41, 0.96);
}
.fs-primary-nav>ul{
  width:100%;
}
.fs-primary-nav li {
  font-size: 28px;
  font-weight: 300;
  letter-spacing:2px;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  margin: .2em 0;
}
.fs-primary-nav a {
  display: inline-block;
  padding:.4em 1em;
}
.fs-primary-nav .fs-label {
  color: #848484 !important;
  text-transform: uppercase !important;
  font-weight: 700 !important;
  font-size: 14px !important;
  font-size: 0.875rem !important;
  margin: 2.4em 0 .8em !important;
}
.fs-primary-nav.fs-nav-black .fs-label {
  color: #848484 !important;
}
.fs-primary-nav.is-visible {
  -webkit-transform: translateY(0);
  -moz-transform: translateY(0);
  -ms-transform: translateY(0);
  -o-transform: translateY(0);
  transform: translateY(0);
}
@media only screen and (max-width: 480px) {
  .fs-primary-nav>ul {
    margin-top:20px;
  }
  .fs-primary-nav li {
    font-size: 16px;
    letter-spacing:1px;
    font-weight:400;
    margin: 0;
  }
}
@media only screen and (min-width: 768px) {
  .fs-primary-nav {
    padding: 80px 0;
  }
}
@media only screen and (min-width: 1170px) {
  .fs-primary-nav li {
    font-size: 32px;
     margin: .4em 0;
  }
  .fs-primary-nav .fs-label {
    font-size: 16px !important;
    font-size: 1rem !important;
  }
}
.fs-main-content {
  position: relative;
  z-index: 1;
}
.header-side-menu.affix-fix.affix .header-wrapper{
  background:transparent !important;
  box-shadow:none !important;
}
.affix-fix .logo-2 {
  padding-top: 17px !important;
  padding-bottom: 17px !important;
}

/* #TWITTER FEEDS NEW ***************************************************/
.tweet_list {
	margin:0;
	padding:0;
	list-style:none;
}
.tweet_list li {
	padding-bottom: 10px;
 	padding-top: 0px; 
}
.tweet_time {
	display:block;
	float:left;
  margin-bottom:7px;
}
.tweet_time a{
	font-size: 14px;
}
.tweet_text {
	display:inline-block;
	padding: 0 0 15px 0;
	font-size:14px;
}
#twitter-feeds .loading {
	padding:20px;
}
#twitter-feeds .fa-twitter{
	display: block;
	float: left;
	margin-right: 15px;	
	color: #9c9fa2;
	font-size: 24px;
	transition: all 0.3s ease 0s; 
}

/* #FLICKR FEEDS NEW ***************************************************/
ul#flickr-feeds {
	margin:0;
	padding:0;
}
ul#flickr-feeds li {
  display: inline-block;
  float: left;
}
ul#flickr-feeds li a {
  display: block;
}
ul#flickr-feeds li a, ul#flickr-sfeeds li a img {
	height: 75px;
	width: 75px;
}

/* #TOP BAR NEW ***************************************************/
.top-bar{
  background: #F5F5F5;
  font-size: 12px;
  letter-spacing: 1px;
}
.top-bar-section{
  list-style: none;
  margin: 0;
  padding: 0;
}
.top-bar-section li{
  float: left;
  border-left: #E2E6E7;
}
.top-bar-section li:last-child{
  border-right: #E2E6E7;
}
.top-bar-section li .fa{
  font-size: 14px;
  position: relative;
  top: 1px;
}
.top-bar-section li {
  display: block;
  line-height: 43px;
  padding: 0 13px;
}
.affix .top-bar-section li {
  display: block;
  line-height: 32px;
  padding: 0 13px;
}
.top-bar-section li a{
  display: block;
  text-align: center;
  color: #999;
}
.top-bar-section li a:hover{
  color: #2a2b2f;
}

/* #CONSTRUCTION 2 NEW ***************************************************/
.slider-cap-left{
  width:480px;
}
@media only screen and (max-width : 1024px) {
  .height-not-fscr{height:350px;}
  .header-big-offset {padding-top: 0;}
}
@media only screen and (max-width : 767px) {
  .slider-cap-left{width:100%;}
  .height-not-fscr{height:250px;}

  .top-bar{
    min-height: 0;
    letter-spacing:0px;
  }
  .top-bar-section{
    float: none !important;
    padding: 5px 0;
    text-align: center;
  }
  .top-bar-section li,
  .top-bar-section li:last-child{
    float: none;
    border:none;
    display: inline-block;
  }
  .top-bar-section li a{
    display: inline-block;
    min-width: 0;
    height: auto;
    line-height: 1;
    padding: 0 5px;
  }
  .top-links li a:hover,
  .top-bar.dark .top-links li a:hover{
    background: none;
  } 
  .header-mag .top-bar{
    font-size: 11px;
  }
  .header-mag  .top-bar-section{
    padding: 0;
  }
  .header-mag .top-bar-section li {
    line-height: 38px;
    padding: 0 7px;
  }
  
}

/* #CAFE NEW *******************************************************/
.cafe-menu-tab-nav {
  display:inline-block;
  border:none;
}
.cafe-menu-tab-nav li>a{
  border:none !important;
  padding: 10px !important;
  margin:0 10px 0 10px;
}
.cafe-menu-tab-nav li a:hover{
  border:none !important;
  background:none !important;
}
.cafe-menu-tab-nav li.active>a{
  border-bottom:1px solid #101010 !important;
}
@media only screen and (max-width : 478px) {
  .cafe-menu-tab-nav>li {
    float: none !important;
  }
}
.cafe-menu h4{
  font-size: 16px;
  line-height: 25px;
  margin: 14px 0 14px 0;
}
.bd-t-none{
  border-top:none !important;
}

/* #NEW NAV STICK2 MENU***************************************************/
.fixed{
  position:fixed !important;
}
#nav-stick2.has-bg.white .header-wrapper{
  background: #fff;
  -webkit-box-shadow: 0 1px 15px rgba(0,0,0, .15);
	-moz-box-shadow: 0 1px 15px rgba(0,0,0, .15);
	box-shadow: 0 1px 15px rgba(0,0,0, .15);
}
#nav-stick2.has-bg.black .header-wrapper{
  background: #303236;
  -webkit-box-shadow: 0 1px 15px rgba(0,0,0, .15);
	-moz-box-shadow: 0 1px 15px rgba(0,0,0, .15);
	box-shadow: 0 1px 15px rgba(0,0,0, .15);
}
#nav-stick2.transparent .header-wrapper {
  background: transparent !important;
}
#nav-stick2 {
  transition: all .4s ease;
  width: 100%;
}
#nav-stick2.has-bg .logo-2{
  padding-top: 9px;
  padding-bottom: 9px;
  transition: all .4s ease 0s;
}
.header-side-menu.affix-fix .header-wrapper {
  background: transparent !important;
  box-shadow: none !important;
}


/* #UPDATE 1.9 ****************************************************/

/* #IOS TEL LINKS ***************************************************/
#main-menu .sub .contact-phone a {
  color:#fff !important;
  display:inline !important;
  padding: 0 !important;
}
#main-menu .sub .contact-phone a:hover {
	color:#414146 !important;
	background:none !important;
}

/* #HEADER CENTER ***************************************************/
@media (min-width: 1025px) {
  .navbar-collapse.collapse.nav-center {
    display: -webkit-box !important;
    display: -ms-flexbox !important;
    display: flex !important;
    -webkit-box-orient:horizontal;
    -webkit-box-direction:normal;
    -ms-flex-direction:row;
            flex-direction:row;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
            justify-content: center;
  }
  .header-1 #main-menu .nav-center .nav.navbar-nav {
    margin-right: 0 !important;
  }
}

/* #HEADER LEFT ***************************************************/
@media (min-width: 1025px) {
  .header-menu-left .logo-row {
    float: right;
    width:148px;
  }
}
.header-menu-left .logo-2{
  text-align:right;
  padding-right:15px;
}
@media (max-width: 1024px) {
  .header-menu-left .menu-btn-respons-container {
    right:inherit;
  }
}

/* #FULL SCREEN SUB MENU ***************************************************/ 
.fs-table {
  display: table;
  width: 100%;
  height: 100%;
}
.fs-table-cell {
  display: table-cell;
  text-align: center;
  vertical-align: middle;
}
.logo-hiding {
  opacity:1;
  visibility:visible;
}
 .logo-hide {
  opacity:0;
  visibility:hidden;
} 
.fs-primary-nav.fs-sub-nav li {
  margin: 10px 0;
}
.fs-primary-nav.fs-sub-nav li a{
  font-size: 22px;
  color:#101010;  
  letter-spacing: 3px;
}
.fs-primary-nav.fs-sub-nav li a:hover{
  color: #97999c;
}
.fs-primary-nav.fs-sub-nav .fs-sub li {
  margin: 3px 0;
}
.fs-primary-nav.fs-sub-nav .fs-sub li a {
  padding: 5px 20px;
  font-size: 14px;
  line-height:18px;
  letter-spacing: 1px;
}  
@media only screen and (min-width: 1170px){
  .fs-primary-nav.fs-sub-nav li {
    margin: 10px 0;
  }
}
@media only screen and (max-width: 480px){
  .fs-primary-nav.fs-sub-nav li a {
    padding: 5px 20px;
    font-size: 16px;
    line-height:18px;
    font-weight: 400; 
    letter-spacing: 1px;
  }  
}
.fs-a-sec{
  margin: .1em 0 !important;
}
.fs-parent {
  position:relative;
}
.fs-sub {
  display: none;
  opacity: 0;
  position: relative;
}  
.fs-active .fs-sub {
  opacity: 1;
}
.fs-parent .open-sub:before {
  content: "\e232";
  font-size: 18px;
  font-family: "linea-icons" !important;
  right: 0;
  position: absolute;
  font-style: normal !important;
  font-weight: normal !important;
  font-variant: normal !important;
  text-transform: none !important;
  speak: none;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.fs-active .open-sub:before {
  content: "\e200";
}
.fs-active .fs-sub .open-sub:before {
  content: "\e232";
} 
.fs-active .fs-sub  .fs-active .open-sub:before {
  content: "\e200";
} 
.fs-active .fs-sub .open-sub:before {
  line-height:20px; 
  right:4px;
}  

/* #INTRO 2
***************************************************/
.sm-img-bg2{
  background-size: cover;
  background-position: center bottom;
  width: 100%;
  height: 100%;
}
.post-prev-title2 h3{
  margin:0;
  font-size:16px;
  line-height:26px;
}  
.fs-21{
  font-size:21px;
}
.fs-24{
  font-size:24px;
}
@media (min-width:1200px) {
  .mt-lg-80{margin-top:80px}
}  

/* #STICKY HEADER ON MOBILE 
***************************************************/  
@media (max-width: 1024px) {
  .header.affix-on-mobile{
    position: fixed;
    width: 100%;
  }  
  #nav.affix-on-mobile .nav.navbar-nav {
    overflow-y: auto;
  }
}
.label-new {
  margin-left:5px;
  background-color: #fde801;
  color: #2a2b2f !important;
  transition-property:all !important;
  padding: 4px 6px 4px 5px;
  font-size: 9px;
  font-weight: 700;
  line-height:21px;
  vertical-align:middle;
}

/* #LOGO HIDE HEADER ON MOBILE 
***************************************************/  
.hiding-logo.logo-img {
  transition: all .3s cubic-bezier(0.000, 0.000, 0.580, 1.000);
  opacity:0
}
.affix .hiding-logo.logo-img {
  transition: all .3s cubic-bezier(0.000, 0.000, 0.580, 1.000);
  opacity:1;
}

/* #HEADER BELOW
***************************************************/ 
.nav-below-sticky-wrapper.is-sticky {
  height: 60px !important;
  transition: height .3s ease 0s; 
  -webkit-transform: translateZ(0);
  -moz-transform: translateZ(0);
  -ms-transform: translateZ(0);
  -o-transform: translateZ(0);
  transform: translateZ(0);
}
#nav-below.header {
	position:relative;
  width:100%;
}
.font-lato{
  font-family: 'Lato',Arial,Helvetica,sans-serif;
}

/* #INTRO 2
***************************************************/
.p-200-cont {
  padding-top: 200px;
  padding-bottom: 200px;
}
.fes7-text-cont h1 {
  margin-top: 0px;
  margin-bottom: 40px;
}
.fes2-title-45 {
	color:#404347;
	font-family: 'Lato',Arial,Helvetica,sans-serif;
	font-size: 45px;
	line-height: 50px;
	font-weight: 300;
}
.fes2-text-cont{
	margin-top:30px;
  font-family: 'Lato',Arial,Helvetica,sans-serif;
	font-size: 16px;
	line-height: 18px;
  font-weight: 300;
}
.color-dark{
  color:#404347;
}
@media only screen and (max-width : 768px) {
  .p-200-cont{ padding-top:120px; padding-bottom:120px;}
	.fes2-title-45 {
		font-size: 30px;
		line-height: 30px;
	}
}

.form-error-icon {
  display: none;
}

.form-error-message {
  font-weight: 400;
  font-size: 11px;
  color: #e42c3e;
  position: absolute;
  top: 5px;
  right: 15px;
}